
import { defineComponent, ref, Ref, computed, ComputedRef, onMounted, watch } from "vue";
import batchTransferSubVo from "@/assets/vo/batchTransferSubVo";
import UserInfoVo from "@/assets/vo/UserInfoVo";
import Lang from "@/assets/lang";
import mobileArr from "@/assets/ts/mobileArr";
import { useStore } from "vuex";
import { getMyBalance, batchTransfer } from "@/assets/http";
import { ElMessage } from "element-plus";
import Utils from "@/assets/ts/utils";
import xlsx from "@/assets/ts/xlsx";
import { useRouter } from "vue-router"; //引入路由
export default defineComponent({
    setup() {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const $router = useRouter();
        const payTransferMin = computed(() => {
            const _obj = $store.state.captialConfig.USDT;
            if (_obj) return _obj.payTransferMin;
            return 0;
        });
        const payTransferMax = computed(() => {
            const _obj = $store.state.captialConfig.USDT;
            if (_obj) return _obj.payTransferMax;
            return 0;
        });
        const showRight = ref(false);
        const submitBtn = ref(false);
        const balance = computed(() => $store.state.balance);
        const userInfo: ComputedRef<UserInfoVo> = computed(() => $store.state.userInfo);
        const phCode = ref(mobileArr[0]);
        const userPhone = ref("");
        const userId = ref("");
        const allTotal = ref(0);
        const payPassword = ref("");
        const batchtransferList = ref(new batchTransferSubVo());
        let _amount = "";
        const fileList: Ref<any[]> = ref([]);
        const fileTemp:Ref<any[]> = ref([]);
        const tabelList:Ref<any[]> = ref([]);
        const tabelData:Ref<any[]> = ref([
            {
                receiveUserId: 888888,
                amount: 8888.88,
                // receiveCodeId: 888888,
            }
        ])
        watch(() => [...tabelList.value],(odd, newList) => {
                totalquantity();
            },{ deep: true }
        );
        const heUserInfo = ref(new UserInfoVo());
        onMounted(() => {
        });
        // 文件个数限制
        const fileExceed  = (files: number) => {
            ElMessage.error(langObj.value.l0435);
        };
        // 上传文件之前
        const beforeUploadFile = (file:any) => {
            console.log('before upload');
            console.log(file);
            let extension = file.name.substring(file.name.lastIndexOf('.')+1);
            let size = file.size / 1024 / 1024;
            if(extension !== 'xlsx' || extension !== 'xls') {
            ElMessage.error(langObj.value.l0429);
            }
        };
        // 文件状态改变
        const fileChange = (file:any,fileList:any) => {
            fileTemp.value = [];
            tabelList.value = [];
            fileTemp.value.push(file.raw) ;
            xlsx(fileTemp.value[0]).then((r: any) => {
              const questions: any = []; //临时存放questions对象的集合
              r.forEach((item: any) => {
                questions.push({
                  //将导出的数据装入临时集合
                  amount: Number(item.数量),
                //   receiveCodeId: item.地址,
                  receiveUserId: Number(item.用户ID),
                });
              });
              tabelList.value = questions; //将临时集合的数据给真正的集合
            });
            submitBtn.value = true;
        };
        // 数量总计
        const totalquantity = () =>{
            allTotal.value = 0
            tabelList.value.forEach((item:any) => {
               return allTotal.value += Number(item.amount);
            });
        };
        // 文件上传成功
        const handleSuccess = (res:any, file:any, fileList:any) => {
            ElMessage.success(langObj.value.l0425);
        };
        // 文件上传失败
        const handleError = (err:any, file:any, fileList:any) => {
            ElMessage.error(langObj.value.l0426);
        };
        const submitFile = () => {
            if (fileTemp.value.length === 0){
                ElMessage.error(langObj.value.l0436);
                return;
            }
            if(!userInfo.value.payPassword){
                ElMessage.error(langObj.value.l0118)
                $router.push({
                    path:"/user/securitySet"
                })
                return
            }
            showRight.value = true;
        };
        const updataFile = () =>{
            if (!payPassword.value) {
                ElMessage.error(langObj.value.l0121);
                return;
            }
            if (allTotal.value < 0) {
                ElMessage.error(langObj.value.l0016);
                return;
            }
            if( Number(balance.value) <= allTotal.value){
                ElMessage.error(langObj.value.l0332);
                return;
            }
            const list:any = [];
            tabelList.value.forEach((item:any) => {
                delete item.show;
                // list.push({
                //     amount: Number(item.amount),
                //     receiveCodeId: Number(item.receiveCodeId),
                //     receiveUserId: Number(item.receiveUserId),
                // });
            });
            batchtransferList.value.currencyCode = "USDT";
            batchtransferList.value.payUserId = userInfo.value.userId;
            batchtransferList.value.receiveInfos = tabelList.value;
            batchtransferList.value.payPassword = payPassword.value;
            batchTransfer(batchtransferList.value, () => {
                ElMessage.success(langObj.value.l0425);
                getMyBalance();
                onCancel();
            });
        };
        const handleEdit = (index:Number, row:any) =>  {
            console.log(index, row);
        };
        const handleDelete = (index:Number, row:any) =>  {
            console.log(index, row);
        };
        // 清空
        const onCancel = () => {
            batchtransferList.value.currencyCode = "";
            batchtransferList.value.payUserId = "";
            batchtransferList.value.receiveInfos = [];
            batchtransferList.value.payPassword = "";
            batchtransferList.value.remarks = "";
            payPassword.value = "";
            showRight.value = false;
            submitBtn.value = false;
            fileList.value = [];
            allTotal.value = 0;
        };
        return {
            langObj,
            Utils,
            payTransferMin,
            payTransferMax,
            balance,
            showRight,
            mobileArr,
            phCode,
            userPhone,
            payPassword,
            userId,
            submitBtn,
            batchtransferList,
            userInfo,
            allTotal,
            fileList,
            tabelData,
            tabelList,
            fileExceed,
            fileChange,
            totalquantity,
            beforeUploadFile,
            handleSuccess,
            handleError,
            submitFile,
            updataFile,
            handleEdit,
            handleDelete,
            onCancel,
        };
    },
});

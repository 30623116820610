/**账单明细*/
export default class BillDetailsVo {
    /**用户id*/
    userId = "";
    /**广告商户名称*/
    userName = "";
    payUserId = 0;
    orderNo = "";
    currencyCode = "";
    amount = 0;
    createTime = 0;
    merchant = "";
    mode = 0;
    type = 0;
    toCode = "";
    toAmount = "";
    rate = "";
    billCode = "";
    fromCode = "";
    status = 0;
    addr = "";
    withdrawService = "";
    digital_from = "";
    previousAmount = "";
    orderId = "";
    usdt = 0;
    nickname = "";
    transferRate = "";
    merchantName = "";
    merchantNO = "";
    pay = 0;
    income = 0;
}

export default {
    zh: {
        /**ajax code*/
        code0: "服务器异常",
        code1: "请重新登录",
        code2: "系统错误",
        code3: "请求过于频繁",
        code4: "服务维护，请稍后登录",

        // "1": "异常错误",
        // "401": "参数有误",

        // /* 钱包状态码*/
        // "4000": "用户该类型钱包不存在",
        // "4001": "钱包余额不足",
        // "4002": "货币类型不匹配",
        // "4003": "支付用户不匹配",
        // "4004": "外部区块链链接",
        // "4005": "钱包被禁用",
        // "4006": "支付密码因连续输错被禁用",
        // "4007": "支付密码错误",
        // "4008": "钱包签名错误",
        // "4009": "钱包更新错误",
        // "4010": "金额错误",
        // "4011": "对方账户已被冻结",

        // /*收款方式,转账状态码*/
        // "5000": "收款方式被禁用",
        // // "5001": "转账失败",

        // /*其他*/
        // "5201": "验证码请求过于频繁，请稍后再试",
        // "3001": "您的账号已在其它设备登录",
        // /*联系人状态码*/
        // "5100": "联系人个数达到上限",
        // /* 用户状态码*/
        // "51001": "用户不匹配",
        // "51002": "账户已被冻结，请联系客服",
        // "51003": "注册失败",
        // "51004": "支付密码错误",
        // "51005": "密码错误达到4次,账号已被冻结",
        // "51006": "登录密码错误，连续多次错误会导致账号临时冻结",
        // "51007": "登录失败,登陆太过频繁",
        // "51008": "登录失败,获取token失败",
        // "51009": "系统不存在该用户",
        // "51010": "用户层级不匹配",
        // "51012": "用户昵称已存在",
        // "51013": "谷歌验证已开启，请输入谷歌验证码",
        // "51014": "手机验证码错误",
        // "51015": "用户还未绑定邮箱",
        // "51016": "邮箱验证码错误",
        // "51017": "谷歌验证码错误",
        // "51025": "用户旧登录密码错误！",

        // /* 订单状态码*/
        // "52001": "订单创建失败",
        // "52002": "订单支付时间已过期",
        // "52003": "该广告正在创建订单，请稍后再试",
        // "52004": "该订单已被支付",
        // "52005": "订单正在操作中,请勿重复操作！",
        // "52006": "支付方和收款方id相同",
        // "52007": "收款码不存在",
        // "52008": "收款码收款次数达到上限",
        // "52009": "收款码已经过期",
        // "52010": "与收款码固定金额不匹配",
        // "52011": "数字货币地址接口请求不通",
        // "52012": "缺少USDT兑QD或QD兑USDT的汇率配置",
        // "52013": "签名校验不通过",
        // "52014": "低于最小限额",
        // "52015": "高于最大限额",
        // "52016": "类型不匹配",
        // "52017": "数字货币地址格式不正确",
        // "52018": "该笔订单已存在",
        // "52019": "汇率已发生变动",
        // "52020": "购买广告的总金额大于抵押金额",
        // "52021": "同一方向最多不能超过4条广告，请完成撤销后再发布新广告。",
        // "52022": "广告状态已变更，请重新下单。",
        // "52024": "该广告下，还有订单未完成",
        // "52025": "该收款类型正被使用，至少需要1个已激活的收款方式",
        // "52026": "您有正在交易中的订单，请先将其处理后才能发起新订单",
        // "52027": "对方需要您添加并激活相应的收款方式",
        // "52028": "提现金额错误",
        // "52029": "交易订单金额错误；不可交易",
        // "52030": "有效广告数大于0",
        // "52031": "所选择的付款方式不匹配",
        // "52032": "金额格式不正确",
        // "52033": "非法支付链接!",
        // "52034": "高于当日最高限额",
        // "52035": "数字货币地址格式不正确",
        // "52036": "提币服务费发生变动",
        // "52037": "缺少配置",
        // "52038": "当前提币链路不可用",
        // "52039": "系统繁忙",
        // "52040": "otc交易被冻结",
        // "52041": "该收款方式下有广告交易，无法关闭或删除",

        // /* 广告状态码*/
        // "53001": "低于最小默认限额",
        // "53002": "高于最大默认限额",
        // "53003": "支付方式为空",
        // "53004": "广告状态错误",
        // "53005": "广告金额回退中",
        // "53006": "广告金额不足",
        // "53007": "不在广告限额中",
        // "53008": "您尚未获得发布广告权限，请联系客服获取权限",
        // "53009": "您当前的抵押资产已被锁定，若要修改请联系客服",
        // "53010": "单价超出限定区间",

        // "9994": "未查询到用户信息",
        // "9992": "操作已生效,请勿重复操作",
        // "9987": "保存异常！",
        // "9986": "更新异常！",
        // "8000": "找不到相关数据",
        // "9999": "未知异常",
        // "54001": "验证码错误",
    },
    en: {
        /**ajax code*/
        code0: "Server Exception",
        code1: "Please log in again",
        code2: "System Error",
        code4: "Requests are too frequent",
        code5: "Service maintenance, please log in later",

        // "1": "Exception Error",
        // "401": "Parameter error",

        // /* Wallet status code*/
        // "4000": "User wallet of this type does not exist",
        // "4001": "Insufficient wallet balance",
        // "4002": "Currency type does not match",
        // "4003": "Payment user does not match",
        // "4004": "External blockchain link",
        // "4005": "Wallet is disabled",
        // "4006": "Payment password is disabled due to continuous input errors",
        // "4007": "Payment password error",
        // "4008": "Wallet signature error",
        // "4009": "Wallet update error",
        // "4010": "Incorrect amount",
        // "4011": "The other party's account has been frozen",

        // /*Payment method, transfer status code*/
        // "5000": "The payment method is disabled",
        // // "5001": "Transfer failed",

        // /*other*/
        // "5201": "The verification code request is too frequent, please try again later",
        // "3001": "Your account is already logged in on another device",
        // /*Contact status code*/
        // "5100": "The number of contacts has reached the upper limit",
        // /* User status code*/
        // "51001": "User does not match",
        // "51002": "The account has been frozen, please contact customer service",
        // "51003": "Registration failed",
        // "51004": "Payment password error",
        // "51005": "The password has been incorrectly reached 4 times, and the account has been frozen",
        // "51006": "The login password is incorrect. Multiple consecutive errors will cause the account to be temporarily frozen",
        // "51007": "Login failed, login too frequently",
        // "51008": "Login failed, failed to obtain token",
        // "51009": "The user does not exist in the system",
        // "51010": "User level does not match",
        // "51012": "User nickname already exists",
        // "51013": "Google verification is enabled, please enter Google verification code",
        // "51014": "Mobile phone verification code error",
        // "51015": "The user has not yet bound the mailbox",
        // "51016": "Email verification code error",
        // "51017": "Google verification code error",
        // "51025": "The user’s old login password is wrong!",

        // /* Order status code*/
        // "52001": "Order creation failed",
        // "52002": "The order payment time has expired",
        // "52003": "This ad is creating an order, please try again later",
        // "52004": "The order has been paid",
        // "52005": "The order is in operation, please do not repeat the operation!",
        // "52006": "Payer and recipient have the same id",
        // "52007": "The receipt code does not exist",
        // "52008": "The number of receipts by the receipt code reaches the upper limit",
        // "52009": "The receipt code has expired",
        // "52010": "Does not match the fixed amount of the payment code",
        // "52011": "The digital currency address interface request fails",
        // "52012": "Missing USDT/QD or QD/USDT exchange rate configuration",
        // "52013": "Signature verification failed",
        // "52014": "Below the minimum limit",
        // "52015": "Above the maximum limit",
        // "52016": "Type does not match",
        // "52017": "The format of the digital currency address is incorrect",
        // "52018": "The order already exists",
        // "52019": "Exchange rate has changed",
        // "52020": "The total amount of advertising purchased is greater than the mortgage amount",
        // "52021": "There can be no more than 4 ads in the same direction. Please complete the cancellation before posting a new ad.",
        // "52022": "The advertising status has been changed, please place another order.",
        // "52024": "There are still orders that have not been completed under this advertisement",
        // "52025": "The payment type is being used, and at least 1 activated payment method is required",
        // "52026": "You have an order that is currently being traded, please process it before you can initiate a new order",
        // "52027": "The other party needs you to add and activate the corresponding payment method",
        // "52028": "Incorrect withdrawal amount",
        // "52029": "The amount of the transaction order is wrong; it cannot be traded",
        // "52030": "The number of effective ads is greater than 0",
        // "52031": "The selected payment method does not match",
        // "52032": "Amount format is incorrect",
        // "52033": "Illegal payment link!",
        // "52034": "Above the maximum limit of the day",
        // "52035": "The format of the digital currency address is incorrect",
        // "52036": "Withdrawal service fee has changed",
        // "52037": "Missing configuration",
        // "52038": "The current withdrawal link is unavailable",
        // "52039": "System is busy",
        // "52040": "otc transaction is frozen",
        // "52041": "There are advertising transactions under this payment method, which cannot be closed or deleted",

        // /* Advertising status code*/
        // "53001": "Below the minimum default limit",
        // "53002": "Above the maximum default limit",
        // "53003": "Payment method is empty",
        // "53004": "Advertising status error",
        // "53005": "Advertising amount being refunded",
        // "53006": "Insufficient advertising amount",
        // "53007": "Not in the advertising quota",
        // "53008": "You have not obtained the permission to publish advertisements, please contact customer service to obtain the permission",
        // "53009": "Your current mortgage assets have been locked, if you want to modify, please contact customer service",
        // "53010": "Unit price exceeds the limit",

        // "9994": "User information not found",
        // "9992": "The operation has taken effect, please do not repeat the operation",
        // "9987": "Save exception!",
        // "9986": "Update abnormal!",
        // "8000": "Cannot find relevant data",
        // "9999": "Unknown exception",
        // "54001": "Verification code error",
    },
};

/**广告参数*/
export default class AdvertVo {
    /**链路地址*/
    addr = "";
    /**广告剩余金额*/
    amount = 0;
    /**创建时间*/
    createTime = 0;
    /**币种code*/
    currencyCode = "";
    /**到期时间*/
    expireTime = 0;
    /**被克隆广告id*/
    fatherId = null;
    /**广告id*/
    id = "";
    /**图片留言*/
    imageMsg = "";
    /**文本留言*/
    leaveMsg = "";
    /**最高限额*/
    maxQuota = 0;
    /**最低限额*/
    minQuota = 0;
    /**操作人*/
    operator = "";
    /**广告订单id*/
    orderId = "";
    /**支付方式*/
    paymentMethodId = "";
    /**撤销时间*/
    revokeTime = 0;
    /**状态（（0已发布 1撤销中 2已撤消 3待退还（自动退还，手动退还） */
    status = 0;
    /**广告商的30日成单*/
    successByMonth = 0;
    /**用户订单的完成率*/
    successRatio = 0;
    /**总金额数量*/
    totalAmount = 0;
    /**广告类型 ：0他人出售 1他人购买*/
    type = 0;
    /**单价*/
    unitPrice = 0;
    /**修改时间*/
    updateTime = 0;
    /**用户id*/
    userId = "";
    /**广告商户名称*/
    userName = "";
}

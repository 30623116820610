
import MyAddPayment from "../trade/MyAddPayment.vue";
import MyAddPaymentIndia from "../trade/MyAddPaymentIndia.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import Utils from "@/assets/ts/utils";
import Config from "@/assets/ts/config";
import { getPaymentList, updatePayment } from "@/assets/http";
import { getPayIcon, getPayName, PaymentEnum } from "@/assets/ts/common";
import Lang from "@/assets/lang";

export default defineComponent({
    components: { MyAddPayment, MyAddPaymentIndia },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const showDeleteAlert = ref(false);
        const addPaymentAlert = ref();
        const addPaymentAlertIndia = ref();
        const status = ref(true);

        const selectItem = ref({
            account: "",
            accountName: "",
            branch: "",
            delFlag: "",
            email: "",
            id: 0,
            ifscCode: "",
            openBranch: "",
            paymentUrl: "",
            phone: "",
            status: "",
            type: 0,
            userId: 0,
        });
        const dataList = ref([]);

        const requestPayList = () => {
            const data = {
                current: 1,
                size: 1000,
            };
            getPaymentList(data, (res: any) => {
                dataList.value = res.records;
            });
        };
        onMounted(() => {
            requestPayList();
        });

        //更新状态
        const changeStatus = (item: any) => {
            const newdata: any = {
                id: item.id,
                status: item.status == 1 ? 0 : 1,
            };
            updatePayment(newdata, () => {
                requestPayList();
            });
        };
        const addAction = () => {
            if (Config.projectFlag == 0 || Config.projectFlag == 1 || Config.projectFlag == 2) {
                addPaymentAlert.value.showAlert(PaymentEnum.Add);
            } else if (Config.projectFlag == 3 || Config.projectFlag == 4) {
                addPaymentAlertIndia.value.showAlert(PaymentEnum.Add);
            }
        };
        const deleteAction = (item: any) => {
            Utils.updateObj(selectItem.value, item);
            showDeleteAlert.value = true;
            // addPaymentAlert.value.showAlert(PaymentEnum.Delete, selectItem.value);
        };
        const handleCloseDelete = () => {
            showDeleteAlert.value = false;
            const item = selectItem.value;
            const newdata: any = {
                id: item.id,
                delFlag: 1,
            };
            updatePayment(newdata, () => {
                requestPayList();
            });
        };
        return {
            langObj,
            showDeleteAlert,
            addPaymentAlert,
            addPaymentAlertIndia,
            deleteAction,
            handleCloseDelete,
            requestPayList,
            addAction,
            dataList,
            changeStatus,
            selectItem,
            status,
            getPayIcon,
            paymentName: (type: number) => {
                if (type == undefined) {
                    return "";
                }
                return getPayName(type);
            },
            getImgUrl: (imgUrl: string) => {
                const url = Config.project.fileHost + imgUrl;
                return url;
            },
        };
    },
});

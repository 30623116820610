export default class captialConfigVo {
    // id: number,
    // levelId: number,
    // currencyCode: string,
    // withdrawService: number,
    // payTransferMax: number,
    // payTransferMin: number,
    // payTransferDay: number,
    // withdrawMax: number,
    // withdrawMin: number,
    // bigAmountWarning: number,
    // withdrawDay: number,
    // withdrawEntrustFeeRate: number,
    // withdrawEntrustFeeMax: number,
    // withdrawEntrustFeeMin: number,
    // withdrawEntrustMax: number,
    // withdrawEntrustMin: number,
    // withdrawEntrustDay: number,
    // exchangeMax: number,
    // exchangeMin: number,
    // exchangeDay: number,
    // pardon: number,
    // createTime: string,
    // updateTime: string,
[x: string]: any,
}
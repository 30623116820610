/**
 * 转账提交的参数
 * */
 export default class TransferSubVo {
    /**金额*/
    amount = "";
    /**数字货币种类*/
    currencyCode = "";
    /**支付密码*/
    payPassword = "";
    /**支付方id*/
    payUserId = "";
    /**接收方id*/
    receiveUserId = "";
    /**说明*/
    remarks = "";
    /**转账类型*/
    type = 0;
}

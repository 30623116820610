import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d1fa76b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "userAdmin" }
const _hoisted_2 = { class: "head" }
const _hoisted_3 = { class: "top" }
const _hoisted_4 = { class: "section" }
const _hoisted_5 = { class: "sectionLeft" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_userAssets = _resolveComponent("userAssets")!
  const _component_user_filled = _resolveComponent("user-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_unlock = _resolveComponent("unlock")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, _toDisplayString(_ctx.langObj.l0035), 1)
      ]),
      _createVNode(_component_userAssets)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, { to: "/user/userInfo" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_user_filled)
              ]),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.langObj.l0272), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/user/securitySet" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_unlock)
              ]),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.langObj.l0273), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_router_view, { class: "sectionRight" })
    ])
  ]))
}
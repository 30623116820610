
import Lang from "@/assets/lang";
import { defineComponent, ref, Ref, onMounted, onUnmounted, computed } from "vue";
import Wares from "@/components/Wares.vue";
import OrderDetails from "./OrderDetails.vue";
import OrderChat from "./OrderChat.vue";
import PlaceOrder from "@/components/PlaceOrder.vue";
import { postFreeTradeList } from "@/assets/http";
import { useStore } from "vuex";
import EventBus from "@/assets/ts/eventBus";
import { ElMessage } from "element-plus";
import OrderDetailsVo from "@/assets/vo/OrderDetailsVo";
export default defineComponent({
    name: "FreeTrade",
    components: {
        Wares,
        PlaceOrder,
        OrderDetails,
        OrderChat,
    },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const isLoadEnd = ref(false);
        const isLoadStart = ref(false);
        const itemBoxI = ref(-1);
        const placeOrder = ref();
        const orderDetails = ref();
        const orderChat = ref();
        const $store = useStore();
        const isMySell = (id: string) => {
            return $store.state.userInfo.userId == id; //是否我出售
        };
        const onItemBox = (item: any, index: number) => {
            if (itemBoxI.value == index) return;
            if (isMySell(item.userId)) {
                ElMessage.error(langObj.value.l0394);
                return;
            }
            itemBoxI.value = index;
            placeOrder.value.onOpen(item);
            orderChat.value.onShowLoading(true);
            orderDetails.value.isHide(false);
        };
        let pageNumber = 0; //当前页数
        let totalPages = 0; //总页数
        const goodsArr: Ref<any[]> = ref([]);
        const onFreeTradeList = () => {
            isLoadStart.value = true;
            isLoadEnd.value = true;
            pageNumber++;
            const _data = {
                type: 0,
                currencyCode: "USDT",
                size: 6,
                current: pageNumber,
            };
            postFreeTradeList(_data, (data: any) => {
                isLoadStart.value = false;
                if (pageNumber == 1) goodsArr.value = [];
                goodsArr.value = [...goodsArr.value, ...data.content];
                totalPages = data.totalPages;
                pageNumber = data.pageNumber;
                isLoadEnd.value = pageNumber >= totalPages;
            });
        };
        let orderId = "";
        const refreshChat = (_data: any, _orderDetail: OrderDetailsVo) => {
            /**判断是否显示当前订单**/
            if (_data.orderId == orderId) {
                orderDetails.value.updataOrderDetail(_orderDetail);
            }
        };
        onMounted(() => {
            EventBus.on(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        onUnmounted(() => {
            EventBus.remove(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        return {
            langObj,
            itemBoxI,
            onItemBox,
            isLoadEnd,
            isLoadStart,
            goodsArr,
            onFreeTradeList,
            placeOrder,
            orderDetails,
            orderChat,
            placeOrderHide: () => {
                itemBoxI.value = -1;
                orderChat.value.onShowLoading(false);
            },
            showOrderDetail: (_data: OrderDetailsVo) => {
                orderId = _data.id;
                const _isMySell = isMySell(_data.payUserId);
                orderDetails.value.onShow(_data, _isMySell);
                orderChat.value.onShow(_data, _isMySell);
            },
        };
    },
});

export default {
    zh: {
        "1": "消息接收人或发送人非订单用户",
        "2": "您的发言过于频繁，请稍后再试。",
        "3": "未查询到该订单",
        "4": "订单已经过期了",
        "5": "已在其他设备登录",
    },
    en: {
        "1": "The recipient or sender of the message is not the order user",
        "2": "Your speech is too frequent, please try again later.",
        "3": "The order was not found",
        "4": "The order has expired",
        "5": "Login conflict",
    },
};

interface JudgeStatus {
    isonce: boolean;
    fun: Function;
}

/**
 * @desc 全局事件总线
 */
export default class EventBus {
    /**有订单更新*/
    public static OTC_SOCKET_ORDERINFO = "OTC_SOCKET_ORDERINFO";
    /**事件集合*/
    private static _callbacks: any = {};

    /**
     * 添加事件监听
     * @param {string} eventName 事件名
     * @method {Function} func 回调函数
     * @param {boolean} isonce 是否就执行一次
     */
    private static addEvent(eventName: string, func: Function, isonce: boolean) {
        // 如果未监听过则添加进去
        if (!this._callbacks[eventName]) {
            this._callbacks[eventName] = [];
        }
        const _judgeStatus: JudgeStatus = {
            isonce: isonce,
            fun: func,
        };
        this._callbacks[eventName].push(_judgeStatus);
    }

    /**
     * 事件监听-多次执行
     * @param {string} eventName 事件名
     * @param {Function} func 回调函数
     */
    public static on(eventName: string, func: Function) {
        EventBus.addEvent(eventName, func, false);
    }

    /**
     * 事件监听-执行一次
     * @param {string} eventName 事件名
     * @param {Function} func 回调函数
     */
    public static once(eventName: string, func: Function) {
        EventBus.addEvent(eventName, func, true);
    }

    /**
     * 事件触发
     * @param {string} eventName 事件名
     * @param {any} arg1 传入的参数
     * @param {any} arg2 传入的参数
     */
    public static emit(eventName: string, arg1: any = null, arg2: any = null) {
        if (this._callbacks[eventName]) {
            this._callbacks[eventName].forEach((item: JudgeStatus, index: number) => {
                item.fun(arg1, arg2);
                if (item.isonce) this._callbacks[eventName].splice(index, 1);
            });
        } else {
            console.warn("暂未监听：" + eventName + "事件");
        }
    }

    /**
     * 事件删除
     * @param {string} eventName 事件名
     * @param {Function} func 回调函数
     */
    public static remove(eventName: string, func: Function) {
        if (this._callbacks[eventName]) {
            this._callbacks[eventName].forEach((item: JudgeStatus, index: number) => {
                if (item.fun == func) this._callbacks[eventName].splice(index, 1);
            });
        } else {
            console.warn("暂未监听：" + eventName + "事件");
        }
    }
}


import { getHeUserInfo, getMessage, getOtcData, httpTime, tokenObj, getMemberLevel, imgHeader } from "@/assets/http";
import Utils from "@/assets/ts/utils";
import Config from "@/assets/ts/config";
import { defineComponent, ref, nextTick, onBeforeUpdate, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { onSend } from "@/assets/http/webSocket";
import { ElMessage } from "element-plus";
import router from "@/router";
import UserInfoVo from "@/assets/vo/UserInfoVo";
import OrderDetailsVo from "@/assets/vo/OrderDetailsVo";
import { getPayName } from "@/assets/ts/common";
import Lang from "@/assets/lang";
export default defineComponent({
    name: "AdvertisTrade",
    setup() {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        /**0 不显示 1 显示加载 2 显示聊天*/
        const showStatus = ref(0);
        const orderId = ref("");
        const host = Config.project.host;
        const userInfo: UserInfoVo = $store.state.userInfo;
        const heUserInfo = ref(new UserInfoVo());
        const sendVal = ref("");
        const merchant = ref("0");
        const onSendVal = () => {
            if (!sendVal.value) return;
            const _obj = {
                ack: 0,
                fp: Utils.createUUID(),
                protoCode: 1002, //消息协议号
                orderId: orderId.value, //订单id
                from: userInfo.userId, //消息发送人,0为系统消息
                to: heUserInfo.value.userId, //消息接收人
                ts: httpTime(), //消息时间戳
                type: 1,
                body: JSON.stringify({ text: sendVal.value }), //消息实体
            };
            onSend(_obj, () => {
                $store.commit("updataChatObj", _obj);
                $store.commit("updataNewsObj", { ..._obj, othersName: heUserInfo.value.username });
                sendVal.value = "";
            });
        };
        const onHeUserInfo = (userId: string) => {
            getHeUserInfo(userId, (data: UserInfoVo) => {
                Utils.updateObj(heUserInfo.value, data);
                showStatus.value = 2;

                getMemberLevel(data.levelId.toString(), (res: any) => {
                    merchant.value = res.merchant;
                });
            });
        };
        const otcData = ref({ success: 0, successRatio: 0, successByMonth: 0, avgPermitTime: 0 });
        const onOtcData = (userId: string) => {
            getOtcData(userId, (data: any) => {
                Utils.updateObj(otcData.value, data);
            });
        };
        const gotoMerchantInfo = (item: any) => {
            router.push({ path: "/merchantInfo", query: { userId: item.userId, pageName: langObj.value.l0367 } });
        };
        const onBottom = () => {
            const ele = document.getElementById("chatList");
            nextTick(() => {
                if (ele && ele.scrollHeight > ele.clientHeight) {
                    ele.scrollTop = ele.scrollHeight;
                }
            });
        };

        onBeforeUpdate(() => {
            onBottom();
        });
        onUnmounted(() => {
            $store.commit("setNowChatOrderId", "");
        });
        const chatObj: any = ref($store.state.chatObj);
        const onShow = (_orderDetail: OrderDetailsVo, isSell: boolean) => {
            showStatus.value = 0;
            if (_orderDetail) {
                const heUserId = isSell ? _orderDetail.receiveUserId : _orderDetail.payUserId;
                onHeUserInfo(heUserId);
                onOtcData(heUserId);
                orderId.value = _orderDetail.id;
                $store.commit("setNowChatOrderId", orderId.value);
                if (!chatObj.value[orderId.value] || !chatObj.value[orderId.value].ajax) {
                    getMessage(orderId.value, (data: any) => {
                        $store.commit("updataChatObj", { orderId: orderId.value, arr: data, ajax: true });
                    });
                }
                $store.commit("setNewsObj", { name: "cleanUpI", orderId: orderId.value });
            } else {
                $store.commit("setNowChatOrderId", "");
            }
        };
        const serializeData = (data: any) => {
            if (!data) return [];
            const _arr: any[] = [];
            data.arr.forEach((item: any) => {
                const dateTime = Utils.formatDateTime(item.ts);
                const body = JSON.parse(item.body);
                if (item.type == 1 || item.type == 2) {
                    if (item.to == userInfo.userId) {
                        if (body.text) {
                            _arr.push({ message: body.text, isMy: false, time: dateTime, type: "text" });
                        } else if (body.url) {
                            _arr.push({
                                message: Config.getFileUrl(body.url),
                                isMy: false,
                                time: dateTime,
                                type: "img",
                            });
                        }
                    } else {
                        if (body.text) {
                            _arr.push({ message: body.text, isMy: true, time: dateTime, type: "text" });
                        } else if (body.url) {
                            _arr.push({
                                message: Config.getFileUrl(body.url),
                                isMy: true,
                                time: dateTime,
                                type: "img",
                            });
                        }
                    }
                } else {
                    let msg = "";
                    switch (item.type) {
                        case 3:
                            msg = body.receiveUserId == userInfo.userId ? langObj.value.l0368 : langObj.value.l0369;
                            break;
                        case 4: {
                            const _msg1 = langObj.value.l0370;
                            const msg2 = langObj.value.l0371
                                .replace("{1}", heUserInfo.value.nickName)
                                .replace("{2}", (body.payType == 0 || body.payType == 4) ? body.openBranch : getPayName(body.payType))
                                .replace("{3}", body.account || body.accountName)
                                .replace("{4}", Number(body.totalCny).toFixed(2))
                                .replace("{5}", Config.currency.code);
                            msg = body.receiveUserId == userInfo.userId ? _msg1 : msg2;
                            break;
                        }
                        case 5:
                            msg =
                                body.receiveUserId == userInfo.userId
                                    ? langObj.value.l0372.replace("{1}", body.currencyCode)
                                    : langObj.value.l0373.replace("{1}", body.currencyCode);
                            break;
                        case 6: {
                            msg = userInfo.userId == body.appealUserId ? langObj.value.l0374 : langObj.value.l0375;
                            break;
                        }
                        // 取消订单
                        case 7: {
                            msg = userInfo.userId == body.cancelUserId ? langObj.value.l0376 : langObj.value.l0377;
                            break;
                        }
                        // 超过5分钟提醒购买方支付
                        case 8:
                            msg = body.receiveUserId == userInfo.userId ? langObj.value.l0378 : langObj.value.l0379;
                            break;
                        // 取消申诉
                        case 12:
                            msg = userInfo.userId == body.unAppealUserId ? langObj.value.l0380 : langObj.value.l0371;
                            break;
                        case 13: {
                            //超过五分钟
                            msg = userInfo.userId == body.payUserId ? langObj.value.l0382 : langObj.value.l0373;
                            break;
                        }
                    }
                    _arr.push({ message: msg, id: "", time: dateTime, type: "system" });
                }
            });
            return _arr;
        };
        const onShowLoading = (isShow: boolean) => {
            if (isShow) showStatus.value = 1;
            else showStatus.value = 0;
        };
        return {
            langObj,
            showStatus,
            onShow,
            onShowLoading,
            heUserInfo,
            otcData,
            host,
            merchant,
            gotoMerchantInfo,
            imgHeader,
            handleAvatarSuccess: (data: any) => {
                const _obj = {
                    ack: 0,
                    fp: Utils.createUUID(),
                    protoCode: 1002, //消息协议号
                    orderId: orderId.value, //订单id
                    from: userInfo.userId, //消息发送人,0为系统消息
                    to: heUserInfo.value.userId, //消息接收人
                    ts: httpTime(), //消息时间戳
                    type: 2,
                    body: JSON.stringify({ url: Config.getFileUrl(data.data.url) }),
                };
                onSend(_obj, () => {
                    $store.commit("updataChatObj", _obj);
                });
            },
            handleAvatarError: () => {
                ElMessage.error(langObj.value.l0296);
            },
            formatDateYMD: (time: number) => {
                return Utils.formatDateYMD(time);
            },
            getCountdown: (time: number) => {
                if (time > 3600) return ">60" + langObj.value.l0385;
                return Utils.getCountdown(time);
            },
            chatObj,
            sendVal,
            onSendVal,
            orderId,
            serializeData,
            userInfo,
        };
    },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f087c1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "advertisAdmin" }
const _hoisted_2 = { class: "head" }
const _hoisted_3 = { class: "top" }
const _hoisted_4 = { class: "section pad20 marTB10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_userAssets = _resolveComponent("userAssets")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, _toDisplayString(_ctx.langObj.l0035), 1)
      ]),
      _createVNode(_component_userAssets)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_link, { to: "/my/transfer" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langObj.l0037), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/my/batchTransfer" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langObj.l0423), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/my/withdraw" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langObj.l0038), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/my/MyBill" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langObj.l0039), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_router_view)
  ]))
}
import { createStore } from "vuex";
import Utils from "@/assets/ts/utils";
import State  from "./State";
import UserInfoVo from "@/assets/vo/UserInfoVo";
import OrderDetailsVo from '@/assets/vo/OrderDetailsVo'
import captialConfigVo from '@/assets/vo/captialConfigVo'

const state: State = {
    userInfo: new UserInfoVo(),
    mortgageNum: 0,
    merchant: 0,
    webStatus: 0,
    nowChatOrderId: "",
    chatObj: {},
    newsObj: {
        /**未读总消息*/
        unreadAll: 0,
        /**消息列表*/
        newsArr: [],
    },
    orderDetailsObj: new OrderDetailsVo(),
    clonAdvertise: {},
    leaveMsgData: {},
    balance: 0,
    captialConfig: new captialConfigVo(),
};

export default createStore({
    state,
    mutations: {
        updataUserInfo(state, data) {
            Utils.updateObj(state.userInfo, data);
        },
        setMerchant(state, val) {
            state.merchant = val;
        },
        setMortgageNum(state, val) {
            state.mortgageNum = val;
        },
        setNowChatOrderId(state, val) {
            state.nowChatOrderId = val;
        },
        setWebStatus(state, val) {
            state.webStatus = val;
        },
        setBalance(state, val) {
            state.balance = val;
        },
        setCaptialConfig(state, _arr) {
            _arr.forEach((val: captialConfigVo) => {
                state.captialConfig[val.currencyCode] = val;
            });
        },
        // updataChatObj(state, _data) {
        //     //更新聊天消息
        //     let _obj = state.chatObj[_data.orderId];
        //     if (!_obj) _obj = { unread: 0, arr: [] };
        //     if (_data.arr) {
        //         _obj.arr = _data.arr;
        //         _obj.ajax = _data.ajax;
        //     } else {
        //         _obj.arr.push(_data);
        //     }
        //     state.chatObj[_data.orderId] = _obj;
        // },
        // updataNewsObj(state, _data) {
        //     const _arr = state.newsObj.newsArr;
        //     const _obj = Object.assign({unread: 0},_data);
        //     for (let i = 0; i < _arr.length; i++) {
        //         if (_arr[i].orderId == _data.orderId) {
        //             _obj.unread = _arr[i].unread;
        //             _arr[i].othersName && (_obj.othersName = _arr[i].othersName);
        //             _arr.splice(i, 1);
        //             break;
        //         }
        //     }
        //     if (_data.from == state.userInfo.userId || _data.orderId == state.nowChatOrderId) {
        //         //是自己发送或者当前订单正在聊天-->只置顶消息
        //         _obj.unread = 0;
        //     } else {
        //         //是别人发送-->置顶消息，未读+1 总未读+1
        //         _obj.unread++;
        //         state.newsObj.unreadAll++;
        //     }
        //     _arr.unshift(_obj);
        //     console.log(_arr);
        //     sessionStorage.setItem("newsObj" + state.userInfo.userId, JSON.stringify(state.newsObj));
        // },
        // setNewsObj(state, _data) {
        //     //设置聊天列表
        //     let _arr = state.newsObj.newsArr;
        //     if (_data.name == "cleanUpAll") {
        //         state.newsObj.unreadAll = 0;
        //         _arr = _arr.map((val: any) => {
        //             val.unread = 0;
        //         });
        //         sessionStorage.setItem("newsObj" + state.userInfo.userId, JSON.stringify(state.newsObj));
        //     } else if (_data.name == "cleanUpI") {
        //         for (let i = 0; i < _arr.length; i++) {
        //             if (_arr[i].orderId == _data.orderId) {
        //                 state.newsObj.unreadAll -= _arr[i].unread;
        //                 _arr[i].unread = 0;
        //                 state.newsObj.newsArr[i] = _arr[i];
        //                 break;
        //             }
        //         }
        //         sessionStorage.setItem("newsObj" + state.userInfo.userId, JSON.stringify(state.newsObj));
        //     } else if (_data.name == "deleteI") {
        //         for (let i = 0; i < _arr.length; i++) {
        //             if (_arr[i].orderId == _data.orderId) {
        //                 state.newsObj.unreadAll -= _arr[i].unread;
        //                 _arr.splice(i, 1);
        //                 break;
        //             }
        //         }
        //         sessionStorage.setItem("newsObj" + state.userInfo.userId, JSON.stringify(state.newsObj));
        //     } else if (_data.name == "getStorage") {
        //         const _obj: any = sessionStorage.getItem("newsObj" + state.userInfo.userId) || {};
        //         if (_obj && _obj.unreadAll > 0) {
        //             _obj.newsArr = _obj.newsArr.filter((val: any) => val.unread > 0);
        //             Utils.setObj(state.newsObj, _obj);
        //         }
        //     }
        // },
        // updateOrderDetailsObj(state, data) {
        //     //更新订单详情map
        //     if (Array.isArray(data)) {
        //         data.forEach((element) => {
        //             state.orderDetailsObj[element.id] = element;
        //         });
        //     } else {
        //         state.orderDetailsObj[data.id] = data;
        //     }
        // },
        setClonAdvertise(state, data) {
            state.clonAdvertise = data;
        },
        setLeaveMsg(state, data) {
            Utils.updateObj(state.leaveMsgData, data, false);
        },
    },
    actions: {},
    modules: {},
});


import { getBillRecordDetailByType, getHeUserInfo, tokenObj } from "@/assets/http";
import Utils from "@/assets/ts/utils";
import { useStore } from "vuex";
import { computed, defineComponent, ref } from "vue";
import Lang from "@/assets/lang";

// 订单类型 0 转账 1 收付款  2兑汇  3 提币  4 充币 5抵押 6otc广告 ；7otc订单 8 提现委托 9充值 10 后台扣款 11 消费 12入款
export default defineComponent({
    name: "MyBillDetail",
    setup() {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const data = ref({
            orderId: "",
            type: 0,
            id: 0,
            orderNo: "",
            orderNO: "",
            otherUserName: "",
            mode: 0,
            payUserId: 0,
            status: 0,
        });

        const refreshOrder = (order: any) => {
            if (order.orderId.length == 0) {
                return;
            }

            const mode = order.mode;
            const type = order.type;
            //赋值
            data.value = order;

            getBillRecordDetailByType(data.value.orderId.toString(), type, (res: any) => {
                res.mode = mode;
                res.type = type;
                data.value = res;

                console.log(data.value);
                const otherUserId = res.payUserId == tokenObj.user_id ? res.receiveUserId : res.payUserId;
                if (otherUserId > 0)
                    getHeUserInfo(otherUserId, (res: any) => {
                        data.value.otherUserName = res.nickName ? res.nickName : res.username;
                    });
            });
        };

        const copyAction = (content: string) => {
            Utils.clipboard({ text: content.toString() });
        };
        const getCategory = () => {
            if (data.value.id == 0) return "";
            const arr = computed(() => [
                langObj.value.l0037,
                langObj.value.l0056,
                langObj.value.l0067,
                langObj.value.l0038,
                langObj.value.l0058,
                langObj.value.l0059,
                langObj.value.l0060,
                langObj.value.l0061,
                langObj.value.l0068,
                langObj.value.l0062,
                langObj.value.l0069,
                langObj.value.l0063,
                langObj.value.l0064,
            ]);

            return arr.value[data.value.type];
        };
        const getSubTitle = () => {
            if (data.value.type == 0) {
                return data.value.mode == 1 ? langObj.value.l0087 : langObj.value.l0088;
            } else if (data.value.type == 1) {
                return data.value.mode == 1 ? langObj.value.l0090 : langObj.value.l0091;
            } else if (data.value.type == 2) {
                return langObj.value.l0067;
            } else if (data.value.type == 3) {
                if (data.value.status == 3) {
                    return langObj.value.l0092;
                } else if (data.value.status == 4 || data.value.status == 10) {
                    return langObj.value.l0093;
                } else {
                    return langObj.value.l0094;
                }
            } else if (data.value.type == 4) {
                return langObj.value.l0058;
            } else if (data.value.type == 5) {
                return data.value.mode == 1 ? langObj.value.l0095 : langObj.value.l0096;
            } else if (data.value.type == 6) {
                return data.value.mode == 1 ? langObj.value.l0097 : langObj.value.l0098;
            } else if (data.value.type == 7) {
                if (data.value.mode == 1 && data.value.payUserId && data.value.payUserId.toString() == tokenObj.user_id) {
                    return langObj.value.l0099;
                } else {
                    return data.value.mode == 1 ? langObj.value.l0017 + "USDT" : langObj.value.l0018 + "USDT";
                }
            } else if (data.value.type == 8) {
                return "";
            } else if (data.value.type == 9) {
                return langObj.value.l0062;
            } else if (data.value.type == 11) {
                return langObj.value.l0063;
            } else if (data.value.type == 12) {
                return langObj.value.l0064;
            } else {
                return "";
            }
        };
        const getTime = (time: number) => {
            return Utils.formatDateYMDHMS(time);
        };
        return {
            langObj,
            refreshOrder,
            user: computed(() => $store.state.userInfo),
            getTime,
            data,
            copyAction,
            getCategory,
            getSubTitle,
        };
    },
});

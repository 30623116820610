/**
 * 转账提交的参数
 * */
 export default class batchTransferSubVo {
    /**数字货币种类*/
    currencyCode = "";
    /**支付密码*/
    payPassword = "";
    /**支付方id*/
    payUserId = "";
    receiveInfos = 
    [
        {   
            /**接收方id*/
            receiveUserId: "",
            /**金额*/
            amount:0,
            receiveCodeId:0,
        }
    ];
    /**说明*/
    remarks = "";
    /**转账类型*/
    type = 0;
}

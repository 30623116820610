
import { defineComponent, ref, computed, ComputedRef, onMounted } from "vue";
import TransferSubVo from "@/assets/vo/TransferSubVo";
import UserInfoVo from "@/assets/vo/UserInfoVo";
import Lang from "@/assets/lang";
import mobileArr from "@/assets/ts/mobileArr";
import { useStore } from "vuex";
import { getMyBalance, postTransfer, queryUser } from "@/assets/http";
import { ElMessage } from "element-plus";
import Utils from "@/assets/ts/utils";
import { useRouter } from "vue-router"; //引入路由
export default defineComponent({
    setup() {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const $router = useRouter();
        const payTransferMin = computed(() => {
            const _obj = $store.state.captialConfig.USDT;
            if (_obj) return _obj.payTransferMin;
            return 0;
        });
        const payTransferMax = computed(() => {
            const _obj = $store.state.captialConfig.USDT;
            if (_obj) return _obj.payTransferMax;
            return 0;
        });
        const showRight = ref(false);
        const tabArr = computed(() => [langObj.value.l0074, langObj.value.l0108]);
        const tabI = ref(0);
        const onTab = (index: number) => {
            if (tabI.value == index || showRight.value) return;
            tabI.value = index;
        };
        const balance = computed(() => $store.state.balance);
        const userInfo: ComputedRef<UserInfoVo> = computed(() => $store.state.userInfo);
        const phCode = ref(mobileArr[0]);
        const userPhone = ref("");
        const userId = ref("");
        const payPassword = ref("");
        const transferSub = ref(new TransferSubVo());
        let _amount = "";
        const onContent = () => {
            if (!Utils.inputNum(transferSub.value.amount, 6)) {
                transferSub.value.amount = _amount;
                return;
            }
            const _max = Math.min(balance.value, payTransferMax.value);
            if (Number(transferSub.value.amount) > _max) transferSub.value.amount = _max.toString();
            _amount = transferSub.value.amount;
        };
        const allSelect = () => {
            if (showRight.value) return;
            transferSub.value.amount = balance.value;
            onContent();
        };
        const heUserInfo = ref(new UserInfoVo());
        const Submit1 = () => {
            let param = "";
            if (tabI.value == 0) {
                param = userId.value;
                if (!param) {
                    ElMessage.error(langObj.value.l0122);
                    return;
                }
                if (param == userInfo.value.userId) {
                    ElMessage.error(langObj.value.l0123);
                    return;
                }
            }
            if (tabI.value == 1) {
                param = phCode.value.code + "_" + userPhone.value;
                if (!param) {
                    ElMessage.error(langObj.value.l0127);
                    return;
                }
                if (param == userInfo.value.phone) {
                    ElMessage.error(langObj.value.l0123);
                    return;
                }
            }
            if (!transferSub.value.amount) {
                ElMessage.error(langObj.value.l0016);
                return;
            }
            if (Number(transferSub.value.amount) < payTransferMin.value) {
                ElMessage.error(langObj.value.l0124);
                return;
            }
            queryUser(tabI.value, param, (data: any) => {
                if (data) {
                    Utils.updateObj(heUserInfo.value, data);
                    showRight.value = true;
                } else {
                    ElMessage.error(langObj.value.l0125);
                }
            });
        };
        const onCancel = () => {
            transferSub.value.amount = "";
            transferSub.value.remarks = "";
            userId.value = "";
            transferSub.value.payPassword = "";
            showRight.value = false;
        };
        const Submit2 = () => {
            if (!payPassword.value) {
                ElMessage.error(langObj.value.l0121);
                return;
            }
            transferSub.value.currencyCode = "USDT";
            transferSub.value.payUserId = userInfo.value.userId;
            transferSub.value.receiveUserId = heUserInfo.value.userId;
            transferSub.value.payPassword = payPassword.value;
            payPassword.value = "";
            postTransfer(transferSub.value, () => {
                ElMessage.success(langObj.value.l0126);
                getMyBalance();
                onCancel();
            });
        };
        onMounted(() => {
            const data: any = $router.currentRoute.value.query;
            if (data.userId) {
                Utils.updateObj(heUserInfo.value, data);
                userId.value = data.userId;
                transferSub.value.amount = data.amount || "";
                showRight.value = true;
            }
        });
        return {
            langObj,
            Utils,
            payTransferMin,
            payTransferMax,
            tabArr,
            tabI,
            onTab,
            balance,
            showRight,
            mobileArr,
            phCode,
            userPhone,
            payPassword,
            userId,
            onContent,
            transferSub,
            userInfo,
            allSelect,
            heUserInfo,
            onCancel,
            Submit1,
            Submit2,
        };
    },
});

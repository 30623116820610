import XLSX from 'xlsx'
function xlsxTool(file: any) {
    return new Promise((fn: (r: object) => void, error: () => void) => {
        var reader = new FileReader();
        reader.onload = function (e: any) {
            try {
                var data = e.target.result;
                var workbook = XLSX.read(data, { type: 'binary' });
                //console.log(workbook);
 
                var datas = workbook.Sheets[workbook.SheetNames[0]];
                //console.log(datas);
 
                var allColumn = [];
                for (var i = 0; i < 26; i++) {
                    allColumn.push(String.fromCharCode((65 + i)));
                }
 
                var hread: any = [];
                var column: any = [];
                allColumn.forEach(d => {
                    if (datas[`${d}1`] != null) {
                        hread.push(datas[`${d}1`].v);
                        column.push(d);
                    }
                })
 
                var objs = [];
                for (var i = 2; i <= 100; i++) {
                    if (datas[`A${i}`] == null) {
                        break;
                    }
                    var obj = {};
                    column.forEach((d: any, ii: number) => {
                        (obj as any)[`${hread[ii]}`] = datas[`${d}${i}`] == null ? "" : datas[`${d}${i}`].v;
                    })
                    objs.push(obj);
                }
                //console.log(objs);
                fn(objs);
            }
            catch (errorMsg) {
                error();
            }
        };
        reader.readAsBinaryString(file);
    })
}
 
 
export default xlsxTool
/**商户获取自己广告下的交易订单**/
export default class OrderDetailsVo {
    /**广告id（关联到广告表）*/
    advertisId = 0;
    /**广告类型*/
    advertisementType = 0;
    /**币商id*/
    advertisementUserId = "";
    /**数量*/
    amount = 0;
    /**订单创建时间*/
    createTime = 0;
    /**支付时间*/
    payTime = 0;
    /**订单id*/
    id = "";
    /**订单号*/
    orderNo = "";
    /**购买id*/
    payUserId = "";
    /**购买名*/
    payUserName = "";
    /**支付id*/
    paymentMethodId = "";
    /**支付方式*/
    paymentMethodTypes = "";
    /**出售id*/
    receiveUserId = "";
    /**出售名*/
    receiveUserName = "";
    /**
     * 订单状态：0：待支付 1：待放行 2 已完成 3：已取消、4:申诉中
     * 0-等待买家付款 1-确认放行 2-交易完成 3-交易取消 4-确认放行
     * 0-请付款 1-等待卖家确认 2-交易完成 3-交易取消 4-等待卖家确认
     * 0-若买家长时间未付款，建议主动联系对方 1-买家已付款，请确认放行 2-交易已经完成 3-交易已经取消 4-申诉中
     * 0-请您尽快付款 1-您已付款，等待卖家确认 2-交易已经完成 3-交易已经取消 4-申诉中
     */
    status = 0;
    /**总价*/
    totalCny = 0;
}

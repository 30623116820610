import { Method } from "axios";

export enum MethodEnum {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete",
}

/**接口参数*/
export interface AjaxObj {
    /**接口地址*/
    url: string;
    /**是否关闭此接口统一报错提示*/
    hideCatch?: boolean;
    /**接口协议*/
    method?: MethodEnum;
    /**接口参数*/
    data?: any;
    /**成功的回调函数*/
    then?: Function;
    /**失败的回调函数*/
    fail?: Function;
    /**接口报错回调函数*/
    catch?: Function;
}

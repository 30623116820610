import { ElMessage } from "element-plus";
import Lang from "../lang";
interface CopyObj {
    /**复制的字符串*/
    text: string;
    /**是否隐藏提示*/
    hideTost?: boolean;
    /**成功的回调*/
    success?: Function;
    /**失败的回调函数*/
    fail?: Function;
}
export default class Utils {
    /**
     * 根据参数更新对象 --nk
     *  @param {any} main 主对象
     *  @param {any} obj 更新对象
     *  @param {Boolean} isfilter 是否过滤obj的key值 (过滤，当主对象上没有key而更新对象有key，过滤掉key)
     */
    public static updateObj(main: any, obj: any, isfilter = true): any {
        for (const key in obj) {
            if (isfilter) main[key] !== undefined && (main[key] = obj[key]);
            else main[key] = obj[key];
        }
        return main;
    }

    /**
     *  获取url参数
     *  @param {any} name key值
     */
    public static getUrlValue(name: string) {
        const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        const r = window.location.search.substr(1).match(reg); //search,查询？后面的参数，并匹配正则
        if (r != null) return unescape(r[2]);
        return null;
    }

    /**
     * 设置对象 --nk
     * @param {Object} main 主对象
     * @param {Object} obj 更新对象
     * @param {Boolean} isRetain 是否保留obj的null值 (过滤，当主对象上没有key而更新对象有key，过滤掉key)
     */
    public static setObj(main: any, obj: any, isRetain = true): any {
        for (const key in main) {
            if (isRetain) obj[key] != null && (main[key] = obj[key]);
            else main[key] = obj[key];
        }
        return main;
    }
    //隐藏字符串中间内容（*）--nk
    public static hideMiddle(str: string): string | null {
        if (!str) return null;
        const num1 = Math.ceil(str.length / 3);
        const num2 = Math.floor(str.length / 3);
        return str.substr(0, num2) + "*".repeat(num1) + str.substr(num1 + num2);
    }

    /**时间戳转对象**/
    public static getFormData(timeStamp: number): any {
        if (!timeStamp) return {};
        const date = new Date(timeStamp);
        const y: number = date.getFullYear();
        const m: number = date.getMonth() + 1;
        const d: number = date.getDate();
        const h: number = date.getHours();
        const mi: number = date.getMinutes();
        const s: number = date.getSeconds();
        return { y: y, m: m, d: d, h: h, mi: mi, s: s };
    }

    /**时间戳转时间--2020-02-02 00:00:00**/
    public static formatDateTime(timeStamp: number): string {
        if (!timeStamp) return "";
        const { y, m, d, h, mi, s } = Utils.getFormData(timeStamp);
        return (
            y +
            "-" +
            (m < 10 ? "0" + m : m) +
            "-" +
            (d < 10 ? "0" + d : d) +
            " " +
            (h < 10 ? "0" + h : h) +
            ":" +
            (mi < 10 ? "0" + mi : mi) +
            ":" +
            (s < 10 ? "0" + s : s)
        );
    }

    /**时间戳转时间--2020-02-02**/
    public static formatDateYMD(timeStamp: number): string {
        if (!timeStamp) return "";
        const { y, m, d } = Utils.getFormData(timeStamp);
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    }

    /**时间戳转时间--2020-02-02**/
    public static formatDateYMDHMS(timeStamp: number): string {
        if (!timeStamp) return "";
        const { y, m, d, h, mi, s } = Utils.getFormData(timeStamp);
        return (
            y +
            "-" +
            (m < 10 ? "0" + m : m) +
            "-" +
            (d < 10 ? "0" + d : d) +
            " " +
            (h < 10 ? "0" + h : h) +
            ":" +
            (mi < 10 ? "0" + mi : mi) +
            ":" +
            (s < 10 ? "0" + s : s)
        );
    }

    /**时间戳转时间--02-02 00:00:00**/
    public static formatDateMD(timeStamp: number): string {
        if (!timeStamp) return "";
        const { m, d, h, mi, s } = Utils.getFormData(timeStamp);
        return (
            (m < 10 ? "0" + m : m) +
            "-" +
            (d < 10 ? "0" + d : d) +
            " " +
            (h < 10 ? "0" + h : h) +
            ":" +
            (mi < 10 ? "0" + mi : mi) +
            ":" +
            (s < 10 ? "0" + s : s)
        );
    }
    /**时间date转时间--00:00:00**/
    public static formatDateToString(date: Date): string {
        if (!date) return "";
        const getMonth = (date.getMonth() + 1).toString().length == 1 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
        const hour = date.getHours().toString().length == 1 ? "0" + date.getHours().toString() : date.getHours();
        const getDate = date.getDate().toString().length == 1 ? "0" + date.getDate().toString() : date.getDate();
        const getMinutes = date.getMinutes().toString().length == 1 ? "0" + date.getMinutes().toString() : date.getMinutes();
        const getSeconds = date.getSeconds().toString().length == 1 ? "0" + date.getSeconds().toString() : date.getSeconds();

        const Str = date.getFullYear() + "-" + getMonth + "-" + getDate + " " + hour + ":" + getMinutes + ":" + getSeconds;
        return Str;
    }
    /**时间戳转时间--00:00:00**/
    public static formatDateHMS(timeStamp: number): string {
        if (!timeStamp) return "";
        const { h, mi, s } = Utils.getFormData(timeStamp);
        return (h < 10 ? "0" + h : h) + ":" + (mi < 10 ? "0" + mi : mi) + ":" + (s < 10 ? "0" + s : s);
    }
    /**计算倒计时**/
    public static getCountdown(timeI: number, isHour = true): string {
        if (timeI >= 0) {
            const hour: number = Math.floor(timeI / 3600); // 时
            const minute: number = Math.floor((timeI - hour * 3600) / 60); // 分
            const second: number = Math.floor(timeI - hour * 3600 - minute * 60); // 秒
            const h = hour < 10 ? "0" + hour : hour;
            const m = minute < 10 ? "0" + minute : minute;
            const s = second < 10 ? "0" + second : second;
            if (isHour) return h + ":" + m + ":" + s;
            else return m + ":" + s;
        } else {
            if (isHour) return "00:00:00";
            else return "00:00";
        }
    }

    /*************复制-开始************/
    private static clipTextarea: HTMLTextAreaElement;
    /***
     * 复制
     * @param {CopyObj} data{text:string-复制字符串，success：Function 成功后回调函数 fail:失败回调 hideTost:隐藏系统成功提示}
     * **/
    public static clipboard(data: CopyObj) {
        if (!data.text) {
            ElMessage.error(Lang.langObj.l0196);
            return;
        }
        if (!Utils.clipTextarea) {
            Utils.clipTextarea = document.createElement("textarea");
            Utils.clipTextarea.style.cssText = "z-index: -1000;position: fixed;left: -100px;top: -100px;width: 10px;height: 10px;";
            document.body.appendChild(Utils.clipTextarea);
        }
        Utils.clipTextarea.value = data.text;
        Utils.clipTextarea.select();
        if (document.execCommand("Copy")) {
            !data.hideTost && ElMessage.success(Lang.langObj.l0271);
            data.success && data.success();
        } else {
            !data.hideTost && ElMessage.error(Lang.langObj.l0089);
            data.fail && data.fail();
        }
    }
    /*************复制-结束************/

    /**
     * 判断是否手机号
     * @param {string} phone 手机号
     * @param {string} reg 正则表达式
     * return boolean
     */
    public static isPhone(phone: string, reg: string): boolean {
        if (!phone) return false;
        const regExp = new RegExp(reg);
        return regExp.test(phone);
    }

    /**
     * 判断input数字 允许为空
     * @param {string} num 输入的内容
     * @param {number} decimal 小数位
     * @return boolean
     */
    public static inputNum(num: string, decimal: number): boolean {
        const _arr = num.toString().split(".");
        if (num == ".") return false;
        if (num == "00") return false;
        if (num.length == 2 && num[0] == "0" && num[1] != ".") return false;
        if (decimal == 0 && num.endsWith(".")) return false;
        if (_arr.length == 2 && _arr[1].length > decimal) return false;
        return Number(num) >= 0;
    }

    /**
     * 判断input数字 最小数大于0 可以为空
     * @param {string} num 输入的内容
     * @param {number} decimal 小数位
     * return boolean
     */
    public static inputNumMin(num: string, decimal: number, min?: string): boolean {
        if (!min) {
            if (decimal > 0) min = "0." + "0".repeat(decimal - 1) + "1";
            else min = "0";
        }
        const _numArr = num.split(".");
        const _minArr = min.split(".");
        console.log(num);
        if (num === "") return true;
        // if ((num === _minArr[0] || num === _minArr[0] + ".") && decimal > 0) return true; //小数位前
        if (_numArr[1].length > decimal) return false;
        if (Number(_numArr[1]) < Number(_minArr[1].substring(0, _numArr[1].length))) return false; //小数位后

        if (_numArr.length == 2 && _numArr[1].length > decimal) return false;
        if (_numArr.length == 2 && _numArr[1].length < decimal) return true;
        return Number(num) >= 0;
    }

    /**
     * 数字保留小数位 返回字符串（强制保留小数）--nk
     * @param {String} value 要处理的数字
     * @param {Number} pow 保留位数
     * @param {Boolean} isTop 是否向上取整，默认向下
     * @param {Boolean} isface 当小数位小于保留位，是否直接return
     * 说明：当数字本身的小数位大于要取整的小数位时，不进行Math的运算--数字精准度(20.01)
     */
    public static numberStr(value: number | string = "0", decimal = 0, isTop = false, isface = false): string {
        let _n = 0;
        const _nStr = value.toString().split(".")[1];
        _nStr && (_n = _nStr.length);
        if (isface) {
            if (!_nStr || _nStr.length <= decimal) return value.toString();
        }
        let _num;
        if (_n > decimal) {
            const _multiple = Math.pow(10, decimal);
            _num = Math[isTop ? "ceil" : "floor"](Number(value) * _multiple) / _multiple;
        } else {
            _num = Number(value);
        }
        return _num.toFixed(decimal);
    }

    /*加法*/
    public static addition(arg1: number, arg2: number): number {
        let r1, r2;
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        const m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    }
    /*减法*/
    public static subtraction(arg1: number, arg2: number): number {
        let r1, r2;
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        const m = Math.pow(10, Math.max(r1, r2));
        //last modify by deeka
        //动态控制精度长度
        const n = r1 >= r2 ? r1 : r2;
        return Number(((arg1 * m - arg2 * m) / m).toFixed(n));
    }
    /*乘法*/
    public static multiplication = (arg1: string | number, arg2: string | number) => {
        let m = 0;
        const s1 = arg1.toString();
        const s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        } catch (e) {
            console.log(m);
        }
        try {
            m += s2.split(".")[1].length;
        } catch (e) {
            console.log(m);
        }
        return (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);
    };
    /*除法*/
    public static division(arg1: string | number, arg2: string | number): number {
        let t1 = 0,
            t2 = 0;
        try {
            t1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            console.log(t1);
        }
        try {
            t2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            console.log(t2);
        }
        const r1 = Number(arg1.toString().replace(".", ""));
        const r2 = Number(arg2.toString().replace(".", ""));
        return r1 / r2 / Math.pow(10, t1 - t2);
    }

    /**生成随机数--暂时为ws消息id使用**/
    public static createUUID(): string {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
    }

    /**
     * 判断钱包地址是否正确
     * @param {String} type 类型
     * @param {String} address 地址
     */
    public static walletAddress(type: string, address: string): boolean {
        if (type == "ERC20") return /^(0x)?[0-9a-f]{40}$/i.test(address);
        if (type == "TRC20")
            return (
                typeof address === "string" &&
                address.length == 34 &&
                address
                    .substr(0, 1)
                    .toUpperCase()
                    .charCodeAt(0) == 84
            );
        return false;
    }
}


import Lang from "@/assets/lang";
import { postFreeTradeHistory, tokenObj } from "@/assets/http";
import Utils from "@/assets/ts/utils";
import OrderDetails from "./OrderDetails.vue";
import OrderChat from "./OrderChat.vue";
import { defineComponent, ref, Ref, onMounted, onUnmounted, computed } from "vue";
import EventBus from "@/assets/ts/eventBus";
import Config from "@/assets/ts/config";
import OrderDetailsVo from "@/assets/vo/OrderDetailsVo";

export default defineComponent({
    name: "PlaceOrder",
    components: { OrderDetails, OrderChat },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const orderDetails = ref();
        const orderChat = ref();
        const isMySell = (id: string) => {
            return tokenObj.user_id == id; //是否我出售
        };
        let orderId = "";
        const itemBoxI = ref(-1);
        const onItemBox = (item: any, index: number) => {
            if (itemBoxI.value == index) return;
            itemBoxI.value = index;
            orderId = item.id;
            const _isMySell = isMySell(item.payUserId);
            orderDetails.value.onShow(item, _isMySell);
            orderChat.value.onShow(item, _isMySell);
        };
        const isLoadEnd = ref(false);
        const isLoadStart = ref(false);
        let pageNumber = 0; //当前页数
        let totalPages = 0; //总页数
        const recordArr: Ref<any[]> = ref([]);
        const onFreeTradeHistory = () => {
            isLoadStart.value = true;
            isLoadEnd.value = true;
            pageNumber++;
            const _obj = { current: pageNumber, size: 6, userId: tokenObj.user_id };
            postFreeTradeHistory(_obj, (data: any) => {
                isLoadStart.value = false;
                if (pageNumber == 1) recordArr.value = [];
                recordArr.value = [...recordArr.value, ...data.content];
                totalPages = data.totalPages;
                pageNumber = data.pageNumber;
                isLoadEnd.value = pageNumber >= totalPages;
            });
        };
        const refreshChat = (_data: any, _orderDetail: OrderDetailsVo) => {
            const _index = recordArr.value.findIndex((item: any) => item.id == _data.orderId);
            /*_data.type 聊天消息 1 为文本 2 为图片 3 订单创建通知 4 订单付款通知 5 订单放行通知 6 申诉 7 取消 8 二次提醒支付 12 取消申诉 13 二次提醒放行**/
            /**创建订单&&在交易中&&币商是自己*/
            if (_index >= 0) {
                recordArr.value[_index] = _orderDetail;
            } else if (_data.type == 3 && isMySell(_orderDetail.advertisementUserId)) {
                recordArr.value.unshift(_orderDetail);
                if (itemBoxI.value != -1) itemBoxI.value++;
                if (pageNumber < totalPages) recordArr.value.pop();
            }
            /**判断是否显示当前订单**/
            if (_data.orderId == orderId) {
                orderDetails.value.updataOrderDetail(_orderDetail);
            }
        };
        onMounted(() => {
            EventBus.on(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        onUnmounted(() => {
            EventBus.remove(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        return {
            langObj,
            Config,
            orderDetails,
            orderChat,
            isMySell,
            itemBoxI,
            onItemBox,
            formatDateTime: (time: number) => {
                return Utils.formatDateTime(time);
            },
            showName: (data: any) => {
                //显示的名字
                if (isMySell(data.payUserId)) return data.receiveUserName;
                else return data.payUserName;
            },
            getStatus: (num: number) => {
                //0：待支付款 1：支付成功等待放行 2 已完成 3：已取消、4:申诉中
                const arr = computed(() => [langObj.value.l0392, langObj.value.l0393, langObj.value.l0390, langObj.value.l0391, langObj.value.l0345]);
                return arr.value[num];
            },
            getClassName: (data: any) => {
                //状态的颜色
                const _isMySell = isMySell(data.payUserId);
                if ((_isMySell && data.status == 1) || (!_isMySell && data.status == 0)) {
                    return "fontRed";
                } else if (data.status == 4) {
                    return "fontBright";
                } else {
                    return "";
                }
            },
            isLoadEnd,
            isLoadStart,
            recordArr,
            onFreeTradeHistory,
        };
    },
});


import { currencyOrderStatistics, getRecords, tokenObj, getCurrencyType } from "@/assets/http";
import BillDetailsVo from "@/assets/vo/BillDetailsVo";
import Utils from "@/assets/ts/utils";
import { ElMessage } from "element-plus";
import { computed, defineComponent, ref, onMounted, Ref } from "vue";
import { useStore } from "vuex";
import MyBillDetail from "../my/MyBillDetail.vue";
import Lang from "@/assets/lang";
export default defineComponent({
    components: { MyBillDetail },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const data = ref(new BillDetailsVo());
        const $store = useStore();
        const type = ref("");
        const max = ref("");
        const min = ref("");
        const startTime = ref("");
        const endTime = ref("");
        const itemBoxI = ref(-1);
        const isLoadEnd = ref(false);
        const isLoadStart = ref(false);
        const list: Ref<any[]> = ref([]);
        const page = ref(1);
        const totalPages = ref(0); //总页数
        const selectPayIndex = ref(-1);
        const currencyType = ref(-1);
        const selectItem = ref({});
        const detailView = ref();
        const myOptions = computed(() => [
            {
                type: -1,
                title: langObj.value.l0007,
            },
            {
                type: 0,
                title: langObj.value.l0037,
            },
            {
                type: 1,
                title: langObj.value.l0056,
            },
            {
                type: 3,
                title: langObj.value.l0038,
            },
            {
                type: 4,
                title: langObj.value.l0058,
            },
            {
                type: 5,
                title: langObj.value.l0059,
            },
            {
                type: 6,
                title: langObj.value.l0060,
            },
            {
                type: 7,
                title: langObj.value.l0061,
            },
            {
                type: 9,
                title: langObj.value.l0062,
            },
            {
                type: 11,
                title: langObj.value.l0063,
            },
            {
                type: 12,
                title: langObj.value.l0064,
            },
        ]);
        const currencyTypeList: Ref<any[]> = ref([
            {
                code: -1,
                id: -1,
                descriptionEn: langObj.value.l0007,
            }
        ]);
        const getData = () => {
            const item: any = Object.create(null);
            item.current = page.value;
            item.size = 10;
            item.userId = tokenObj.user_id;

            if (max.value.length > 0) {
                item.max = max.value;
            }
            if (min.value.length > 0) {
                item.min = min.value;
            }
            if (startTime.value.length > 0) {
                item.startTime = startTime.value;
            }
            if (endTime.value.length > 0) {
                item.endTime = endTime.value;
            }
            if (type.value.length > 0) {
                item.type = type.value;
            }
            if(currencyType.value != -1) {
                item.currencyCode = currencyType.value;
            }
            getRecords(item, (res: any) => {
                if (page.value == 1) list.value = [];
                list.value = [...list.value, ...res.content];
                totalPages.value = res["totalRecords"];
            });
        };
        const getAsset = () => {
            const item = Object.create(null);
            item.userId = tokenObj.user_id;
            item.currencyCode = currencyType.value;
            if (max.value.length > 0) {
                item.max = max.value;
            }
            if (min.value.length > 0) {
                item.min = min.value;
            }
            if (startTime.value.length > 0) {
                item.startTime = startTime.value;
            } else {
                ElMessage.error(langObj.value.l0065);
                return;
            }
            if (endTime.value.length > 0) {
                item.endTime = endTime.value;
            } else {
                ElMessage.error(langObj.value.l0066);
                return;
            }
            if (type.value.length > 0) {
                item.type = type.value;
            }
            if(currencyType.value == -1) {
                item.currencyCode = "";
            }

            currencyOrderStatistics(item, (res: any) => {
                console.log(res);
                data.value = res;
            });
        };
        onMounted(() => {
            startTime.value = Utils.formatDateToString(new Date()).substring(0, 8) + "01";
            endTime.value = Utils.formatDateToString(new Date());
            getData();
            getAsset();
            getCurrencyTypeList()
        });
        const reset = () => {
            min.value = "";
            max.value = "";
            selectItem.value = {};
            startTime.value = Utils.formatDateToString(new Date()).substring(0, 8) + "01";
            endTime.value = Utils.formatDateToString(new Date());
            selectPayIndex.value = -1;
            currencyType.value = -1;
            type.value = "";
            getData();
            getAsset();
        };
        const getCategory = (type: number) => {
            const arr = computed(() => [
                langObj.value.l0037,
                langObj.value.l0056,
                langObj.value.l0067,
                langObj.value.l0038,
                langObj.value.l0058,
                langObj.value.l0059,
                langObj.value.l0060,
                langObj.value.l0061,
                langObj.value.l0068,
                langObj.value.l0062,
                langObj.value.l0069,
                langObj.value.l0063,
                langObj.value.l0064,
                langObj.value.l0440,
                langObj.value.l0441,
            ]);
            return arr.value[type];
        };
        const ensureFilter = () => {
            selectItem.value = {};
            if (startTime.value.length == 0) {
                ElMessage.error(langObj.value.l0065);
                return;
            }
            if (endTime.value.length == 0) {
                ElMessage.error(langObj.value.l0066);
                return;
            }
            if (max.value < min.value) {
                ElMessage.error(langObj.value.l0070);
                return;
            }
            page.value = 1;

            getData();
            getAsset();
        };
        const selectType = (item: any) => {
            type.value = item == -1 ? "" : item.toString();
        };
        const getTime = (time: number) => {
            return Utils.formatDateMD(time);
        };
        const getCurrencyTypeList = () => {
            getCurrencyType((data: any) =>{
                currencyTypeList.value = currencyTypeList.value.concat(data)
            })
        };
        const onItemBox = (item: any) => {
            selectItem.value = item;
            detailView.value.refreshOrder(selectItem.value);
        };
        const selectStartTime = (value: any) => {
            startTime.value = Utils.formatDateToString(value);
        };
        const selectEndTime = (value: any) => {
            endTime.value = Utils.formatDateToString(value);
        };
        const handleCurrentChange = (value: any) => {
            page.value = value;
            getData();
        };
        return {
            langObj,
            data,
            type,
            detailView,
            endTime,
            getTime,
            getCategory,
            getCurrencyTypeList,
            totalPages,
            startTime,
            max,
            min,
            list,
            currencyType,
            currencyTypeList,
            // page,//阿申，这里啥意思
            selectItem,
            onItemBox,
            selectStartTime,
            selectEndTime,
            handleCurrentChange,
            isLoadEnd,
            itemBoxI,
            getData,
            myOptions,
            reset,
            selectPayIndex,
            isLoadStart,
            ensureFilter,
            selectType,
            user: computed(() => $store.state.userInfo),
        };
    },
});

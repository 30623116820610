
import Lang from "@/assets/lang";
import { getPayIcon } from "@/assets/ts/common";
import Config from "@/assets/ts/config";
import { computed, defineComponent } from "vue";
export default defineComponent({
    name: "Wares",
    props: {
        goods: Object,
    },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const titleArr = [langObj.value.l0017, langObj.value.l0018];
        const getIconArr = (str: string) => {
            if (!str) return [];
            const _arr = str.split(",");
            return _arr.map(item => getPayIcon(item));
        };
        return {
            langObj,
            Config,
            getIconArr,
            titleArr,
        };
    },
});

/***
 * 私有的目前写在一个文件中
 * 意思是这个分支单独存在的中英文
 * 方便文件冲突
 * **/

export const zh = {
    sy0001: "私有的",
};

export const en = {
    sy0001: "private",
};


import { defineComponent, onMounted } from "vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Config from "./assets/ts/config";
export default defineComponent({
    name: "App",
    components: {
        Header,
        Footer,
    },
    setup() {
        onMounted(() => {
            const _obj: any = document.querySelector("link[rel*='icon']");
            if (_obj) _obj.href = "/ico/favicon" + Config.project.name + ".ico";
            document.title = Config.project.title;
        });
    },
});


import userAssets from "./UserAssetItem.vue";
import { computed, defineComponent } from "vue";
import Lang from "@/assets/lang";

export default defineComponent({
    name: "My",
    components: { userAssets },

    setup() {
        const langObj = computed(() => Lang.langObj);
        return { langObj };
    },
});

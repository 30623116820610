
import { useStore } from "vuex";
import Utils from "@/assets/ts/utils";
import { computed, defineComponent, onMounted, ref } from "vue";
import { getMyBalance, tokenObj } from "@/assets/http";
import { ElMessage } from "element-plus";
import Lang from "@/assets/lang";
export default defineComponent({
    name: "UserAssets",
    components: {},

    setup() {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const balance = computed(() => Number($store.state.balance).toFixed(6));
        const merchant = ref(tokenObj.merchant);
        const startRefresh = ref(false);

        onMounted(() => {
            console.log("ddddd");
        });

        const copyAction = () => {
            Utils.clipboard({ text: tokenObj.user_id });
        };

        //更新状态
        const refresh = () => {
            startRefresh.value = true;
            getMyBalance((res: any) => {
                startRefresh.value = false;
                ElMessage.success(langObj.value.l0131);
                console.log(res);
            });
        };

        return {
            langObj,
            user: computed(() => $store.state.userInfo),
            startRefresh,
            copyAction,
            balance,
            merchant,
            refresh,
            formatDateTime: (time: number) => {
                return Utils.formatDateYMD(time);
            },
        };
    },
});


import Lang from "@/assets/lang";
import { computed, defineComponent } from "vue";
export default defineComponent({
    name: "FreeTrade",
    setup() {
        const langObj = computed(() => Lang.langObj);
        return {
            langObj,
        };
    },
});

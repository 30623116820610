import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dbe9447"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatItems scroll" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flexBothEnds" }
const _hoisted_4 = { class: "name fontLong" }
const _hoisted_5 = { class: "fontGrey fontSize12" }
const _hoisted_6 = { class: "flexBothEnds marT5" }
const _hoisted_7 = { class: "news fontLong" }
const _hoisted_8 = {
  key: 0,
  class: "chatNum"
}
const _hoisted_9 = {
  key: 0,
  class: "loadEnd"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_OrderDetails = _resolveComponent("OrderDetails")!
  const _component_OrderChat = _resolveComponent("OrderChat")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    class: "chatList marT10",
    gutter: 20
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatArr, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(['itemBox', 'pad10', 'flexBothEnds', 'pointer', 'borderB', { active: _ctx.itemBoxI == index }]),
                key: item.orderId,
                onClick: ($event: any) => (_ctx.onItemBox(item, index))
              }, [
                _createVNode(_component_el_avatar, {
                  class: "fontSize25",
                  size: 50
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.othersName.substring(0, 1)), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(item.othersName), 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatDateTime(item.ts)), 1)
                  ]),
                  _createElementVNode("p", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.onBody(item)), 1),
                    (item.unread > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.unread), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 10, _hoisted_2))
            }), 128)),
            (_ctx.chatArr.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.langObj.l0055), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_OrderDetails, { ref: "orderDetails" }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_OrderChat, { ref: "orderChat" }, null, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}

import { getPayIcon } from "@/assets/ts/common";
import { defineComponent, ref, onMounted, Ref } from "vue";

export default defineComponent({
    name: "AdvertisAdmin",
    props: {
        paymethodId: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const arr: Ref<any[]> = ref([]);
        onMounted(() => {
            if (props.paymethodId) {
                const _arr = props.paymethodId.split(",");
                arr.value = _arr;
            }
        });
        return {
            arr,
            getPayIcon,
        };
    },
});

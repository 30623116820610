
import Utils from "@/assets/ts/utils";
import { defineComponent, onMounted, computed, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import OrderDetails from "./OrderDetails.vue";
import OrderChat from "./OrderChat.vue";
import { getOrderDetail } from "@/assets/http";
import Lang from "@/assets/lang";
import EventBus from "@/assets/ts/eventBus";
import OrderDetailsVo from "@/assets/vo/OrderDetailsVo";
export default defineComponent({
    name: "ChatList",
    components: { OrderDetails, OrderChat },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const orderDetails = ref();
        const orderChat = ref();
        const $store = useStore();
        const chatArr: any = ref($store.state.newsObj.newsArr);
        const isMySell = (id: string) => {
            return $store.state.userInfo.userId == id; //是否我出售
        };
        let orderId = "";
        const itemBoxI = ref(-1);
        const onItemBox = (item: any, index: number) => {
            if (itemBoxI.value == index) return;
            itemBoxI.value = index;
            orderId = item.orderId;
            const _orderDetails = $store.state.orderDetailsObj[orderId];
            if (_orderDetails) {
                const _isMySell = isMySell(_orderDetails.payUserId);
                orderDetails.value.onShow(_orderDetails, _isMySell);
                orderChat.value.onShow(_orderDetails, _isMySell);
            } else {
                getOrderDetail(item.orderId, (data: OrderDetailsVo) => {
                    const _isMySell = isMySell(data.payUserId);
                    orderDetails.value.onShow(data, _isMySell);
                    orderChat.value.onShow(data, _isMySell);
                    $store.commit("updateOrderDetailsObj", data);
                });
            }
        };
        const refreshChat = (_data: any, _orderDetail: OrderDetailsVo) => {
            /**判断是否显示当前订单**/
            if (_data.orderId == orderId) {
                orderDetails.value.updataOrderDetail(_orderDetail);
            }
        };
        onMounted(() => {
            EventBus.on(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        onUnmounted(() => {
            EventBus.remove(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        return {
            langObj,
            orderDetails,
            orderChat,
            itemBoxI,
            onItemBox,
            chatArr,
            onBody: (_item: any) => {
                if (_item.type == 1) return JSON.parse(_item.body).text;
                else if (_item.type == 2) return "[" + langObj.value.l0387 + "]";
                else return "[" + langObj.value.l0388 + "]";
            },
            formatDateTime: (ts: number) => {
                return Utils.formatDateTime(ts);
            },
        };
    },
});

/**个人信息**/
export default class TokenVo {
    /**token type*/
    token_type = "Bearer";
    /**token*/
    access_token = "";
    /**替换token*/
    refresh_token = "";
    /**用户id*/
    user_id = "";
    /**用户等级*/
    levelId = 1;
    /**商户层级*/
    merchant = 1;
    /**get拼好的token*/
    get getToken(): string {
        if (!this.access_token) return "";
        return this.token_type + " " + this.access_token;
    }
    /**
     * 更新商户层级和用户等级
     * @param {String} key merchant || levelId
     * @param {number} value 需要更新的值
     */
    update(key: "merchant" | "levelId", value: number) {
        const _tokenObj = JSON.parse(sessionStorage.getItem("tokenObj") || "");
        this[key] = value;
        _tokenObj[key] = value;
        sessionStorage.setItem("tokenObj", JSON.stringify(_tokenObj));
    }
    /**清除内存token*/
    onClear(): void {
        this.token_type = "Bearer";
        this.access_token = "";
        this.refresh_token = "";
        this.user_id = "";
        this.merchant = 1;
        this.levelId = 0;
    }
}

/**个人信息*/
export default class UserInfoVo {
    /**中心钱包地址*/
    addr = "";
    /**头像*/
    avatar = "";
    /**创建时间*/
    createTime = 0;
    /**删除标记,1:已删除,0:正常*/
    delFlag = 0;
    /**冻结时间*/
    frozenTime = 0;
    /**广告权限：0 正常 1 锁定*/
    isAdvertise = 0;
    /**用户等级*/
    levelId = 1;
    /**用户所在层级名称*/
    levelName = "";
    /**锁定标记,0:正常,9:已锁定*/
    lockFlag = 0;
    /**邮箱*/
    mail = "";
    /**昵称*/
    nickName = "";
    /**消息通知 0:启用 1:未启用*/
    noticeState = 1;
    /**密码*/
    password = "";
    /**支付密码*/
    payPassword = "";
    /**手机号*/
    phone = "";
    /**显示的手机号*/
    get showPhone(): string {
        const _arr = this.phone.split("_");
        return "+" + _arr[0] + " " + _arr[1];
    }
    /**用户代理人*/
    proxy = "";
    /**是否是风险用户; 0 不是 1 是*/
    risk = 0;
    /**谷歌验证码密钥*/
    secret = "";
    /**谷歌验证是否开启 0 未开启 1 已开启*/
    secretType = 0;
    /**交易是否冻结字段 0 正常 9 锁定*/
    transactionLockFlag = 0;
    /**交易冻结解冻时间，为null 表示冻结永久*/
    transactionThawTime = 0;
    /**默认密码 0:未重置 1:重置过*/
    type = 0;
    /**修改时间*/
    updateTime = 0;
    /**主键 用户ID*/
    userId = "";
    /**用户名*/
    username = "";
    /**钱包状态 : 0-正常，9-锁定*/
    walletStatus = 0;
}


import Lang from "@/assets/lang";
import { defineComponent, ref, onMounted, Ref, computed } from "vue";
import Config from "@/assets/ts/config";
import { getActivePaymentList, createAdvertisment, resetAdvertise, imgHeader } from "@/assets/http";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import route from "../../router";
import paymentView from "./paymentView.vue";
import store from "@/store";
import { getPayIcon, getPayName } from "@/assets/ts/common";

export default defineComponent({
    name: "AdvertisPublish",
    components: { paymentView },
    props: {
        paymethodId: {
            type: String,
            default: "",
        },
    },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const selectType = ref(0); //界面type: 0购买1出售
        const paymentList: Ref<any[]> = ref([]);
        const paymentArrBuy: Ref<any[]> = ref([]);
        const paymentArrSell: Ref<any[]> = ref([]);
        const clonAdvertise: any = ref({ id: 0, type: 0 });
        const $store = useStore();
        const user = ref(computed(() => $store.state.userInfo));
        const showEnsureAlert = ref(false);
        const unitPrice = ref("");
        const amount = ref("");
        const maxQuota = ref("");
        const minQuota = ref("");
        const selectTime = ref(1);
        const showLeaveMsg = ref(false);
        const leaveMsg = ref("");
        const imageMsg = ref("");
        const fileHost = ref(Config.project.host);
        const resetOriAd = ref(false);
        const balance = computed(() => store.state.balance);

        const initDefaultData = () => {
            paymentList.value = [];
            Config.currency.paymentArr.value.forEach((data: any) => {
                const newData = { type: 0, select: false, status: 0 };
                newData.type = data.type;
                newData.select = false; //是否选中
                newData.status = 0; //可用
                paymentList.value.push(newData);
                console.log("------6--6-6-6");
                console.log(newData);
            });
        };

        //是否克隆
        const initCloneData = (arr: any) => {
            clonAdvertise.value = $store.state.clonAdvertise;

            if (clonAdvertise.value && clonAdvertise.value.id) {
                selectType.value = clonAdvertise.value.type == 0 ? 1 : 0;
                unitPrice.value = clonAdvertise.value.unitPrice;
                amount.value = clonAdvertise.value.amount;
                minQuota.value = clonAdvertise.value.minQuota;
                maxQuota.value = clonAdvertise.value.maxQuota;
                imageMsg.value = clonAdvertise.value.imageMsg;
                leaveMsg.value = clonAdvertise.value.leaveMsg;
                selectTime.value = Math.ceil((clonAdvertise.value.expireTime - clonAdvertise.value.createTime) / 3600000);

                const list: any = clonAdvertise.value.paymentMethodId.split(",");
                if (list && list.length > 0) {
                    for (let i = 0; i < arr.length; i++) {
                        const data = arr[i];

                        let exsit = false;
                        for (let j = 0; j < list.length; j++) {
                            if (list[j] == data.type) {
                                exsit = true;
                                break;
                            }
                        }

                        if (!exsit) {
                            data.select = false;
                        } else {
                            data.select = true;
                        }
                    }
                }
            }

            return arr;
        };

        const getMyData = (type: number, list: any) => {
            const arr: any = [];
            for (let i = 0; i < paymentList.value.length; i++) {
                const oriData = paymentList.value[i];
                const data = {
                    type: oriData.type,
                    status: oriData.status,
                    select: oriData.select,
                };

                for (let j = 0; j < list.length; j++) {
                    const item = list[j];
                    if (type == 1) {
                        data.select = true;
                        if (item.type == data.type) {
                            data.status = 1;
                            break;
                        }
                    } else {
                        if (item.type == data.type) {
                            data.select = true;
                            data.status = 1;
                            break;
                        }
                    }
                }
                arr.push(data);
            }

            //是否克隆
            return initCloneData(arr);
        };

        const requestActivePayment = () => {
            if (!user.value.userId) {
                return;
            }
            getActivePaymentList(user.value.userId, (list: any) => {
                //设置可用的支付方式
                if (!list || list.length == 0) {
                    return;
                }
                paymentArrSell.value = getMyData(1, list);
                paymentArrBuy.value = getMyData(0, list);
                paymentList.value = selectType.value == 1 ? paymentArrBuy.value : paymentArrSell.value;
            });
        };

        const selectPaymentItem = (type: number) => {
            for (let i = 0; i < paymentList.value.length; i++) {
                const data = paymentList.value[i];
                if (selectType.value == 0) {
                    if (type == data.type) {
                        data.select = !data.select;
                        break;
                    }
                } else {
                    if (type == data.type && data.status == 0) {
                        ElMessage.error(langObj.value.l0321 + getPayName(data.type));
                        break;
                    } else if (type == data.type) {
                        data.select = !data.select;
                        break;
                    }
                }
            }
        };

        onMounted(() => {
            initDefaultData();
            requestActivePayment();
        });

        const changePublishType = (type: number) => {
            selectType.value = type;

            //置换为最初的数据
            paymentList.value = selectType.value == 1 ? paymentArrBuy.value : paymentArrSell.value;
        };

        const changeTime = (value: number) => {
            if (value == 1) {
                selectTime.value = selectTime.value + 1;
            } else {
                if (selectTime.value == 1) {
                    return;
                } else {
                    selectTime.value = selectTime.value - 1;
                }
            }
        };
        const getPaymentString = () => {
            const arr = [];
            for (let j = 0; j < paymentList.value.length; j++) {
                const item = paymentList.value[j];
                if (item.select == true) {
                    arr.push(item.type);
                }
            }
            return arr.length == 0 ? "" : arr.join(",");
        };
        const ensure = () => {
            const inputReg = /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/;
            if (!inputReg.test(amount.value.toString())) {
                ElMessage.error(langObj.value.l0333);
                return;
            }

            if (parseFloat(amount.value) > balance.value && selectType.value == 1) {
                ElMessage.error(langObj.value.l0332);
                return;
            }

            if (!inputReg.test(unitPrice.value.toString())) {
                ElMessage.error(langObj.value.l0331);
                return;
            }
            if (inputReg.test(maxQuota.value.toString()) == false || parseFloat(maxQuota.value) < 1) {
                ElMessage.error(langObj.value.l0330);
                return;
            }
            if (inputReg.test(minQuota.value.toString()) == false || parseFloat(minQuota.value) < 1) {
                ElMessage.error(langObj.value.l0329);
                return;
            }
            if (minQuota.value > maxQuota.value) {
                ElMessage.error(langObj.value.l0328);
                return;
            }
            if (!inputReg.test(selectTime.value.toString()) || selectTime.value < 1) {
                ElMessage.error(langObj.value.l0327);
                return;
            }
            if (selectTime.value > 168) {
                ElMessage.error(langObj.value.l0326.replace("{X}", 168));
                return;
            }
            if (getPaymentString().length == 0) {
                ElMessage.error(langObj.value.l0325);
                return;
            }
            showEnsureAlert.value = true;
        };

        const requestPublishApi = () => {
            const data = {
                currencyCode: "USDT",
                unitPrice: Number(unitPrice.value).toFixed(2) || "", //单价
                totalAmount: Number(amount.value).toFixed(6) || "", //数量
                minQuota: Number(minQuota.value).toFixed(2) || "", //最小限额
                maxQuota: Number(maxQuota.value).toFixed(2) || "", //最大限额
                expireTime: Date.parse(new Date().toString()) + Math.ceil(selectTime.value) * 60 * 60 * 1000, //
                paymentMethodId: getPaymentString() || "", //支付方式,
                userId: user.value.userId || "", //用户id
                type: selectType.value == 0 ? 1 : 0, // 0 出售 1购买
                leaveMsg: leaveMsg.value || "",
                imageMsg: imageMsg.value || "", 
                legalCurrency: Config.currency.code,
            };

            createAdvertisment(data, (res: any) => {
                if (res && res.code == 52027) {
                    ElMessage.error(langObj.value.l0324);
                } else if (res && res.code == 52040) {
                    if (res.data.transactionThawTime) {
                        const str = "";
                        ElMessage.error(langObj.value.l0323 + str);
                    } else {
                        ElMessage.error(langObj.value.l0322);
                    }
                } else {
                    route.push({ path: "/advertisAdmin" });
                }
            });
        };

        const ensurePublish = () => {
            showEnsureAlert.value = false;
            if (resetOriAd.value && clonAdvertise.value.id) {
                resetAdvertise(clonAdvertise.value.id, () => {
                    requestPublishApi();
                });
            } else {
                requestPublishApi();
            }
        };

        const handleAvatarSuccess = (res: any, file: any) => {
            imageMsg.value = res.data.url;
        };
        const handleAvatarError = (res: any, file: any) => {
            ElMessage.error(langObj.value.l0296);
        };
        const beforeAvatarUpload = (file: any) => {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     ElMessage.error("上传头像图片只能是 JPG 格式!");
            // }
            // if (!isLt2M) {
            //     ElMessage.error("上传头像图片大小不能超过 2MB!");
            // }
            // return isJPG && isLt2M;
            return true;
        };
        const gotoAd = () => {
            route.push({ path: "/advertisAdmin" });
        };
        const clearLeaveMsg = () => {
            leaveMsg.value = "";
            imageMsg.value = "";
        };
        const selectAll = () => {
            amount.value = balance.value.toString();
        };
        const selectMax = () => {
            selectTime.value = 168;
        };
        const resetOriAdAction = () => {
            resetOriAd.value = !resetOriAd.value;
        };

        const inputTime = (value: any) => {
            selectTime.value = Math.ceil(value);
        };

        return {
            langObj,
            Config,
            selectType,
            showEnsureAlert,
            showLeaveMsg,
            paymentList,
            clonAdvertise,
            fileHost,
            imgHeader,
            resetOriAd,
            selectPaymentItem,
            amount,
            selectTime,
            inputTime,
            unitPrice,
            maxQuota,
            minQuota,
            ensurePublish,
            ensure,
            balance,
            changeTime,
            handleAvatarSuccess,
            handleAvatarError,
            beforeAvatarUpload,
            changePublishType,
            leaveMsg,
            imageMsg,
            gotoAd,
            selectAll,
            selectMax,
            resetOriAdAction,
            clearLeaveMsg,
            getPaymentString,
            getImgUrl: () => {
                const url = Config.project.fileHost + imageMsg.value;
                return url;
            },
            getPayIcon,
            paymentName: (type: number) => {
                if (type == undefined) {
                    return "";
                }
                return getPayName(type);
            },
        };
    },
});

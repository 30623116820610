
import userAssets from "../my/UserAssetItem.vue";
import { defineComponent, computed } from "vue";
import Lang from "@/assets/lang";
export default defineComponent({
    name: "User",
    components: { userAssets },
    setup() {
        const langObj = computed(() => Lang.langObj);
        return {
            langObj,
        };
    },
});

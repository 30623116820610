
import { defineComponent, ref, computed } from "vue";
import { getType, getMobile, postLogin, getSendVoiceSms } from "@/assets/http";
import mobileArr from "@/assets/ts/mobileArr";
import mobileItem from '@/assets/vo/mobileVo'
import { ElMessage } from "element-plus";
import Utils from "@/assets/ts/utils";
import Config from "@/assets/ts/config";
import Lang from "@/assets/lang";
export default defineComponent({
    setup() {
        const mobileArrs = ref<mobileItem[]>(mobileArr);
        const langI = computed(() => Lang.langI);
        const langObj = computed(() => Lang.langObj);
        const dialogVisible = ref(false);
        const dialogNum = ref(0);
        const langV = ref(Lang.langI);
        const dialogPhone = ref("");
        const account = ref("");
        const password = ref("");
        const isLogin = ref(false);
        const isPass = ref(false);
        const passOk = ref(false);
        const phCode = ref<mobileItem>(mobileArr.find((item: mobileItem) => item.code == Config.currency.areaCode)!);
        const phCodeText = ref("");
        const isAreaCode = ref(false);
        const codeNum = ref(0);
        const onGetType = () => {
            if (!Utils.isPhone(account.value, phCode.value.pattern)) {
                ElMessage.error(langObj.value.l0336);
                return;
            }
            isAreaCode.value = phCode.value.code == Config.currency.areaCode;
            const phone = phCode.value.code + "_" + account.value;
            getType(phone, (data: any) => {
                isLogin.value = true;
                isPass.value = data.type == 1; //-1 注册 0 已注册，未设置登录密码 1 已注册，已设置登录密码
                passOk.value = data.type == 1;
            });
        };
        const onDialogPhone = () => {
            if (!Utils.isPhone(account.value, phCode.value.pattern)) {
                ElMessage.error(langObj.value.l0336);
                return;
            }
            dialogPhone.value = "+" + phCode.value.code + " " + account.value;
            dialogVisible.value = true;
        };
        const sendVoiceSms = () => {
            if (dialogNum.value > 0) return;
            dialogNum.value = 60;
            const _set = setInterval(() => {
                dialogNum.value--;
                if (dialogNum.value <= 0) {
                    clearInterval(_set);
                }
            }, 1000);
            const phone = phCode.value.code + "_" + account.value;
            getSendVoiceSms(phone);
        };
        const onLoginSwitch = () => {
            if (passOk.value) {
                isPass.value = !isPass.value;
            }
        };
        const onGetCode = () => {
            if (codeNum.value > 0) return;
            codeNum.value = 60;
            const _set = setInterval(() => {
                codeNum.value--;
                if (codeNum.value <= 0) {
                    clearInterval(_set);
                    codeNum.value = 0;
                }
            }, 1000);
            const phone = phCode.value.code + "_" + account.value;
            getMobile(phone);
        };
        const onLogin = () => {
            if (!Utils.isPhone(account.value, phCode.value.pattern)) {
                ElMessage.error(langObj.value.l0336);
                return;
            }
            const _phone = phCode.value.code + "_" + account.value;
            interface dataRes {
                phone: string,
                password?: string,
                smsCode?: string,
                type?: number
            };
            const _data: dataRes = { phone: _phone };
            if (isPass.value) {
                if (!password.value) {
                    ElMessage.error(langObj.value.l0335);
                    return;
                }
                _data.password = password.value;
                _data.type = 1;
            } else {
                if (!phCodeText.value) {
                    ElMessage.error(langObj.value.l0337);
                    return;
                }
                _data.smsCode = phCodeText.value;
                _data.type = 0;
            }
            password.value = "";
            phCodeText.value = "";
            postLogin(_data);
        };
        return {
            mobileArrs,
            langObj,
            langI,
            Config,
            dialogVisible,
            dialogNum,
            langV,
            dialogPhone,
            sendVoiceSms,
            onDialogPhone,
            account,
            password,
            isLogin,
            onGetType,
            isPass,
            passOk,
            phCode,
            onLogin,
            onLoginSwitch,
            codeNum,
            isAreaCode,
            onGetCode,
            phCodeText,
        };
    },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f2a0d56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imgBac" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arr, (item) => {
      return (_openBlock(), _createElementBlock("div", { key: item }, [
        _createElementVNode("img", {
          src: _ctx.getPayIcon(item)
        }, null, 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}

import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { computed, defineComponent, onMounted, ref } from "vue";
import { checkPayPassword, getUserInfo, getMobile, updateUserInfo, checkSmsCode } from "@/assets/http";
import Config from "@/assets/ts/config";
import Lang from "@/assets/lang";
export default defineComponent({
    setup() {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const user = ref(computed(() => $store.state.userInfo));
        const showSetLoginPwdAlert = ref(false);
        const showSetPayPwdAlert = ref(false);
        const showLoginPwdAlert = ref(false);
        const showPayPwdAlert = ref(false);
        const showForgetPwdAlert = ref(false);
        const inputOriPwd = ref("");
        const inputNewPwd = ref("");
        const inputNewPwdC = ref("");
        const loginPwdReg = /^[0-9a-zA-Z]{6,12}$/;
        const payPwdReg = /^[0-9]{6}$/;
        const forgetStep = ref(0);
        const sendTitle = ref(langObj.value.l0245);
        const inputCode = ref("");
        const sendNum = ref(60);
        const alertType = ref(0); //忘记密码类型0登录，1支付

        onMounted(() => {
            console.log(user.value);
        });

        const clearInput = () => {
            inputNewPwd.value = "";
            inputNewPwdC.value = "";
            inputOriPwd.value = "";
        };
        const setLoginPwd = () => {
            if (loginPwdReg.test(inputNewPwd.value) == false) {
                ElMessage.error(langObj.value.l0219);
                return;
            }

            if (inputNewPwd.value != inputNewPwdC.value) {
                ElMessage.error(langObj.value.l0215);
                return;
            }

            const data1 = {
                password: inputNewPwd.value,
                userId: user.value.userId,
            };
            updateUserInfo(data1, (res1: any) => {
                console.log(res1);
                ElMessage.success(langObj.value.l0218);
                showSetLoginPwdAlert.value = false;

                getUserInfo();

                clearInput();
            });
        };
        const updateLoginPwd = () => {
            if (loginPwdReg.test(inputOriPwd.value) == false) {
                ElMessage.error(langObj.value.l0213);
                return;
            }

            if (loginPwdReg.test(inputNewPwd.value) == false) {
                ElMessage.error(langObj.value.l0209);
                return;
            }

            if (inputNewPwd.value != inputNewPwdC.value) {
                ElMessage.error(langObj.value.l0208);
                return;
            }

            const data = {
                oldLoginPwd: inputOriPwd.value,
                password: inputNewPwd.value,
                userId: user.value.userId,
                phone: user.value.phone,
            };
            updateUserInfo(data, () => {
                ElMessage.success(langObj.value.l0217);
                showLoginPwdAlert.value = false;
                clearInput();
            });
        };

        const setPayPwd = () => {
            if (payPwdReg.test(inputNewPwd.value) == false) {
                ElMessage.error(langObj.value.l0216);
                return;
            }

            if (inputNewPwd.value != inputNewPwdC.value) {
                ElMessage.error(langObj.value.l0215);
                return;
            }

            const data1 = {
                payPassword: inputNewPwd.value,
                userId: user.value.userId,
            };
            updateUserInfo(data1, (res1: any) => {
                console.log(res1);
                ElMessage.success(langObj.value.l0214);
                showSetPayPwdAlert.value = false;

                getUserInfo();

                clearInput();
            });
        };

        const updatePayPwd = () => {
            if (payPwdReg.test(inputOriPwd.value) == false) {
                ElMessage.error(langObj.value.l0213);
                return;
            }
            if (payPwdReg.test(inputNewPwd.value) == false) {
                ElMessage.error(langObj.value.l0211);
                return;
            }

            if (inputNewPwd.value != inputNewPwdC.value) {
                ElMessage.error(langObj.value.l0208);
                return;
            }

            const data = {
                payPassWord: inputOriPwd.value,
                userId: user.value.userId,
            };
            checkPayPassword(data, () => {
                const data1 = {
                    payPassword: inputNewPwd.value,
                    phone: user.value.phone,
                    userId: user.value.userId,
                };
                updateUserInfo(data1, (res1: any) => {
                    console.log(res1);
                    ElMessage.success(langObj.value.l0210);
                    showPayPwdAlert.value = false;

                    clearInput();
                });
            });
        };

        const closeSetLoginPwd = () => {
            showSetLoginPwdAlert.value = false;
            clearInput();
        };
        const closeUpdateLoginPwd = () => {
            showLoginPwdAlert.value = false;
            clearInput();
        };
        const closeSetPayPwd = () => {
            showSetPayPwdAlert.value = false;
            clearInput();
        };
        const closeUpdatePayPwd = () => {
            showPayPwdAlert.value = false;
            clearInput();
        };

        const contactService = () => {
            window.open(Config.project.server);
        };

        const forgetPwd = (index: number) => {
            showForgetPwdAlert.value = true;
            alertType.value = index;
            showLoginPwdAlert.value = false;
            showPayPwdAlert.value = false;
        };

        const sendCode = () => {
            if (sendNum.value < 60) return;
            getMobile(user.value.phone);
            sendNum.value = 60;
            const _set = setInterval(() => {
                sendNum.value--;
                sendTitle.value = sendNum.value + "s";
                if (sendNum.value <= 0) {
                    clearInterval(_set);
                    sendNum.value = 60;
                    sendTitle.value = langObj.value.l0245;
                    inputCode.value = "";
                }
            }, 1000);
        };
        // 忘记密码下一步
        const nextAction = () => {
            if (inputCode.value.length != 6) {
                ElMessage.error(langObj.value.l0212);
                return;
            }
            //校验验证码
            checkSmsCode(user.value.phone, inputCode.value, (res: any) => {
                if (!res) {
                    forgetStep.value = 1;
                }
            });
        };

        const forgetResetPwd = () => {
            if (alertType.value == 1) {
                if (payPwdReg.test(inputNewPwd.value) == false) {
                    ElMessage.error(langObj.value.l0211);
                    return;
                }

                if (inputNewPwd.value != inputNewPwdC.value) {
                    ElMessage.error(langObj.value.l0208);
                    return;
                }
                const data = {
                    phone: user.value.phone,
                    userId: user.value.userId,
                    payPassWord: inputNewPwdC.value,
                };
                updateUserInfo(data, (res1: any) => {
                    console.log(res1);
                    ElMessage.success(langObj.value.l0210);
                    showForgetPwdAlert.value = false;
                    forgetStep.value = 0;
                    inputCode.value = "";

                    clearInput();
                });
            } else {
                if (loginPwdReg.test(inputNewPwd.value) == false) {
                    ElMessage.error(langObj.value.l0209);
                    return;
                }

                if (inputNewPwd.value != inputNewPwdC.value) {
                    ElMessage.error(langObj.value.l0208);
                    return;
                }

                const data = {
                    phone: user.value.phone,
                    userId: user.value.userId,
                    password: inputNewPwdC.value,
                };
                updateUserInfo(data, (res1: any) => {
                    console.log(res1);
                    ElMessage.success(langObj.value.l0207);
                    showForgetPwdAlert.value = false;
                    forgetStep.value = 0;
                    inputCode.value = "";
                    clearInput();
                });
            }
        };

        return {
            langObj,
            user,
            setLoginPwd,
            showSetLoginPwdAlert,
            showSetPayPwdAlert,
            showLoginPwdAlert,
            showPayPwdAlert,
            showForgetPwdAlert,
            inputOriPwd,
            inputNewPwd,
            inputNewPwdC,
            setPayPwd,
            alertType,
            updateLoginPwd,
            updatePayPwd,
            contactService,
            closeSetLoginPwd,
            closeUpdateLoginPwd,
            closeSetPayPwd,
            closeUpdatePayPwd,
            forgetPwd,
            nextAction,
            sendCode,
            sendTitle,
            inputCode,
            forgetStep,
            forgetResetPwd,
        };
    },
});

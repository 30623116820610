import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "itemBox" }
const _hoisted_2 = { class: "flexBothEnds" }
const _hoisted_3 = { class: "avatar" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "flexBothEnds marT5" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "fontBright" }
const _hoisted_10 = { class: "flexBothEnds marT5" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.goods.userName.substring(0, 1)), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.goods.userName), 1)
      ]),
      _createElementVNode("div", _hoisted_5, "( " + _toDisplayString(_ctx.goods.successByMonth + "丨" + (_ctx.goods.successRatio * 100).toFixed(0)) + "% )", 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.langObj.l0033) + "：", 1),
      _createTextVNode(" " + _toDisplayString(Number(_ctx.goods.amount).toFixed(6)) + "USDT ", 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.langObj.l0008) + "：", 1),
        _createTextVNode(" " + _toDisplayString(_ctx.Config.currency.symbol + Number(_ctx.goods.minQuota).toFixed(2) + "~" + _ctx.Config.currency.symbol + Number(_ctx.goods.maxQuota).toFixed(2)), 1)
      ]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.Config.currency.symbol + _ctx.goods.unitPrice.toFixed(2)), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.langObj.l0034) + "：", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getIconArr(_ctx.goods.paymentMethodId), (item) => {
          return (_openBlock(), _createElementBlock("img", {
            class: "icon",
            key: item,
            src: item
          }, null, 8, _hoisted_12))
        }), 128))
      ]),
      _createVNode(_component_el_button, {
        type: "primary",
        size: "mini",
        plain: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.titleArr[_ctx.goods.type]), 1)
        ]),
        _: 1
      })
    ])
  ]))
}

import { postFreeTradeBuy } from "@/assets/http";
import AdvertVo from "@/assets/vo/AdvertVo";

import Config from "@/assets/ts/config";
import Utils from "@/assets/ts/utils";
import { ElMessage } from "element-plus";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import Lang from "@/assets/lang";
export default defineComponent({
    name: "PlaceOrder",
    emits: ["onHide", "showOrderDetail"],
    setup(prop, context) {
        const langObj = computed(() => Lang.langObj);
        const tabArr = computed(() => [langObj.value.l0021, langObj.value.l0022]);
        const tabI = ref(0);
        const waresContent = ref("");
        const isShow = ref(false);
        const onShow = (_isShow: boolean) => {
            isShow.value = _isShow;
            !_isShow && context.emit("onHide");
            waresContent.value = "";
        };
        const onTab = (index: number) => {
            tabI.value = index;
            waresContent.value = "";
        };
        const $store = useStore();
        const wares = ref(new AdvertVo());
        const timeCancl = ref(0);
        let _timeSet: NodeJS.Timeout | undefined;
        const onOpen = (_data: AdvertVo) => {
            Utils.updateObj(wares.value, _data);
            onShow(true);
            timeCancl.value = 45;
            _timeSet && clearInterval(_timeSet);
            _timeSet = setInterval(() => {
                timeCancl.value--;
                if (timeCancl.value <= 0) {
                    _timeSet && clearInterval(_timeSet);
                    onShow(false);
                }
            }, 1000);
        };
        const waresGold = computed(() => {
            let _num;
            if (!waresContent.value) _num = 0;
            else if (tabI.value == 0) _num = waresContent.value;
            else if (tabI.value == 1) _num = Utils.multiplication(waresContent.value, wares.value.unitPrice);
            return Utils.numberStr(_num, 2, true);
        });
        const waresCount = computed(() => {
            let _num;
            if (!waresContent.value) _num = 0;
            else if (tabI.value == 0) _num = Utils.division(waresContent.value, wares.value.unitPrice);
            else if (tabI.value == 1) _num = waresContent.value;
            const maxNum = Math.min(Number(Utils.numberStr(_num, 6)), wares.value.amount);
            if (wares.value.type == 1) return Math.min($store.state.balance, maxNum);
            return maxNum;
        });
        let _waresContent = "";
        const onContent = () => {
            if (!Utils.inputNum(waresContent.value, tabI.value === 0 ? 2 : 6)) {
                waresContent.value = _waresContent;
                return;
            }
            const _number1 = Utils.numberStr(Utils.division(wares.value.maxQuota, wares.value.unitPrice), 6);
            const _maxQuota = Number(Utils.numberStr(Utils.multiplication(_number1, wares.value.unitPrice), 2, true));
            let _maxNum = Math.min(Number(Utils.numberStr(Utils.multiplication(wares.value.amount, wares.value.unitPrice), 2, true)), _maxQuota);
            if (wares.value.type == 1) _maxNum = Math.min(Utils.multiplication($store.state.balance, wares.value.unitPrice), _maxNum);
            if (tabI.value == 0) {
                //金额购买
                if (Number(waresContent.value) > _maxNum) waresContent.value = Utils.numberStr(_maxNum, 2, true, true);
                else 
                waresContent.value = Utils.numberStr(waresContent.value, 2, true, true);
            } else {
                if (Number(waresContent.value) > Utils.division(_maxNum, wares.value.unitPrice))
                    waresContent.value = Utils.numberStr(Utils.division(_maxNum, wares.value.unitPrice), 6, false, true);
                else 
                waresContent.value = Utils.numberStr(waresContent.value, 6, false, true);
            }
            _waresContent = waresContent.value;
        };
        const allSelect = () => {
            if (wares.value.type == 1 && $store.state.balance == 0) {
                ElMessage.error(langObj.value.l0014);
                return;
            }
            let _num = "";
            if (tabI.value == 0) _num = wares.value.maxQuota + "";
            else _num = wares.value.maxQuota + "";
            waresContent.value = _num;
            onContent();
        };
        const placeholderText = computed(() => [langObj.value.l0015, langObj.value.l0016][tabI.value]);
        return {
            langObj,
            Config,
            tabArr,
            tabI,
            onTab,
            onShow,
            waresContent,
            isShow,
            onOpen,
            wares,
            timeCancl,
            getTitle: () => [langObj.value.l0017, langObj.value.l0018][wares.value.type],
            placeholderText,
            waresGold,
            waresCount,
            allSelect,
            onContent,
            onSubmit: () => {
                if (waresCount.value == 0) {
                    ElMessage.error(langObj.value.l0019);
                    return;
                }
                if (!waresCount.value) {
                    ElMessage.error(placeholderText.value);
                    return;
                }
                if (Number(waresGold.value) < wares.value.minQuota) {
                    ElMessage.error(langObj.value.l0020);
                    return;
                }
                if (Number(waresGold.value) > wares.value.maxQuota) {
                    ElMessage.error(langObj.value.l0438);
                    return;
                }
                const _obj = {
                    advertisId: wares.value.id,
                    amount: waresCount.value,
                    totalCny: waresGold.value,
                };
                postFreeTradeBuy(_obj, function (data: any) {
                    isShow.value = false;
                    context.emit("showOrderDetail", data);
                });
            },
        };
    },
});

import mobileItem from '../vo/mobileVo' 
 const mobileArr:mobileItem[] =  [
    // {
    //     pattern: "^(86){0,1}1\\d{10}$",
    //     code: 86,
    //     desp: "CN",
    //     text: "中国大陆(+86)",
    //     value: 1,
    //     entext: "Mainland China(+86)",
    // },
    {
        pattern: "^(00){0,1}(971){0,1}\\d{6,12}$",
        code: 971,
        desp: "AE",
        text: "阿联酋(+971)",
        value: 25,
        entext: "United Arab Emirates(+971)",
    },
    {
        pattern: "^(00){0,1}(853){0,1}6\\d{7}$",
        code: 853,
        desp: "MO",
        text: "中国澳门(+853)",
        value: 3,
        entext: "Macao(China)(+853)",
    },
    {
        pattern: "^(00){0,1}(886){0,1}0{0,1}[6,7,9](?:\\d{7}|\\d{8}|\\d{10})$",
        code: 886,
        desp: "TW",
        text: "中国台湾地区(+886)",
        value: 4,
        entext: "Taiwan(China)(+886)",
    },
    {
        pattern: "^(00){0,1}(852){0,1}0{0,1}[1,5,6,9](?:\\d{7}|\\d{8}|\\d{12})$",
        code: 852,
        desp: "HK",
        text: "中国香港(+852)",
        value: 2,
        entext: "Hong Kong(China)(+852)",
    },
    {
        pattern: "^(00){0,1}(355){0,1}\\d{6,15}$",
        code: 355,
        desp: "AL",
        text: "阿尔巴尼亚(+355)",
        value: 75,
        entext: "Albania(+355)",
    },
    {
        pattern: "^(00){0,1}(54){0,1}\\d{6,12}$",
        code: 54,
        desp: "AR",
        text: "阿根廷(+54)",
        value: 26,
        entext: "Argentina(+54)",
    },
    {
        pattern: "^(00){0,1}(968){0,1}\\d{6,15}$",
        code: 968,
        desp: "OM",
        text: "阿曼(+968)",
        value: 143,
        entext: "Oman(+968)",
    },
    {
        pattern: "^(00){0,1}(994){0,1}\\d{6,15}$",
        code: 994,
        desp: "AZ",
        text: "阿塞拜疆(+994)",
        value: 78,
        entext: "Azerbaijan(+994)",
    },
    {
        pattern: "^(00){0,1}(20){0,1}\\d{6,12}$",
        code: 20,
        desp: "EG",
        text: "埃及(+20)",
        value: 39,
        entext: "Egypt(+20)",
    },
    {
        pattern: "^(00){0,1}(353){0,1}\\d{6,12}$",
        code: 353,
        desp: "IE",
        text: "爱尔兰(+353)",
        value: 44,
        entext: "Ireland(+353)",
    },
    {
        pattern: "^(00){0,1}(372){0,1}\\d{6,12}$",
        code: 372,
        desp: "EE",
        text: "爱沙尼亚(+372)",
        value: 38,
        entext: "Estonia(+372)",
    },
    {
        pattern: "^(00){0,1}(244){0,1}\\d{6,15}$",
        code: 244,
        desp: "AO",
        text: "安哥拉(+244)",
        value: 77,
        entext: "Angola(+244)",
    },
    {
        pattern: "^(00){0,1}(43){0,1}\\d{6,12}$",
        code: 43,
        desp: "AT",
        text: "奥地利(+43)",
        value: 27,
        entext: "Austria(+43)",
    },
    {
        pattern: "^(00){0,1}(61){0,1}4\\d{8,9}$",
        code: 61,
        desp: "AU",
        text: "澳大利亚(+61)",
        value: 10,
        entext: "Australia(+61)",
    },
    {
        pattern: "^(00){0,1}(675){0,1}\\d{6,15}$",
        code: 675,
        desp: "PG",
        text: "巴布亚新几内亚(+675)",
        value: 144,
        entext: "Papua New Guinea(+675)",
    },
    {
        pattern: "^(00){0,1}(1242){0,1}\\d{6,12}$",
        code: 1242,
        desp: "BS",
        text: "巴哈马(+1242)",
        value: 31,
        entext: "Bahamas(+1242)",
    },
    {
        pattern: "^(00){0,1}(970){0,1}\\d{6,15}$",
        code: 970,
        desp: "PS",
        text: "巴勒斯坦(+970)",
        value: 147,
        entext: "Palestine(+970)",
    },
    {
        pattern: "^(00){0,1}(973){0,1}\\d{6,15}$",
        code: 973,
        desp: "BH",
        text: "巴林(+973)",
        value: 82,
        entext: "Bahrain(+973)",
    },
    {
        pattern: "^(00){0,1}(507){0,1}\\d{6,12}$",
        code: 507,
        desp: "PA",
        text: "巴拿马(+507)",
        value: 59,
        entext: "Panama(+507)",
    },
    {
        pattern: "^(00){0,1}(55){0,1}\\d{6,12}$",
        code: 55,
        desp: "BR",
        text: "巴西(+55)",
        value: 30,
        entext: "Brazil(+55)",
    },
    {
        pattern: "^(00){0,1}(375){0,1}\\d{6,12}$",
        code: 375,
        desp: "BY",
        text: "白俄罗斯(+375)",
        value: 32,
        entext: "Belarus(+375)",
    },
    {
        pattern: "^(00){0,1}(359){0,1}\\d{6,12}$",
        code: 359,
        desp: "BG",
        text: "保加利亚(+359)",
        value: 29,
        entext: "Bulgaria(+359)",
    },
    {
        pattern: "^(00){0,1}(229){0,1}\\d{6,15}$",
        code: 229,
        desp: "BJ",
        text: "贝宁(+229)",
        value: 84,
        entext: "Benin(+229)",
    },
    {
        pattern: "^(00){0,1}(32){0,1}\\d{6,12}$",
        code: 32,
        desp: "BE",
        text: "比利时(+32)",
        value: 28,
        entext: "Belgium(+32)",
    },
    {
        pattern: "^(00){0,1}(48){0,1}\\d{6,12}$",
        code: 48,
        desp: "PL",
        text: "波兰(+48)",
        value: 61,
        entext: "Poland(+48)",
    },
    {
        pattern: "^(00){0,1}(591){0,1}\\d{6,15}$",
        code: 591,
        desp: "BO",
        text: "玻利维亚(+591)",
        value: 86,
        entext: "Bolivia(+591)",
    },
    {
        pattern: "^(00){0,1}(501){0,1}\\d{6,12}$",
        code: 501,
        desp: "BZ",
        text: "伯利兹(+501)",
        value: 33,
        entext: "Belize(+501)",
    },
    {
        pattern: "^(00){0,1}(226){0,1}\\d{6,15}$",
        code: 226,
        desp: "BF",
        text: "布基纳法索(+226)",
        value: 81,
        entext: "Burkina Faso(+226)",
    },
    {
        pattern: "^(00){0,1}(240){0,1}\\d{6,15}$",
        code: 240,
        desp: "GQ",
        text: "赤道几内亚(+240)",
        value: 109,
        entext: "Equatorial Guinea(+240)",
    },
    {
        pattern: "^(00){0,1}(45){0,1}\\d{6,12}$",
        code: 45,
        desp: "DK",
        text: "丹麦(+45)",
        value: 37,
        entext: "Denmark(+45)",
    },
    {
        pattern: "^(00){0,1}(49){0,1}1(\\d{5,6}|\\d{9,12})$",
        code: 49,
        desp: "DE",
        text: "德国(+49)",
        value: 17,
        entext: "Germany(+49)",
    },
    {
        pattern: "^(00){0,1}(228){0,1}\\d{6,15}$",
        code: 228,
        desp: "TG",
        text: "多哥(+228)",
        value: 161,
        entext: "Togo(+228)",
    },
    {
        pattern: "^(00){0,1}(7){0,1}[13489]\\d{9,11}$",
        code: 7,
        desp: "RU",
        text: "俄罗斯(+7)",
        value: 20,
        entext: "Russia(+7)",
    },
    {
        pattern: "^(00){0,1}(33){0,1}(\\d{6}|\\d{8,9})$",
        code: 33,
        desp: "FR",
        text: "法国(+33)",
        value: 19,
        entext: "France(+33)",
    },
    {
        pattern: "^(00){0,1}(63){0,1}[24579](\\d{7,9}|\\d{12})$",
        code: 63,
        desp: "PH",
        text: "菲律宾(+63)",
        value: 15,
        entext: "Philippines(+63)",
    },
    {
        pattern: "^(00){0,1}(358){0,1}\\d{6,12}$",
        code: 358,
        desp: "FI",
        text: "芬兰(+358)",
        value: 41,
        entext: "Finland(+358)",
    },
    {
        pattern: "^(00){0,1}(238){0,1}\\d{6,15}$",
        code: 238,
        desp: "CV",
        text: "佛得角(+238)",
        value: 95,
        entext: "Cape Verde(+238)",
    },
    {
        pattern: "^(00){0,1}(220){0,1}\\d{6,15}$",
        code: 220,
        desp: "GM",
        text: "冈比亚(+220)",
        value: 107,
        entext: "Gambia(+220)",
    },
    {
        pattern: "^(00){0,1}(57){0,1}\\d{6,12}$",
        code: 57,
        desp: "CO",
        text: "哥伦比亚(+57)",
        value: 36,
        entext: "Colombia(+57)",
    },
    {
        pattern: "^(00){0,1}(506){0,1}\\d{6,15}$",
        code: 506,
        desp: "CR",
        text: "哥斯达黎加(+506)",
        value: 93,
        entext: "Costa Rica(+506)",
    },
    {
        pattern: "^(00){0,1}(1473){0,1}\\d{6,15}$",
        code: 1473,
        desp: "GD",
        text: "格林纳达(+1473)",
        value: 104,
        entext: "Grenada(+1473)",
    },
    {
        pattern: "^(00){0,1}(995){0,1}\\d{6,15}$",
        code: 995,
        desp: "GE",
        text: "格鲁吉亚(+995)",
        value: 105,
        entext: "Georgia(+995)",
    },
    {
        pattern: "^(00){0,1}(592){0,1}\\d{6,15}$",
        code: 592,
        desp: "GY",
        text: "圭亚那(+592)",
        value: 112,
        entext: "Guyana(+592)",
    },
    {
        pattern: "^(00){0,1}(82){0,1}0{0,1}[7,1](?:\\d{8}|\\d{9})$",
        code: 82,
        desp: "KR",
        text: "韩国(+82)",
        value: 5,
        entext: "Korea(+82)",
    },
    {
        pattern: "^(00){0,1}(31){0,1}6\\d{8}$",
        code: 31,
        desp: "NL",
        text: "荷兰(+31)",
        value: 22,
        entext: "Holland(+31)",
    },
    {
        pattern: "^(00){0,1}(504){0,1}\\d{6,15}$",
        code: 504,
        desp: "HN",
        text: "洪都拉斯(+504)",
        value: 113,
        entext: "Honduras(+504)",
    },
    {
        pattern: "^(00){0,1}(253){0,1}\\d{6,15}$",
        code: 253,
        desp: "DJ",
        text: "吉布提(+253)",
        value: 99,
        entext: "Djibouti(+253)",
    },
    {
        pattern: "^(00){0,1}(996){0,1}\\d{6,12}$",
        code: 996,
        desp: "KG",
        text: "吉尔吉斯斯坦(+996)",
        value: 48,
        entext: "Kyrgyzstan(+996)",
    },
    {
        pattern: "^(00){0,1}(224){0,1}\\d{6,15}$",
        code: 224,
        desp: "GN",
        text: "几内亚(+224)",
        value: 108,
        entext: "Guinea(+224)",
    },
    {
        pattern: "^(00){0,1}(245){0,1}\\d{6,15}$",
        code: 245,
        desp: "GW",
        text: "几内亚比绍(+245)",
        value: 111,
        entext: "Guinea-Bissau(+245)",
    },
    {
        pattern: "^(00){0,1}(1){0,1}\\d{10}$",
        code: 1,
        desp: "CA",
        text: "加拿大(+1)",
        value: 8,
        entext: "Canada(+1)",
    },
    {
        pattern: "^(00){0,1}(241){0,1}\\d{6,15}$",
        code: 241,
        desp: "GA",
        text: "加蓬(+241)",
        value: 103,
        entext: "Gabon(+241)",
    },
    {
        pattern: "^(00){0,1}(855){0,1}\\d{6,12}$",
        code: 855,
        desp: "KH",
        text: "柬埔寨(+855)",
        value: 49,
        entext: "Cambodia(+855)",
    },
    {
        pattern: "^(00){0,1}(263){0,1}\\d{6,15}$",
        code: 263,
        desp: "ZW",
        text: "津巴布韦(+263)",
        value: 173,
        entext: "Zimbabwe(+263)",
    },
    {
        pattern: "^(00){0,1}(237){0,1}\\d{6,15}$",
        code: 237,
        desp: "CM",
        text: "喀麦隆(+237)",
        value: 92,
        entext: "Cameroon(+237)",
    },
    {
        pattern: "^(00){0,1}(974){0,1}\\d{6,12}$",
        code: 974,
        desp: "QA",
        text: "卡塔尔(+974)",
        value: 63,
        entext: "Qatar(+974)",
    },
    {
        pattern: "^(00){0,1}(1345){0,1}\\d{6,15}$",
        code: 1345,
        desp: "KY",
        text: "开曼群岛(+1345)",
        value: 121,
        entext: "Cayman Islands(+1345)",
    },
    {
        pattern: "^(00){0,1}(269){0,1}\\d{6,15}$",
        code: 269,
        desp: "KM",
        text: "科摩罗(+269)",
        value: 119,
        entext: "Comoros(+269)",
    },
    {
        pattern: "^(00){0,1}(965){0,1}\\d{6,15}$",
        code: 965,
        desp: "KW",
        text: "科威特(+965)",
        value: 120,
        entext: "Kuwait(+965)",
    },
    {
        pattern: "^(00){0,1}(385){0,1}\\d{6,15}$",
        code: 385,
        desp: "HR",
        text: "克罗地亚(+385)",
        value: 114,
        entext: "Croatia(+385)",
    },
    {
        pattern: "^(00){0,1}(254){0,1}\\d{6,15}$",
        code: 254,
        desp: "KE",
        text: "肯尼亚(+254)",
        value: 118,
        entext: "Kenya(+254)",
    },
    {
        pattern: "^(00){0,1}(371){0,1}\\d{6,15}$",
        code: 371,
        desp: "LV",
        text: "拉脱维亚(+371)",
        value: 127,
        entext: "Latvia(+371)",
    },
    {
        pattern: "^(00){0,1}(266){0,1}\\d{6,15}$",
        code: 266,
        desp: "LS",
        text: "莱索托(+266)",
        value: 126,
        entext: "Lesotho(+266)",
    },
    {
        pattern: "^(00){0,1}(370){0,1}\\d{6,12}$",
        code: 370,
        desp: "LT",
        text: "立陶宛(+370)",
        value: 51,
        entext: "Lithuania(+370)",
    },
    {
        pattern: "^(00){0,1}(352){0,1}\\d{6,12}$",
        code: 352,
        desp: "LU",
        text: "卢森堡(+352)",
        value: 52,
        entext: "Luxembourg(+352)",
    },
    {
        pattern: "^(00){0,1}(250){0,1}\\d{6,15}$",
        code: 250,
        desp: "RW",
        text: "卢旺达(+250)",
        value: 148,
        entext: "Rwanda(+250)",
    },
    {
        pattern: "^(00){0,1}(40){0,1}\\d{6,12}$",
        code: 40,
        desp: "RO",
        text: "罗马尼亚(+40)",
        value: 64,
        entext: "Romania(+40)",
    },
    {
        pattern: "^(00){0,1}(261){0,1}\\d{6,15}$",
        code: 261,
        desp: "MG",
        text: "马达加斯加(+261)",
        value: 132,
        entext: "Madagascar(+261)",
    },
    {
        pattern: "^(00){0,1}(960){0,1}\\d{6,12}$",
        code: 960,
        desp: "MV",
        text: "马尔代夫(+960)",
        value: 55,
        entext: "Maldives(+960)",
    },
    {
        pattern: "^(00){0,1}(265){0,1}\\d{6,15}$",
        code: 265,
        desp: "MW",
        text: "马拉维(+265)",
        value: 138,
        entext: "Malawi(+265)",
    },
    {
        pattern: "^(00){0,1}(60){0,1}1\\d{8,9}$",
        code: 60,
        desp: "MY",
        text: "马来西亚(+60)",
        value: 12,
        entext: "Malaysia(+60)",
    },
    {
        pattern: "^(00){0,1}(223){0,1}\\d{6,15}$",
        code: 223,
        desp: "ML",
        text: "马里(+223)",
        value: 134,
        entext: "Mali(+223)",
    },
    {
        pattern: "^(00){0,1}(230){0,1}\\d{6,15}$",
        code: 230,
        desp: "MU",
        text: "毛里求斯(+230)",
        value: 137,
        entext: "Mauritius(+230)",
    },
    {
        pattern: "^(00){0,1}(222){0,1}\\d{6,15}$",
        code: 222,
        desp: "MR",
        text: "毛里塔尼亚(+222)",
        value: 136,
        entext: "Mauritania(+222)",
    },
    {
        pattern: "^(00){0,1}(1){0,1}\\d{10,12}$",
        code: 1,
        desp: "US",
        text: "美国(+1)",
        value: 7,
        entext: "United States of America(+1)",
    },
    {
        pattern: "^(00){0,1}(976){0,1}\\d{6,12}$",
        code: 976,
        desp: "MN",
        text: "蒙古(+976)",
        value: 54,
        entext: "Mongolia(+976)",
    },
    {
        pattern: "^(00){0,1}(51){0,1}\\d{6,12}$",
        code: 51,
        desp: "PE",
        text: "秘鲁(+51)",
        value: 60,
        entext: "Peru(+51)",
    },
    {
        pattern: "^(00){0,1}(373){0,1}\\d{6,15}$",
        code: 373,
        desp: "MD",
        text: "摩尔多瓦(+373)",
        value: 129,
        entext: "Moldova(+373)",
    },
    {
        pattern: "^(00){0,1}(212){0,1}\\d{6,12}$",
        code: 212,
        desp: "MA",
        text: "摩洛哥(+212)",
        value: 53,
        entext: "Morocco(+212)",
    },
    {
        pattern: "^(00){0,1}(258){0,1}\\d{6,15}$",
        code: 258,
        desp: "MZ",
        text: "莫桑比克(+258)",
        value: 139,
        entext: "Mozambique(+258)",
    },
    {
        pattern: "^(00){0,1}(52){0,1}\\d{6,12}$",
        code: 52,
        desp: "MX",
        text: "墨西哥(+52)",
        value: 56,
        entext: "Mexico(+52)",
    },
    {
        pattern: "^(00){0,1}(264){0,1}\\d{6,15}$",
        code: 264,
        desp: "NA",
        text: "纳米比亚(+264)",
        value: 140,
        entext: "Namibia(+264)",
    },
    {
        pattern: "^(00){0,1}(27){0,1}\\d{6,12}$",
        code: 27,
        desp: "ZA",
        text: "南非(+27)",
        value: 72,
        entext: "South Africa(+27)",
    },
    {
        pattern: "^(00){0,1}(505){0,1}\\d{6,15}$",
        code: 505,
        desp: "NI",
        text: "尼加拉瓜(+505)",
        value: 142,
        entext: "Nicaragua(+505)",
    },
    {
        pattern: "^(00){0,1}(227){0,1}\\d{6,15}$",
        code: 227,
        desp: "NE",
        text: "尼日尔(+227)",
        value: 141,
        entext: "Niger(+227)",
    },
    {
        pattern: "^(00){0,1}(234){0,1}\\d{6,12}$",
        code: 234,
        desp: "NG",
        text: "尼日利亚(+234)",
        value: 57,
        entext: "Nigeria(+234)",
    },
    {
        pattern: "^(00){0,1}(47){0,1}\\d{6,12}$",
        code: 47,
        desp: "NO",
        text: "挪威(+47)",
        value: 58,
        entext: "Norway(+47)",
    },
    {
        pattern: "^(00){0,1}(351){0,1}\\d{6,12}$",
        code: 351,
        desp: "PT",
        text: "葡萄牙(+351)",
        value: 62,
        entext: "Portugal(+351)",
    },
    {
        pattern: "^(00){0,1}(81){0,1}0{0,1}[7,8,9](?:\\d{8}|\\d{9})$",
        code: 81,
        desp: "JP",
        text: "日本(+81)",
        value: 6,
        entext: "Japan(+81)",
    },
    {
        pattern: "^(00){0,1}(46){0,1}[124-7](\\d{8}|\\d{10}|\\d{12})$",
        code: 46,
        desp: "SE",
        text: "瑞典(+46)",
        value: 23,
        entext: "Sweden(+46)",
    },
    {
        pattern: "^(00){0,1}(41){0,1}\\d{6,12}$",
        code: 41,
        desp: "CH",
        text: "瑞士(+41)",
        value: 34,
        entext: "Switzerland(+41)",
    },
    {
        pattern: "^(00){0,1}(503){0,1}\\d{6,15}$",
        code: 503,
        desp: "SV",
        text: "萨尔瓦多(+503)",
        value: 157,
        entext: "El Salvador(+503)",
    },
    {
        pattern: "^(00){0,1}(381){0,1}\\d{6,12}$",
        code: 381,
        desp: "RS",
        text: "塞尔维亚(+381)",
        value: 65,
        entext: "Serbia(+381)",
    },
    {
        pattern: "^(00){0,1}(232){0,1}\\d{6,15}$",
        code: 232,
        desp: "SL",
        text: "塞拉利昂(+232)",
        value: 152,
        entext: "Sierra Leone(+232)",
    },
    {
        pattern: "^(00){0,1}(221){0,1}\\d{6,15}$",
        code: 221,
        desp: "SN",
        text: "塞内加尔(+221)",
        value: 153,
        entext: "Senegal(+221)",
    },
    {
        pattern: "^(00){0,1}(357){0,1}\\d{6,15}$",
        code: 357,
        desp: "CY",
        text: "塞浦路斯(+357)",
        value: 97,
        entext: "Cyprus(+357)",
    },
    {
        pattern: "^(00){0,1}(248){0,1}\\d{6,12}$",
        code: 248,
        desp: "SC",
        text: "塞舌尔(+248)",
        value: 67,
        entext: "Seychelles(+248)",
    },
    {
        pattern: "^(00){0,1}(966){0,1}\\d{6,12}$",
        code: 966,
        desp: "SA",
        text: "沙特阿拉伯(+966)",
        value: 66,
        entext: "Saudi Arabia(+966)",
    },
    {
        pattern: "^(00){0,1}(94){0,1}\\d{6,12}$",
        code: 94,
        desp: "LK",
        text: "斯里兰卡(+94)",
        value: 50,
        entext: "Sri Lanka(+94)",
    },
    {
        pattern: "^(00){0,1}(421){0,1}\\d{6,15}$",
        code: 421,
        desp: "SK",
        text: "斯洛伐克(+421)",
        value: 151,
        entext: "Slovakia(+421)",
    },
    {
        pattern: "^(00){0,1}(386){0,1}\\d{6,15}$",
        code: 386,
        desp: "SI",
        text: "斯洛文尼亚(+386)",
        value: 150,
        entext: "Slovenia(+386)",
    },
    {
        pattern: "^(00){0,1}(268){0,1}\\d{6,15}$",
        code: 268,
        desp: "SZ",
        text: "斯威士兰(+268)",
        value: 159,
        entext: "Swaziland(+268)",
    },
    {
        pattern: "^(00){0,1}(597){0,1}\\d{6,15}$",
        code: 597,
        desp: "SR",
        text: "苏里南(+597)",
        value: 155,
        entext: "Suriname(+597)",
    },
    {
        pattern: "^(00){0,1}(992){0,1}\\d{6,15}$",
        code: 992,
        desp: "TJ",
        text: "塔吉克斯坦(+992)",
        value: 162,
        entext: "Tajikistan(+992)",
    },
    {
        pattern: "^(00){0,1}(66){0,1}[13456789]\\d{7,8}$",
        code: 66,
        desp: "TH",
        text: "泰国(+66)",
        value: 13,
        entext: "Thailand(+66)",
    },
    {
        pattern: "^(00){0,1}(255){0,1}\\d{6,15}$",
        code: 255,
        desp: "TZ",
        text: "坦桑尼亚(+255)",
        value: 165,
        entext: "Tanzania(+255)",
    },
    {
        pattern: "^(00){0,1}(1868){0,1}\\d{6,15}$",
        code: 1868,
        desp: "TT",
        text: "特立尼达和多巴哥(+1868)",
        value: 164,
        entext: "Trinidad & Tobago(+1868)",
    },
    {
        pattern: "^(00){0,1}(216){0,1}\\d{6,12}$",
        code: 216,
        desp: "TN",
        text: "突尼斯(+216)",
        value: 68,
        entext: "Tunisia(+216)",
    },
    {
        pattern: "^(00){0,1}(90){0,1}\\d{6,12}$",
        code: 90,
        desp: "TR",
        text: "土耳其(+90)",
        value: 69,
        entext: "Turkey(+90)",
    },
    {
        pattern: "^(00){0,1}(993){0,1}\\d{6,15}$",
        code: 993,
        desp: "TM",
        text: "土库曼斯坦(+993)",
        value: 163,
        entext: "Turkmenistan(+993)",
    },
    {
        pattern: "^(00){0,1}(502){0,1}\\d{6,15}$",
        code: 502,
        desp: "GT",
        text: "危地马拉(+502)",
        value: 110,
        entext: "Guatemala(+502)",
    },
    {
        pattern: "^(00){0,1}(58){0,1}\\d{6,12}$",
        code: 58,
        desp: "VE",
        text: "委内瑞拉(+58)",
        value: 70,
        entext: "Venezuela(+58)",
    },
    {
        pattern: "^(00){0,1}(673){0,1}\\d{6,15}$",
        code: 673,
        desp: "BN",
        text: "文莱(+673)",
        value: 85,
        entext: "Brunei(+673)",
    },
    {
        pattern: "^(00){0,1}(256){0,1}\\d{6,15}$",
        code: 256,
        desp: "UG",
        text: "乌干达(+256)",
        value: 166,
        entext: "Uganda(+256)",
    },
    {
        pattern: "^(00){0,1}(380){0,1}[3-79]\\d{8,9}$",
        code: 380,
        desp: "UA",
        text: "乌克兰(+380)",
        value: 24,
        entext: "Ukraine(+380)",
    },
    {
        pattern: "^(00){0,1}(598){0,1}\\d{6,15}$",
        code: 598,
        desp: "UY",
        text: "乌拉圭(+598)",
        value: 167,
        entext: "Uruguay(+598)",
    },
    {
        pattern: "^(00){0,1}(998){0,1}\\d{6,15}$",
        code: 998,
        desp: "UZ",
        text: "乌兹别克斯坦(+998)",
        value: 168,
        entext: "Uzbekistan(+998)",
    },
    {
        pattern: "^(00){0,1}(34){0,1}\\d{6,12}$",
        code: 34,
        desp: "ES",
        text: "西班牙(+34)",
        value: 40,
        entext: "Spain(+34)",
    },
    {
        pattern: "^(00){0,1}(30){0,1}\\d{6,12}$",
        code: 30,
        desp: "GR",
        text: "希腊(+30)",
        value: 42,
        entext: "Greece(+30)",
    },
    {
        pattern: "^(00){0,1}(65){0,1}[13689]\\d{6,7}$",
        code: 65,
        desp: "SG",
        text: "新加坡(+65)",
        value: 11,
        entext: "Singapore(+65)",
    },
    {
        pattern: "^(00){0,1}(64){0,1}[278]\\d{7,9}$",
        code: 64,
        desp: "NZ",
        text: "新西兰(+64)",
        value: 21,
        entext: "New Zealand(+64)",
    },
    {
        pattern: "^(00){0,1}(36){0,1}\\d{6,12}$",
        code: 36,
        desp: "HU",
        text: "匈牙利(+36)",
        value: 43,
        entext: "Hungary(+36)",
    },
    {
        pattern: "^(00){0,1}(1876){0,1}\\d{6,15}$",
        code: 1876,
        desp: "JM",
        text: "牙买加(+1876)",
        value: 117,
        entext: "Jamaica(+1876)",
    },
    {
        pattern: "^(00){0,1}(967){0,1}\\d{6,15}$",
        code: 967,
        desp: "YE",
        text: "也门(+967)",
        value: 171,
        entext: "Yemen(+967)",
    },
    {
        pattern: "^(00){0,1}(972){0,1}\\d{6,12}$",
        code: 972,
        desp: "IL",
        text: "以色列(+972)",
        value: 45,
        entext: "Israel(+972)",
    },
    {
        pattern: "^(00){0,1}(39){0,1}[37]\\d{8,11}$",
        code: 39,
        desp: "IT",
        text: "意大利(+39)",
        value: 18,
        entext: "Italy(+39)",
    },
    {
        pattern: "^(00){0,1}(91){0,1}\\d{10}$",
        code: 91,
        desp: "IN",
        text: "印度(+91)",
        value: 46,
        entext: "India(+91)",
    },
    {
        pattern: "^(00){0,1}(62){0,1}[2-9]\\d{7,11}$",
        code: 62,
        desp: "ID",
        text: "印度尼西亚(+62)",
        value: 16,
        entext: "Indonesia(+62)",
    },
    {
        pattern: "^(00){0,1}(44){0,1}[347-9](\\d{8,9}|\\d{11,12})$",
        code: 44,
        desp: "GB",
        text: "英国(+44)",
        value: 9,
        entext: "United Kingdom(+44)",
    },
    {
        pattern: "^(00){0,1}(1284){0,1}\\d{6,12}$",
        code: 1284,
        desp: "VG",
        text: "英属维尔京群岛(+1284)",
        value: 71,
        entext: "Virgin Islands, British(+1284)",
    },
    {
        pattern: "^(00){0,1}(962){0,1}\\d{6,12}$",
        code: 962,
        desp: "JO",
        text: "约旦(+962)",
        value: 47,
        entext: "Jordan(+962)",
    },
    {
        pattern: "^(00){0,1}(84){0,1}[1-9]\\d{6,9}$",
        code: 84,
        desp: "VN",
        text: "越南(+84)",
        value: 14,
        entext: "Vietnam(+84)",
    },
    {
        pattern: "^(00){0,1}(260){0,1}\\d{6,15}$",
        code: 260,
        desp: "ZM",
        text: "赞比亚(+260)",
        value: 172,
        entext: "Zambia(+260)",
    },
    {
        pattern: "^(00){0,1}(235){0,1}\\d{6,15}$",
        code: 235,
        desp: "TD",
        text: "乍得(+235)",
        value: 160,
        entext: "Chad(+235)",
    },
    {
        pattern: "^(00){0,1}(56){0,1}\\d{6,12}$",
        code: 56,
        desp: "CL",
        text: "智利(+56)",
        value: 35,
        entext: "Chile(+56)",
    },
];

export default mobileArr

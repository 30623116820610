import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"fontsize":"16px","color":"#3b3b3b"} }
const _hoisted_2 = { style: {"margin-top":"5px"} }
const _hoisted_3 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "tradeHeader" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 6,
          offset: 0,
          class: "tag"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, { to: {
                path:'/user'
            } }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.langObj.l0027), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/my" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.langObj.l0028), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showAuthAlert,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showAuthAlert) = $event)),
      width: "30%"
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.langObj.l0029), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_el_button, {
            type: "primary",
            style: {"margin-left":"20px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAuthAlert = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.langObj.l0030), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.showAlertTag == 1 ? _ctx.langObj.l0031 : _ctx.langObj.l0032), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}

import { useStore } from "vuex";
import Config from "@/assets/ts/config";
import { defineComponent, onMounted, ref, computed } from "vue";
import { imgHeader, savePayment } from "@/assets/http";
import { ElMessage } from "element-plus";
import { getPayName, PaymentEnum } from "@/assets/ts/common";
import Lang from "@/assets/lang";
export default defineComponent({
    props: {},
    emits: ["refreshList"],
    setup(props, context) {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const user = ref(computed(() => $store.state.userInfo));
        const showAddAlert = ref(false);
        const selectPayType = ref(3);
        const selectPayIndex = ref(0);
        const inputAccount = ref("");
        const inputAccountName = ref("");
        const inputIFSC = ref("");
        const inputIFSCBankName = ref("");
        const inputEmail = ref("");
        const inputPhone = ref("");
        const imgUrl = ref("");
        const fileHost = ref(Config.project.host);
        const paymentOptions = ref([
            {
                type: 3,
                title: "",
            },
            {
                type: 4,
                title: "",
            },
            {
                type: 5,
                title: "",
            },
            {
                type: 6,
                title: "",
            },
            {
                type: 7,
                title: "",
            },
        ]);
        const initMyData = () => {
            selectPayType.value = 3;
            inputAccount.value = "";
            inputAccountName.value = "";
            inputIFSC.value = "";
            inputEmail.value = "";
            inputPhone.value = "";
            imgUrl.value = "";
        };

        onMounted(() => {
            console.log(imgHeader);
            paymentOptions.value.forEach(ele => {
                ele.title = getPayName(ele.type);
            });
        });

        const saveData = (data: any) => {
            savePayment(data, () => {
                showAddAlert.value = false;
                ElMessage.success(langObj.value.l0288);
                initMyData();
                context.emit("refreshList", 1);
            });
        };

        const addConfirm = () => {
            if (user.value.userId == 0) {
                ElMessage.error(langObj.value.l0297);
                return;
            }
            if (selectPayType.value == 4) {
                //Bank Card
                if (!inputAccountName.value || inputAccountName.value.length == 0) {
                    ElMessage.error(langObj.value.l0283);
                    return;
                }

                if (!inputAccount.value || inputAccount.value.length == 0) {
                    ElMessage.error(langObj.value.l0290);
                    return;
                }

                const reg1 = /^[a-zA-Z]{4}([a-zA-Z0-9]{7})$/;
                if (!inputIFSC.value || !reg1.test(inputIFSC.value)) {
                    ElMessage.error(langObj.value.l0292);
                    return;
                }
                const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
                if (inputEmail.value && !reg.test(inputEmail.value)) {
                    ElMessage.error(langObj.value.l0291);
                    return;
                }
                const reg2 = /^[0-9]{10}$/;
                if (inputPhone.value && !reg2.test(inputPhone.value)) {
                    ElMessage.error(langObj.value.l0293);
                    return;
                }

                const newPayment = {
                    type: selectPayType.value,
                    userId: user.value.userId,
                    accountName: inputAccountName.value,
                    account: inputAccount.value,
                    ifscCode: inputIFSC.value,
                    email: inputEmail.value || "",
                    phone: inputPhone.value || "",
                };
                saveData(newPayment);
            } else if (selectPayType.value == 6) {
                //IMPS
                if (!inputAccountName.value || inputAccountName.value.length == 0) {
                    ElMessage.error(langObj.value.l0283);
                    return;
                }

                if (!inputAccount.value || inputAccount.value.length == 0) {
                    ElMessage.error(langObj.value.l0290);
                    return;
                }
                const reg1 = /^[a-zA-Z]{4}([a-zA-Z0-9]{7})$/;
                if (!inputIFSC.value || !reg1.test(inputIFSC.value)) {
                    ElMessage.error(langObj.value.l0292);
                    return;
                }
                const newPayment = {
                    type: selectPayType.value,
                    userId: user.value.userId,
                    accountName: inputAccountName.value,
                    account: inputAccount.value,
                    ifscCode: inputIFSC.value,
                };
                saveData(newPayment);
            } else if (selectPayType.value == 7) {
                //Google
                if (!inputAccountName.value || inputAccountName.value.length == 0) {
                    ElMessage.error(langObj.value.l0283);
                    return;
                }

                const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
                if (!inputEmail.value || !reg.test(inputEmail.value)) {
                    ElMessage.error(langObj.value.l0291);
                    return;
                }

                const newPayment = {
                    type: selectPayType.value,
                    userId: user.value.userId,
                    email: inputEmail.value,
                    accountName: inputAccountName.value,
                    paymentUrl: imgUrl.value ? imgUrl.value : "",
                };
                saveData(newPayment);
            } else if (selectPayType.value == 5) {
                //UPI
                const reg = /^[0-9]{1,40}$/;
                if (!inputAccount.value || !reg.test(inputAccount.value)) {
                    ElMessage.error(langObj.value.l0286);
                    return;
                }
                if (!inputAccountName.value || inputAccountName.value.length == 0) {
                    ElMessage.error(langObj.value.l0287);
                    return;
                }
                const newPayment = {
                    type: selectPayType.value,
                    userId: user.value.userId,
                    accountName: inputAccountName.value,
                    account: inputAccount.value,
                    paymentUrl: imgUrl.value ? imgUrl.value : "",
                };
                saveData(newPayment);
            } else {
                //PayTM
                if (!inputAccount.value || inputAccount.value.length == 0) {
                    ElMessage.error(langObj.value.l0286);
                    return;
                }
                if (!inputAccountName.value || inputAccountName.value.length == 0) {
                    ElMessage.error(langObj.value.l0287);
                    return;
                }
                const newPayment = {
                    type: selectPayType.value,
                    userId: user.value.userId,
                    accountName: inputAccountName.value,
                    account: inputAccount.value,
                    paymentUrl: imgUrl.value ? imgUrl.value : "",
                };
                saveData(newPayment);
            }
        };

        const selectPayPop = (type: number) => {
            selectPayType.value = type;
            if (type == undefined) {
                selectPayIndex.value = 0;
            }
            selectPayIndex.value = type;
        };

        const handleAvatarSuccess = (res: any, file: any) => {
            imgUrl.value = res.data.url;
            console.log("上传之后--", imgUrl.value, res);
        };
        const handleAvatarError = (res: any, file: any) => {
            console.log("上传失败", res, file);
            ElMessage.error(langObj.value.l0296);
        };
        const beforeAvatarUpload = (file: any) => {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     ElMessage.error("上传头像图片只能是 JPG 格式!");
            // }
            // if (!isLt2M) {
            //     ElMessage.error("上传头像图片大小不能超过 2MB!");
            // }
            // return isJPG && isLt2M;
            return true;
        };
        const changeCardNumber = (value: any) => {
            if (value.length > 19) {
                inputIFSC.value = value.toString().substring(0, 19);
            } else {
                inputIFSC.value = value;
            }
            inputIFSCBankName.value = inputIFSC.value.substring(0, 4);
        };
        const changePhoneNumber = (value: any) => {
            if (value.length > 10) {
                inputPhone.value = value.toString().substring(0, 10);
            } else {
                inputPhone.value = value;
            }
        };
        const showAlert = (type: PaymentEnum, item: any) => {
            if (type == PaymentEnum.Add) {
                showAddAlert.value = true;
                selectPayIndex.value = selectPayType.value;
            }
        };

        return {
            langObj,
            showAlert,
            fileHost,
            imgHeader,
            user,
            handleAvatarSuccess,
            beforeAvatarUpload,
            handleAvatarError,
            showAddAlert,
            selectPayIndex,
            paymentOptions,
            selectPayPop,
            addConfirm,
            selectPayType,
            inputAccount,
            inputAccountName,
            inputIFSCBankName,
            inputIFSC,
            inputPhone,
            inputEmail,
            imgUrl,
            changePhoneNumber,
            changeCardNumber,
            getImgUrl: () => {
                const url = Config.project.fileHost + imgUrl.value;
                return url;
            },
        };
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, { height: "70px" }, {
          default: _withCtx(() => [
            _createVNode(_component_Header)
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }),
        _createVNode(_component_el_footer, { height: "70px" }, {
          default: _withCtx(() => [
            _createVNode(_component_Footer)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1a544c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "maxW1200 flexBothEnds" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "right" }
const _hoisted_5 = { class: "avatar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/images/'+ _ctx.langI + '/logo' + _ctx.Config.project.name + '.png')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.langV,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.langV) = $event)),
          onChange: _ctx.onChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langArr, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"]),
        (_ctx.mainIsLogin)
          ? (_openBlock(), _createBlock(_component_el_dropdown, { key: 0 }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.userInfo.nickName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, null, {
                      default: _withCtx(() => [
                        _createTextVNode("ID:" + _toDisplayString(_ctx.userInfo.userId), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { onClick: _ctx.onSignOut }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj.l0001), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.userInfo.nickName.substring(0, 1)), 1),
                  _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_arrow_down)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              size: "small",
              type: "primary",
              onClick: _ctx.onLogin
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.langObj.l0002), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
      ])
    ])
  ]))
}
/**
 * 项目公共区域
 **/

import router from "@/router";
import store from "@/store";
import { ElMessageBox } from "element-plus";
import Lang from "../lang";
import Config from "./config";

/**
 * 判断是否有支付密码
 * @return boolean
 **/
export function havePassWord(): boolean {
    if (!store.state.userInfo.payPassword) {
        ElMessageBox.confirm(Lang.langObj.l0400, {
            confirmButtonText: Lang.langObj.l0004,
            cancelButtonText: Lang.langObj.l0005,
            callback: (action: string) => {
                if (action === "confirm") {
                    router.push({ path: "/user/securitySet" });
                }
            },
        });
        return false;
    }
    return true;
}

/**
 * 获取支付方式名字
 * @param {number} type 类型
 * @return string
 **/
export function getPayName(type: number): string {
    const obj = Config.currency.paymentArr.value.find((item: any) => item.type == type);
    return obj ? obj.name : type.toString();
}

/**
 * 获取支付icon
 * @param {string} str icon类型字符
 * @return string
 **/
export function getPayIcon(type: string): string {
    if (type == null || type == undefined || type === "") return "";
    return require("@/assets/images/pay" + type + ".png");
}

export enum PaymentEnum {
    Add = "Add",
    Delete = "Delete",
    Detail = "Detail",
    Edit = "Edit",
}


import { computed, defineComponent, ref, Ref } from "vue";
import {
    checkPayPassword,
    getNewAppealLog,
    getOrderDetail,
    getPayMethod,
    httpTime,
    postAppealOrder,
    putCancelAppeal,
    putCancelOrder,
    putPaymentOrder,
    putReleaseOrder,
} from "@/assets/http";
import PayMethodVo from "@/assets/vo/PayMethodVo";
import OrderDetailsVo from "@/assets/vo/OrderDetailsVo";
import Lang from "@/assets/lang";
import { useStore } from "vuex";
import Utils from "@/assets/ts/utils";
import { havePassWord } from "@/assets/ts/common";
import { ElMessage, ElMessageBox } from "element-plus";
import Config from "@/assets/ts/config";
export default defineComponent({
    name: "OrderDetails",
    setup() {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const isShow = ref(false);
        const orderObj = ref(new OrderDetailsVo());
        const isMySell = ref(false);
        const isMyAppeal = ref(false);
        const appealObj = ref({ cause: "", userId: "", command: "" }); //申诉订单
        const payArr: Ref<PayMethodVo[]> = ref([]);
        const payArrI = ref(0);
        /****计时器*****/
        let setTime: NodeJS.Timer | null = null;
        const onSetIntEnd = () => {
            setTime && clearInterval(setTime);
            setTime = null;
        };
        const waitTime = ref("00:00");
        const payTime = ref("00:00:00");
        const appealTime = ref("00:00:00");
        const isAppeal = ref(false); //是否可以申诉
        const dialogVisible1 = ref(false);
        const appealArr: Ref<string[]> = ref([]);
        const appealRadio = ref(0);
        const onSetInt = () => {
            if (orderObj.value.status == 0) {
                const num = (orderObj.value.createTime + 885000 - httpTime()) / 1000;
                if (num == 0) {
                    onSetIntEnd();
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    onOrderDetail();
                }
                waitTime.value = Utils.getCountdown(num, false);
            } else if (orderObj.value.status == 1 || orderObj.value.status == 4) {
                const num1 = (httpTime() - orderObj.value.payTime) / 1000;
                payTime.value = Utils.getCountdown(num1);
                const num2 = 300 - num1;
                isAppeal.value = num2 < 0;
                appealTime.value = Utils.getCountdown(num2, false);
            }
        };
        const onSetIntStart = () => {
            if (!setTime) {
                onSetInt();
                setTime = setInterval(onSetInt, 1000);
            }
        };
        /****计时器*****/
        /**获取激活支付方式**/
        const onPayMethod = () => {
            getPayMethod(orderObj.value.payUserId, (data: any[]) => {
                if (orderObj.value.paymentMethodId) {
                    const _obj = data.find(item => item.id == orderObj.value.paymentMethodId);
                    if (_obj) payArr.value = [_obj];
                    else payArr.value = [];
                } else {
                    payArr.value = data.filter(item => orderObj.value.paymentMethodTypes.includes(item.type));
                }
                payArrI.value = 0;
            });
        };
        /**获取订单申诉日志**/
        const onNewAppealLog = () => {
            getNewAppealLog(orderObj.value.id, (data: any) => {
                Utils.updateObj(appealObj.value, data);
                isMyAppeal.value = appealObj.value.userId == $store.state.userInfo.userId;
            });
        };

        const dialogVisible2 = ref(false);

        /**更新订单信息**/
        const updataOrderDetail = (data: OrderDetailsVo) => {
            Utils.updateObj(orderObj.value, data);
            if (orderObj.value.status == 4) onNewAppealLog();
            if (orderObj.value.status == 0 || orderObj.value.status == 1 || orderObj.value.status == 4) {
                onSetIntStart();
            }
        };

        /**获取订单详情**/
        const onOrderDetail = (isUpdatePay: boolean | false = false) => {
            getOrderDetail(orderObj.value.id, (data: OrderDetailsVo) => {
                updataOrderDetail(data);
                isUpdatePay && onPayMethod();
                isShow.value = true;
            });
        };
        const isHide = (isfalse:boolean) =>{
            isShow.value = false;
        };
        const onShow = (obj: any, _isMySell: boolean) => {
            isShow.value = false;
            isMySell.value = _isMySell;
            if (obj) {
                Utils.updateObj(orderObj.value, obj);
                onOrderDetail(true);
                if (_isMySell) appealArr.value = [langObj.value.l0188, langObj.value.l0189, langObj.value.l0190];
                else appealArr.value = [langObj.value.l0191, langObj.value.l0192, langObj.value.l0190];
            }
        };
        return {
            langObj,
            Config,
            isShow,
            isHide,
            onShow,
            updataOrderDetail,
            isMySell,
            orderObj,
            payArrI,
            payArr,
            waitTime,
            payTime,
            appealTime,
            isAppeal,
            getTitle: computed(() => {
                //订单标题
                const _arr1 = [langObj.value.l0193, langObj.value.l0194, langObj.value.l0195, langObj.value.l0099, langObj.value.l0194];
                const _arr2 = [langObj.value.l0197, langObj.value.l0198, langObj.value.l0195, langObj.value.l0099, langObj.value.l0198];
                if (isMySell.value) return _arr1[orderObj.value.status];
                else return _arr2[orderObj.value.status];
            }),
            isMyAppeal, //是否自己申诉
            getTip: computed(() => {
                //订单提示信息
                const _arr1 = [langObj.value.l0199, langObj.value.l0342, langObj.value.l0343, langObj.value.l0344, langObj.value.l0345];
                const _arr2 = [langObj.value.l0346, langObj.value.l0347, langObj.value.l0343, langObj.value.l0344, langObj.value.l0345];
                let _str = "";
                if (isMySell.value) _str = _arr1[orderObj.value.status];
                else _str = _arr2[orderObj.value.status];
                if (isMyAppeal.value) _str += "，" + langObj.value.l0348 + ":" + appealObj.value.command;
                return _str;
            }),
            formatDateTime: computed(() => {
                //订单时间
                return Utils.formatDateTime(orderObj.value.createTime);
            }),
            clipboard: (str: string) => {
                Utils.clipboard({ text: str });
            },
            payTitle: (num: number) => {
                //支付方式
                const _arr = computed(() => [
                    langObj.value.l0351,
                    langObj.value.l0350,
                    langObj.value.l0349,
                    "PayTM",
                    "Bank Card",
                    "UPI",
                    "IMPS",
                    "Google Pay",
                ]);
                return _arr.value[num];
            },
            onPaySelect: (index: number) => {
                //选择支付方式
                if (orderObj.value.status == 0) payArrI.value = index;
            },
            dialogVisible1,
            appealRadio,
            appealArr,
            openAppeal: () => {
                //申诉
                if (isAppeal.value) dialogVisible1.value = true;
            },
            onAppealOrder: () => {
                //确认申诉
                const _data = {
                    id: orderObj.value.id,
                    cause: appealArr.value[appealRadio.value],
                };
                postAppealOrder(_data, () => {
                    dialogVisible1.value = false;
                    ElMessage.success(langObj.value.l0352);
                });
            },
            onGetMoney: () => {
                //确认已经收到付款，放行订单
                if (havePassWord()) {
                    ElMessageBox.confirm(langObj.value.l0353, {
                        confirmButtonText: langObj.value.l0354,
                        cancelButtonText: langObj.value.l0355,
                        type: "warning",
                        callback: (action: string) => {
                            if (action == "confirm") {
                                ElMessageBox.prompt(langObj.value.l0356 + "：" + orderObj.value.amount.toFixed(6) + "USDT", langObj.value.l0357, {
                                    confirmButtonText: langObj.value.l0004,
                                    cancelButtonText: langObj.value.l0005,
                                    inputType: "password",
                                    inputPlaceholder: langObj.value.l0121,
                                    callback: (action: any) => {
                                        if (action.action === "confirm") {
                                            const data = {
                                                payPassWord: action.value,
                                                userId: $store.state.userInfo.userId,
                                            };
                                            checkPayPassword(data, () => {
                                                putReleaseOrder(orderObj.value.id, () => {
                                                    ElMessage.success(langObj.value.l0358);
                                                });
                                            });
                                        }
                                    },
                                });
                            }
                        },
                    });
                }
            },
            onCancelAppeal: () => {
                //取消申诉
                if (isMyAppeal.value) {
                    ElMessageBox.confirm(langObj.value.l0359, {
                        confirmButtonText: langObj.value.l0360,
                        cancelButtonText: langObj.value.l0361,
                        type: "warning",
                        callback: (action: string) => {
                            if (action == "confirm") {
                                putCancelAppeal(orderObj.value.id, () => {
                                    ElMessage.success(langObj.value.l0362);
                                });
                            }
                        },
                    });
                }
            },
            cancelOrder: () => {
                //取消订单
                ElMessageBox.confirm(langObj.value.l0363, {
                    confirmButtonText: langObj.value.l0360,
                    cancelButtonText: langObj.value.l0361,
                    callback: (action: string) => {
                        if (action == "confirm") {
                            putCancelOrder(orderObj.value.id, () => {
                                ElMessage.success(langObj.value.l0362);
                            });
                        }
                    },
                });
            },
            dialogVisible2,
            payOrder: () => {
                //已付款
                putPaymentOrder(orderObj.value.id, payArr.value[payArrI.value].id, () => {
                    ElMessage.success(langObj.value.l0364);
                    dialogVisible2.value = false;
                });
            },
        };
    },
});

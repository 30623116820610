import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
const app = createApp(App);
import ElementPlus from "element-plus";
import { Expand,Edit, DocumentCopy,ArrowDown,Close,ArrowRight,Picture,Loading,Warning,UserFilled,Unlock,Refresh} from '@element-plus/icons'
import "element-plus/dist/index.css";
import "@/assets/css/main.scss";
import { init } from "./assets/http";
import LocalEnum from "./assets/enum/LocalEnum";
app.use(ElementPlus);
app.component('expand',Expand)
app.component('edit',Edit)
app.component('documentCopy',DocumentCopy)
app.component('arrowDown',ArrowDown)
app.component('close',Close)
app.component('arrowRight',ArrowRight)
app.component('picture',Picture)
app.component('loading',Loading)
app.component('warning',Warning)
app.component('userFilled',UserFilled)
app.component('unlock',Unlock)
app.component('refresh',Refresh)
app.use(store);
app.use(router);
app.mount("#app");

const _tokenObj = localStorage.getItem(LocalEnum.TOKEN_OBJ);
_tokenObj && sessionStorage.setItem(LocalEnum.TOKEN_OBJ, _tokenObj);
localStorage.removeItem(LocalEnum.TOKEN_OBJ);

const _lang = localStorage.getItem(LocalEnum.LANG);
_lang && localStorage.setItem(LocalEnum.LANG, _lang);
// localStorage.removeItem(LocalEnum.LANG);

window.onbeforeunload = function() {
    const _tokenObj = sessionStorage.getItem(LocalEnum.TOKEN_OBJ);
    _tokenObj && localStorage.setItem(LocalEnum.TOKEN_OBJ, _tokenObj);

    const _lang = sessionStorage.getItem(LocalEnum.TOKEN_OBJ);
    _lang && localStorage.setItem(LocalEnum.TOKEN_OBJ, _lang);
};

init();

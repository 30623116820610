import { en } from "./private";
export default {
    ...en,
    l0001: "Exit",
    l0002: "Login",
    l0003: "Exit the current account?",
    l0004: "OK",
    l0005: "Cancel",
    l0006: "Unit price",
    l0007: "All",
    l0008: "Limit",
    l0009: "Transaction quantity",
    l0010: "Actual payment",
    l0011: "Cancel in {1} seconds",
    l0012: "Please check the order information, total price, quantity and collection method, and transfer to the other party by yourself. ",
    l0013:
        "After completing the transfer, please keep the payment screenshot and other vouchers, send them to the order chat, and timely operate [I have completed the payment]. ",
    l0014: "Your account balance is 0 and cannot be sold.",
    l0015: "Please input amount",
    l0016: "Please input quantity",
    l0017: "Buy ",
    l0018: "Sell ",
    l0019: "Amount cannot be 0",
    l0020: "The amount or quantity of your purchase is less than the minimum limit",
    l0021: "By amount ",
    l0022: "By quantity ",
    l0023: "P2P",
    l0024: "Sale",
    l0025: "Message",
    l0026: "Ad",
    l0027: "Personal",
    l0028: "Assets",
    l0029: "Warm tips",
    l0030: "Yes",
    l0031: "You do not have sufficient permissions to conduct advertising transactions. ",
    l0032: "You need to apply for and get permission to publish advertisements. ",
    l0033: "Amount",
    l0034: "Pay method",
    l0035: "My personal information",
    l0036: "Collection method",
    l0037: "Transfer",
    l0038: "Withdraw",
    l0039: "Bill",
    l0040: "My bill",
    l0041: "Time",
    l0042: "Start date",
    l0043: "To",
    l0044: "End date",
    l0045: "Amount",
    l0046: "Minimum",
    l0047: "Maximum",
    l0048: "Bill type",
    l0049: "currency type",
    l0050: "Expenditure",
    l0051: "Revenue",
    l0052: "Reset",
    l0053: "Loading",
    l0054: "No more for now",
    l0055: "There is no data...",
    l0056: "Collection and payment",
    l0057: "You cannot withdraw coins to your account",
    l0058: "Charge currency",
    l0059: "Mortgage",
    l0060: "Advertising",
    l0061: "Transaction",
    l0062: "Recharge",
    l0063: "Consumption",
    l0064: "Contributions",
    l0065: "Start time cannot be empty",
    l0066: "End time cannot be empty",
    l0067: "Foreign exchange",
    l0068: "Withdrawal entrustment",
    l0069: "Background deduction",
    l0070: "Maximum limit cannot be lower than minimum limit",
    l0071: "Order details",
    l0072: "Bill No.",
    l0073: "Opposite account",
    l0074: "User",
    l0075: "Service fee",
    l0076: "Amount received",
    l0077: "Merchant name",
    l0078: "Merchant No.",
    l0079: "Payment exchange rate",
    l0080: "Product information",
    l0081: "Exchange into currency",
    l0082: "Cash in amount",
    l0083: "Cash out",
    l0084: "Exchange rate",
    l0085: "Creation time",
    l0086: "Bill classification",
    l0087: "Transfer in",
    l0088: "Transfer out",
    l0089: "Copy failed",
    l0090: "Collection",
    l0091: "Payment",
    l0092: "Withdraw the currency successfully",
    l0093: "Failed to withdraw the currency",
    l0094: "Currency withdrawal confirmation",
    l0095: "Withdrawal of mortgage",
    l0096: "Add mortgage",
    l0097: "Advertisement cancellation",
    l0098: "Advertisement release",
    l0099: "Transaction cancellation",
    l0100: "Please use the real collection information. The activated collection method will be displayed in OTC and become evidence. ",
    l0101: "Add Pay method",
    l0102: "Active",
    l0103: "Off",
    l0104: "Delete",
    l0105: "Payment method",
    l0106: "I want to transfer money",
    l0107: "Transfer to",
    l0108: "Mobile",
    l0109: "Please select",
    l0110: "Available balance",
    l0111: "Next step",
    l0112: "Transfer confirmation",
    l0113: "Transfer quantity",
    l0114: "Payee nickname",
    l0115: "Payee",
    l0116: "Add notes (optional)",
    l0117: "Please enter transfer notes...",
    l0118: "Payment password is not set, please go to",
    l0119: "Settings",
    l0120: "Payment password",
    l0121: "Please enter payment password",
    l0122: "Please enter the user",
    l0123: "Cannot transfer to yourself",
    l0124: "Transfer quantity is less than limit",
    l0125: "This user was not queried",
    l0126: "Transfer succeeded",
    l0127: "Please input mobile phone number",
    l0128: "Verified",
    l0129: "Phone",
    l0130: "Register",
    l0131: "Update succeeded",
    l0132: "I'm going to raise it.",
    l0133: "Chain name",
    l0134: "Withdrawal address",
    l0135: "Please input the withdrawal address",
    l0136: "Amount received",
    l0137: "If the withdrawal amount exceeds 51 usdt, one day's delay is required to ensure the security of account assets.",
    l0138: "Security verification",
    l0139: "Amount of money withdrawn",
    l0140: "Please input the verification code of mobile phone",
    l0141: "Unbound mailbox, please go to",
    l0142: "Binding",
    l0143: "If you don't receive the email, please check the garbage can. If you forget the security item, please contact customer service. ",
    l0144: "Google authentication is not turned on, please go to",
    l0145: "Auth",
    l0146: "Google captcha",
    l0147: "Please input Google verification code",
    l0148: "Verification",
    l0149: "Wrong address for withdrawing money",
    l0150:
        "If it is detected that your target address is Dolphin Wallet user, you will enjoy the fast transfer channel, and the service charge will be free. ",
    l0151: "Please input email verification code",
    l0152: "My OTC Statistics",
    l0153: "Total Order",
    l0154: "Completion",
    l0155: "30 days",
    l0156: "Release",
    l0157: "Mortgage Assets",
    l0158: "Post an ad",
    l0159: "Online Sale",
    l0160: "Cancel",
    l0161: "Recovered Assets",
    l0162: "Clone",
    l0163: "Buy Online",
    l0164: "Remove Ads",
    l0165:
        "After confirmation, the advertisement will be immediately untradeable, and the cancellation will be completed after 15 minutes, and the remaining assets will be recovered to the available balance. If there are transactions in progress at that time, the assets will need to be recovered manually. Transactions in progress are not affected by the cancellation .",
    l0166: "You need to add and activate at least one valid payment method.",
    l0167: "Remaining Time",
    l0168: "Withdrawal",
    l0169: "Waiting for recycling",
    l0170: "Total Price",
    l0171: "Order Number",
    l0172: "Order Time",
    l0173: "Appeal",
    l0174: "Cancel Appeal",
    l0175: "I confirm receipt of the money",
    l0176: "Cancel order",
    l0177: "Reasons for Appeal",
    l0178: "Transaction reminder",
    l0179:
        "Assets will be frozen after the lawsuit is filed, and the reorganization commissioner will intervene in this transaction until the reorganization ends. Malicious tampering is an act of disrupting the normal transaction level, and the account will be frozen if the circumstances are serious",
    l0180: "Submit Appeal",
    l0181: "Record",
    l0182: "Payee",
    l0183: "Account Opening Bank",
    l0184: "Account Opening Branch",
    l0185: "Receipt QR Code",
    l0186: "A malicious click to confirm will affect your behavior record",
    l0187: "Confirm paid",
    l0188: "I did not receive payment from the buyer",
    l0189: "Buyer transferred less money",
    l0190: "Other",
    l0191: "I have completed the payment, but the seller did not release it in time",
    l0192: "I transferred more money to the seller",
    l0193: "Waiting for payment from buyer",
    l0194: "Confirm Release",
    l0195: "Transaction completed",
    l0196: "There is nothing to copy",
    l0197: "Please pay",
    l0198: "Waiting for seller confirmation",
    l0199: "If the buyer has not paid for a long time, it is recommended to contact the other party proactively",
    l0200: "Please enter 6-12 digits or letters for login password",
    l0201: "Password required to log in to Dolphin Wallet",
    l0202: "The password required in the event of a change in account funds",
    l0203: "Please enter the new login password again",
    l0204: "New login password",
    l0205: "Original login password",
    l0206: "Modify login password",
    l0207: "Successfully modify the login password",
    l0208: "The new password is not entered twice",
    l0209: "New password format is incorrect",
    l0210: "Succeeded in modifying payment password",
    l0211: "The new payment password format is incorrect",
    l0212: "Please enter the 6-digit mobile phone verification code",
    l0213: "The original password format is incorrect",
    l0214: "Set payment password successfully",
    l0215: "Two inputs are inconsistent",
    l0216: "Incorrect payment password format",
    l0217: "Successfully modified",
    l0218: "Set login password successfully",
    l0219: "Incorrect login password format",
    l0220: "Modify/forget",
    l0221: "Setting login password",
    l0222: "confirm login password",
    l0223: "Please enter the original password",
    l0224: "Forgot your login password",
    l0225: "Forgot your payment password",
    l0226: "Change/forget payment payment password",
    l0227: "Original payment password",
    l0228: "Please enter the original payment password",
    l0229: "New payment password",
    l0230: "Please enter a new 6-digit password",
    l0231: "Confirm the new password",
    l0232: "Please enter a new password",
    l0233: "Please enter the new password again",
    l0234: "Please enter the new payment password again",
    l0235: "Set payment password",
    l0236: "Confirm payment password",
    l0237: "Contact service",
    l0238: "Forgot password",
    l0239: "Login password",
    l0240: "New password",
    l0241: "Previous",
    l0242: "Finish",
    l0243: "Modify Email",
    l0244:
        "In order to protect the security of your account assets, you need to contact customer service to modify info and provide proof of your receiving bank card, screenshots of the most recent 3 transactions, etc.",
    l0245: "Send",
    l0246: "The system will send a verification code to your registered mobile phone number {X} to verify your identity",
    l0247: "Turn off Google verification",
    l0248: "Confirm Google verification code",
    l0249: "Turn on Google verification",
    l0250: "You can fill in the secret key to Google ID verification, or scan the QR code to add binding",
    l0251: "Are you sure you want to turn off Google verification?",
    l0252: "Please send a security verification email to {X}, and fill in the email verification code after receiving the email",
    l0253: "If you want to modify the mailbox after binding, please contact customer service",
    l0254: "Bind mailbox",
    l0255: "Unbound mailbox",
    l0256: "Google authentication",
    l0257: "Update nickname",
    l0258: "Please enter a nickname",
    l0259: "Modify registered mobile phone number",
    l0260: "please input your email",
    l0261: "Bind mobile phone number",
    l0262: "NickName",
    l0263: "Modify",
    l0264: "Copy",
    l0265: "Open",
    l0266: "Unopened",
    l0267: "E-mail format is incorrect",
    l0268: "Incorrect email verification code format",
    l0269: "Please enter the correct Google verification code",
    l0270: "Email verification code sent successfully",
    l0271: "Copy Success",
    l0272: "Basic information",
    l0273: "Security Settings",
    l0274: "Please select the payment method",
    l0275: "Cardholder‘s Name",
    l0276: "Please enter the cardholder’s name",
    l0277: "Bank card number",
    l0278: "Please enter the bank card number",
    l0279: "Please enter the bank name",
    l0280: "Bank account",
    l0281: "Account opening address",
    l0282: "Please enter the account opening address or branch name",
    l0283: "Please type in your name",
    l0284: "Name",
    l0285: "Account",
    l0286: "Please enter account",
    l0287: "please enter account username",
    l0288: "Add success",
    l0289: "Please enter the account opening bank",
    l0290: "please enter account",
    l0291: "Please enter the correct email format",
    l0292: "Please enter the correct format of IFSC",
    l0293: "Please enter the correct format of phone",
    l0294: "Collection QR code picture",
    l0295: "Please enter a pure digital account",
    l0296: "upload failed",
    l0297: "User id does not exist",
    l0298: "Please enter PayTM account number",
    l0299: "I want to buy",
    l0300: "I want to sell",
    l0301:
        "Publishing the sale advertisement will freeze the total amount of the corresponding assets sold, and the successful transaction will be transferred from the frozen assets.",
    l0302: "Post a purchase ad",
    l0303: "Advertise for sale",
    l0304: "At the same time withdraw the cloned ad",
    l0305: "Advertising message",
    l0306: "Add a picture",
    l0307:
        "If the content of the message is not empty, after the order is created, an advertising message will be automatically sent to the other party.",
    l0308: "Clear",
    l0309: "Aging",
    l0310: "Please fill in the time limit",
    l0311: "Max",
    l0312: "Maximum limit",
    l0313: "The minimum is not less than 1",
    l0314: "Order limit",
    l0315: "Please fill in the unit price",
    l0316: "Quantity sold",
    l0317: "Purchase quantity",
    l0318: "Please fill in the quantity",
    l0319: "Remaining",
    l0320: "hour",
    l0321: "inactivated",
    l0322: "Your OTC transaction has been permanently restricted",
    l0323: "Your OTC transaction has been restricted to",
    l0324: "The other party needs you to add and activate the corresponding payment method",
    l0325: "Choose at least one payment method",
    l0326: "Advertising time limit cannot exceed {X} hours",
    l0327: "The minimum advertising time limit is 1",
    l0328: "The maximum limit cannot be less than the minimum limit",
    l0329: "The minimum limit is 1",
    l0330: "The maximum limit is 1",
    l0331: "Price must be greater than 0",
    l0332: "The quantity cannot exceed the maximum balance",
    l0333: "Quantity must be greater than 0",
    l0334: "No match",
    l0335: "Please enter a password",
    l0336: "The phone number is wrong",
    l0337: "Please enter the verification code",
    l0338: "Answer Voice",
    l0339: "Log in with verification code",
    l0340: "Use password",
    l0341: "Times",
    l0342: "The buyer has paid, please confirm the release",
    l0343: "Transaction has been completed",
    l0344: "Transaction has been cancelled",
    l0345: "Appealing",
    l0346: "Please pay as soon as possible",
    l0347: "You have paid and wait for the seller to confirm",
    l0348: "Appeal Password",
    l0349: "Alipay",
    l0350: "WeChat",
    l0351: "Bank Card",
    l0352: "Appeal successful",
    l0353: "Confirm that you have received the full payment",
    l0354: "Confirm to receive",
    l0355: "Let me see again",
    l0356: "Order Quantity",
    l0357: "Order release verification payment password",
    l0358: "Release successful",
    l0359:
        "After canceling the appeal, your order status will be restored to the state of waiting for release. Please do not frequently appeal and cancel appeals without reason. These actions will be recorded and affect your credit.",
    l0360: "OK to cancel",
    l0361: "I will think about it again",
    l0362: "Cancelled successfully",
    l0363: "Are you sure to cancel the order?",
    l0364: "Confirmation succeeded",
    l0365: "Confirm Payment",
    l0366: "Please enter content",
    l0367: "Transaction Details",
    l0368: "You have successfully placed an order, please pay in time.",
    l0369: "The other party has successfully placed an order and is paying.",
    l0370: "You have marked the order as [paid], please wait for the other party to confirm receipt and release.",
    l0371:
        "The other party has paid, {1} has marked you {2}[{3}] successful payment {4}{5}, please check it in time and release it as soon as possible.",
    l0372: "The other party has confirmed receipt of your payment. The {1} you purchased has been issued to your account, please check.",
    l0373: "You have marked the order for release, and the other party will receive the {1} you sold.",
    l0374: "You have initiated an appeal, waiting for the customer service to intervene, you can also cancel the appeal.",
    l0375: "The other party has initiated an appeal and is waiting for customer service to intervene.",
    l0376: "The order has been cancelled. If you have any questions, please contact customer service.",
    l0377: "The other party has cancelled the order. If you have any questions, please contact customer service.",
    l0378:
        "Please check the payment information as soon as possible to complete the payment, and pay attention to keep the payment credentials or screenshots.",
    l0379: "If the other party hasn't paid for a long time, it is recommended to actively communicate with the other party.",
    l0380: "You have cancelled the appeal",
    l0381: "The other party has cancelled the appeal and the transaction has returned to normal.",
    l0382: "Please confirm that you have received the payment from the other party and release it as soon as possible.",
    l0383: "Please confirm the payment and remind the other party to let it go.",
    l0384: "Cannot receive SMS code?",
    l0385: "Minutes",
    l0386: "Ordinary Merchants",
    l0387: "Picture Message",
    l0388: "System Message",
    l0389: "Trade",
    l0390: "Completed",
    l0391: "Cancelled",
    l0392: "Waiting for payment",
    l0393: "Waiting for release",
    l0394: "Cannot trade with myself",
    l0395: "Unregistered mobile phone numbers will be automatically registered after verification",
    l0396: "Re-enter mobile phone number",
    l0397: "Will dial your mobile phone to notify the voice verification code?",
    l0398: "Are you sure to answer?",
    l0399: "User Information",
    l0400: "You haven't set a payment password. Do you want to go to the personal center to set a payment password?",
    l0401: "You have a new [{1}USDT] order, order number {2}, please process it as soon as possible.",
    l0402: "The payment for the order of [{2}USDT] with the order number {1} has been completed.",
    l0403: "The order of [{2}USDT] with order number {1} has been released.",
    l0404: "The order of [{2}USDT] with the order number {1} is being appealed. Waiting for customer service to process",
    l0405: "The order for [{2}USDT] with order number {1} has been cancelled.",
    l0406: "You have an order for [{1}USDT], the order number is {2}, and it has not been paid. Please process it as soon as possible.",
    l0407: "The withdrawal application has been completed, the amount is {1} {2}, please check the bill for details.",
    l0408: "You have a new [{1}USDT] order with order number {2}, please release it as soon as possible.",
    l0409:
        "Your {1} advertisement for sale has been cancelled, and the remaining {2}{3} has been successfully refunded. Please check the bill for details.",
    l0410: "Receipt into account",
    l0411: "Transfer received",
    l0412: "Remittance into account",
    l0413: "Recharge into account",
    l0414: "Payment to Account",
    l0415: "{1} {2} {3}, please check the bill for details.",
    l0416: "Send successfully",
    l0417: "Request successful",
    l0418: "PayTM",
    l0419: "Bank Card",
    l0420: "UPI",
    l0421: "IMPS",
    l0422: "Google Pay",
    l0423: "Batch transfer",
    l0424: "Import excel",
    l0425: "Uploaded successfully!",
    l0426: "Please delete the failed file and upload it again after modification",
    l0427: "Example:",
    l0428: "Upload now",
    l0429: "Only upload files with the suffix .xlsx or .xls",
    l0430: "User ID",
    l0431: "Quantity",
    l0432: "Address",
    l0433: "Drag the file here, or",
    l0434: "Only upload xlsx, xls files",
    l0435: "Only one can be uploaded at a time!",
    l0436: "Please upload files",
    l0437: "Nickname cannot be less than 6 digits",
    l0438: "The amount or quantity you purchased is greater than the maximum limit",
    l0439: "Dolphin Wallet",
    l0440: "Mobile phone recharge",
    l0441: "Offline transaction",
};

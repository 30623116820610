
import {
    getMobile,
    getMyBalance,
    gettWithdrawSetting,
    getWithdrawVerification,
    postUserByDigitalAddr,
    postWithdrawContact,
    postWithdrawVerification,
    sendMailCodeApi,
    tokenObj,
} from "@/assets/http";
import Lang from "@/assets/lang";
import Utils from "@/assets/ts/utils";
import WithdrawVo from "@/assets/vo/WithdrawVo";
import WithdrawSubVo from "@/assets/vo/WithdrawSubVo";
import WithdrawSetVo from "@/assets/vo/WithdrawSetVo";
import { defineComponent, onMounted, Ref, ref, computed, ComputedRef } from "vue";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import Config from "@/assets/ts/config";
import UserInfoVo from "@/assets/vo/UserInfoVo";
import { useRouter } from "vue-router"; //引入路由
export default defineComponent({
    setup() {
        const langObj = computed(() => Lang.langObj);
        const $router = useRouter();
        const $store = useStore();
        const balance = computed(() => $store.state.balance);
        const contentMax = computed(() => {
            const _obj = $store.state.captialConfig.USDT;
            if (_obj) return _obj.withdrawMax;
            return 0;
        });
        const contentMin = computed(() => {
            const _obj = $store.state.captialConfig.USDT;
            if (_obj) return _obj.withdrawMin;
            return 0;
        });
        const userInfo: ComputedRef<UserInfoVo> = computed(() => $store.state.userInfo);
        const withdrawSub = ref(new WithdrawSubVo());
        const payPassword = ref("");

        const withdraw = ref(new WithdrawVo());
        const tabI = ref(0);
        const showRight = ref(false);
        const onTab = (index: number) => {
            if (tabI.value == index || showRight.value) return;
            tabI.value = index;
        };
        const withdrawSetArr: Ref<WithdrawSetVo[]> = ref([]);
        const withdrawService = computed(() => {
            if (withdrawSetArr.value[tabI.value]) return withdrawSetArr.value[tabI.value].withdrawService;
            return 0;
        });
        let _amount = "";
        const onContent = () => {
            if (!Utils.inputNum(withdrawSub.value.amount, 6)) {
                withdrawSub.value.amount = _amount;
                return;
            }
            const _max = Math.min(balance.value, contentMax.value);
            if (Number(withdrawSub.value.amount) > _max) withdrawSub.value.amount = _max.toString();
            _amount = withdrawSub.value.amount;
        };
        const allSelect = () => {
            if (showRight.value) return;
            withdrawSub.value.amount = balance.value;
            onContent();
        };

        const phoneCodeText: Ref<any> = ref(langObj.value.l0148);
        const onPhoneCode = () => {
            if (phoneCodeText.value >= 0) return;
            phoneCodeText.value = 60;
            const _set = setInterval(() => {
                phoneCodeText.value--;
                console.log(phoneCodeText.value);
                if (phoneCodeText.value <= 0) {
                    clearInterval(_set);
                    phoneCodeText.value = langObj.value.l0148;
                }
            }, 1000);
            getMobile(userInfo.value.phone);
        };

        const mailCodeText: Ref<any> = ref(langObj.value.l0148);
        const onMailCode = () => {
            if (mailCodeText.value >= 0) return;
            mailCodeText.value = 60;
            const _set = setInterval(() => {
                mailCodeText.value--;
                console.log(mailCodeText.value);
                if (mailCodeText.value <= 0) {
                    clearInterval(_set);
                    mailCodeText.value = langObj.value.l0148;
                }
            }, 1000);
            sendMailCodeApi(userInfo.value.mail);
        };

        const Submit1 = () => {
            if (!Utils.walletAddress(withdrawSetArr.value[tabI.value].addrType, withdrawSub.value.addr)) {
                ElMessage.error(langObj.value.l0149);
                return;
            }
            if (Number(withdrawSub.value.amount) < contentMin.value) {
                ElMessage.error(langObj.value.l0124);
                return;
            }
            postUserByDigitalAddr(withdrawSub.value.addr, withdrawSetArr.value[tabI.value].addrType, (data: UserInfoVo) => {
                console.log(data);
                if (data) {
                    if (data.userId == userInfo.value.userId) {
                        ElMessage.error(langObj.value.l0057);
                        return;
                    }
                    const nickName = data.nickName || data.username;
                    ElMessageBox.confirm(langObj.value.l0150, langObj.value.l0029, {
                        confirmButtonText: langObj.value.l0004,
                        cancelButtonText: langObj.value.l0005,
                        callback: (action: string) => {
                            if (action == "confirm") {
                                $router.push({
                                    path: "/my/transfer",
                                    query: { nickName, userId: data.userId, amount: withdrawSub.value.amount },
                                });
                            }
                        },
                    });
                } else {
                    showRight.value = true;
                }
            });
        };

        const onCancel = () => {
            withdrawSub.value.addr = "";
            withdrawSub.value.amount = "";
            withdrawSub.value.payPassword = "";
            withdrawSub.value.googleCode = "";
            withdrawSub.value.mailCode = "";
            withdrawSub.value.phoneCode = "";
            showRight.value = false;
        };
        const Submit2 = () => {
            if (withdraw.value.paypasword == 0 && !payPassword.value) {
                ElMessage.error(langObj.value.l0121);
                return;
            }
            if (withdraw.value.paypasword == 1) payPassword.value = "1";
            if (withdraw.value.phonesmg == 0 && !withdrawSub.value.phoneCode) {
                ElMessage.error(langObj.value.l0140);
                return;
            }
            if (withdraw.value.phonesmg == 1) withdrawSub.value.phoneCode = "1";
            if (withdraw.value.mail == 0 && !withdrawSub.value.mailCode) {
                ElMessage.error(langObj.value.l0151);
                return;
            }
            if (withdraw.value.mail == 1) withdrawSub.value.mailCode = "1";
            if (withdraw.value.google == 0 && !withdrawSub.value.googleCode) {
                ElMessage.error(langObj.value.l0147);
                return;
            }
            if (withdraw.value.google == 1) withdrawSub.value.googleCode = "1";
            withdrawSub.value.merCode = Config.mercode;
            withdrawSub.value.currencyCode = "USDT";
            withdrawSub.value.userId = userInfo.value.userId;
            withdrawSub.value.addrType = withdrawSetArr.value[tabI.value].addrType;
            withdrawSub.value.withdrawService = withdrawService.value;
            withdrawSub.value.payPassword = payPassword.value;
            payPassword.value = "";
            postWithdrawVerification(withdrawSub.value, () => {
                ElMessage.success(langObj.value.l0092);
                getMyBalance();
                onCancel();
            });
        };
        let restaurants: any[] = [];
        const querySearchAsync = (str: string, cb: Function) => {
            const results = str ? restaurants.filter((item: any) => item.value.includes(str)) : restaurants;
            cb(results);
        };
        const handleSelect = (item: any) => {
            withdrawSub.value.addr = item.addr;
        };
        onMounted(() => {
            getWithdrawVerification((data: WithdrawVo) => {
                Utils.updateObj(withdraw.value, data);
            });
            gettWithdrawSetting(tokenObj.levelId, "USDT", (data: WithdrawSetVo[]) => {
                data.forEach((item: WithdrawSetVo) => {
                    if (item.delFlag == "0") withdrawSetArr.value.push(item);
                    postWithdrawContact((data: any) => {
                        const array: any[] = [];
                        data.records.forEach((element: any) => {
                            const obj = withdrawSetArr.value.find((item: any) => item.addrType == element.addrType);
                            if (obj) {
                                array.push({
                                    ...element,
                                    value: element.nickname + "（" + element.addr + "）",
                                });
                            }
                        });
                        restaurants = array;
                    });
                });
            });
        });
        return {
            langObj,
            Utils,
            balance,
            contentMin,
            contentMax,
            userInfo,
            showRight,
            payPassword,
            withdrawSub,
            onCancel,
            Submit1,
            Submit2,
            withdraw,
            tabI,
            onTab,
            withdrawSetArr,
            onContent,
            phoneCodeText,
            onPhoneCode,
            mailCodeText,
            onMailCode,
            allSelect,
            withdrawService,
            querySearchAsync,
            handleSelect,
        };
    },
});


import { useStore } from "vuex";
import Config from "@/assets/ts/config";
import { defineComponent, onMounted, ref, computed } from "vue";
import { imgHeader, savePayment } from "@/assets/http";
import { ElMessage } from "element-plus";
import { getPayName, PaymentEnum } from "@/assets/ts/common";
import Lang from "@/assets/lang";
export default defineComponent({
    props: {},
    emits: ["refreshList"],
    setup(props, context) {
        const langObj = computed(() => Lang.langObj);
        const $store = useStore();
        const user = ref(computed(() => $store.state.userInfo));
        const showAddAlert = ref(false);
        const selectPayType = ref(0);
        const selectPayIndex = ref(0);
        const inputAccount = ref("");
        const inputAccountName = ref("");
        const inputBranch = ref("");
        const inputOpenBranch = ref("");
        const imgUrl = ref("");
        const fileHost = ref(Config.project.host);

        const paymentOptions = ref([
            {
                type: 0,
                title: "",
            },
            // {
            //     type: 1,
            //     title: "",
            // },
            // {
            //     type: 2,
            //     title: "",
            // },
        ]);
        const initMyData = () => {
            selectPayType.value = 0;
            inputAccount.value = "";
            inputAccountName.value = "";
            inputBranch.value = "";
            inputOpenBranch.value = "";
            imgUrl.value = "";
        };

        onMounted(() => {
            paymentOptions.value.forEach(ele => {
                ele.title = getPayName(ele.type);
            });
        });

        const addConfirm = () => {
            if (user.value.userId == 0) {
                ElMessage.error(langObj.value.l0297);
                return;
            }
            if (selectPayType.value == 0) {
                if (!inputAccountName.value || inputAccountName.value.length == 0) {
                    ElMessage.error(langObj.value.l0276);
                    return;
                }

                if (!inputAccount.value || inputAccount.value.length == 0) {
                    ElMessage.error(langObj.value.l0278);
                    return;
                }
                if (!inputBranch.value || inputBranch.value.length == 0) {
                    ElMessage.error(langObj.value.l0289);
                    return;
                }
                const newPayment = {
                    type: selectPayType.value,
                    userId: user.value.userId,
                    accountName: inputAccountName.value,
                    account: inputAccount.value,
                    branch: inputBranch.value,
                    openBranch: inputOpenBranch.value || "",
                };

                savePayment(newPayment, () => {
                    showAddAlert.value = false;
                    ElMessage.success(langObj.value.l0288);

                    initMyData();

                    context.emit("refreshList", 1);
                });
            } else {
                if (!inputAccount.value || inputAccount.value.length == 0) {
                    ElMessage.error(langObj.value.l0286);
                    return;
                }
                if (!inputAccountName.value || inputAccountName.value.length == 0) {
                    ElMessage.error(langObj.value.l0287);
                    return;
                }
                const newPayment = {
                    type: selectPayType.value,
                    userId: user.value.userId,
                    accountName: inputAccountName.value,
                    account: inputAccount.value,
                    paymentUrl: imgUrl.value ? imgUrl.value : "",
                };
                savePayment(newPayment, () => {
                    showAddAlert.value = false;
                    ElMessage.success(langObj.value.l0288);
                    initMyData();
                    context.emit("refreshList", 1);
                });
            }
        };

        const selectPayPop = (type: number) => {
            selectPayType.value = type;
            if (type == undefined) {
                selectPayIndex.value = 0;
            }
            selectPayIndex.value = type;
        };

        const handleAvatarSuccess = (res: any, file: any) => {
            imgUrl.value = res.data.url;
            console.log("上传之后--", imgUrl.value, res);
        };
        const handleAvatarError = (res: any, file: any) => {
            console.log("上传失败", res, file);
            ElMessage.error(langObj.value.l0296);
        };
        const beforeAvatarUpload = (file: any) => {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     ElMessage.error("上传头像图片只能是 JPG 格式!");
            // }
            // if (!isLt2M) {
            //     ElMessage.error("上传头像图片大小不能超过 2MB!");
            // }
            // return isJPG && isLt2M;
            return true;
        };
        const changeCardNumber = (value: any) => {
            if (value.length > 19) {
                inputAccount.value = value.toString().substring(0, 19);
            } else {
                inputAccount.value = value;
            }
        };
        const showAlert = (type: PaymentEnum, item: any) => {
            if (type == PaymentEnum.Add) {
                showAddAlert.value = true;
                selectPayIndex.value = selectPayType.value;
            }
        };

        return {
            langObj,
            showAlert,
            fileHost,
            imgHeader,
            user,
            handleAvatarSuccess,
            beforeAvatarUpload,
            handleAvatarError,
            showAddAlert,
            selectPayIndex,
            paymentOptions,
            selectPayPop,
            addConfirm,
            selectPayType,
            inputAccount,
            inputAccountName,
            inputBranch,
            inputOpenBranch,
            imgUrl,
            changeCardNumber,
            getImgUrl: () => {
                const url = Config.project.fileHost + imgUrl.value;
                return url;
            },
        };
    },
});

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Trade",
        component: () => import("../views/trade/Trade.vue"),
        redirect: "/freeTrade",
        children: [
            {
                path: "/freeTrade",
                name: "FreeTrade",
                component: () => import("../views/trade/FreeTrade.vue"),
                redirect: "/user/userInfo",//默认显示个人中心
                children: [
                    {
                        path: "/freeTrade/freeTradeBuy",
                        name: "FreeTradeBuy",
                        component: () => import("../views/trade/FreeTradeBuy.vue"),
                    },
                    {
                        path: "/freeTrade/freeTradeSell",
                        name: "FreeTradeSell",
                        component: () => import("../views/trade/FreeTradeSell.vue"),
                    },
                    {
                        path: "/freeTrade/freeTradeRecord",
                        name: "FreeTradeRecord",
                        component: () => import("../views/trade/FreeTradeRecord.vue"),
                    },
                ],
            },
            {
                path: "/advertisTrade",
                name: "AdvertisTrade",
                component: () => import("../views/trade/AdvertisTrade.vue"),
            },
            {
                path: "/chatList",
                name: "ChatList",
                component: () => import("../views/trade/ChatList.vue"),
            },
            {
                path: "/advertisAdmin",
                name: "AdvertisAdmin",
                component: () => import("../views/trade/AdvertisAdmin.vue"),
            },
            {
                path: "/advertisPublish",
                name: "AdvertisPublish",
                component: () => import("../views/trade/AdvertisPublish.vue"),
            },
            {
                path: "/merchantInfo",
                name: "MerchantInfo",
                component: () => import("../views/trade/MerchantInfo.vue"),
            },
            {
                path: "/user",
                name: "User",
                component: () => import("../views/user/User.vue"),
                redirect: "/user/userInfo",
                children: [
                    {
                        path: "/user/userInfo",
                        name: "UserInfo",
                        component: () => import("../views/user/UserInfo.vue"),
                    },
                    {
                        path: "/user/securitySet",
                        name: "SecuritySet",
                        component: () => import("../views/user/SecuritySet.vue"),
                    },
                ],
            },
            {
                path: "/my",
                name: "My",
                component: () => import("../views/my/My.vue"),
                redirect: "/my/transfer",//默认显示转账
                children: [
                    {
                        path: "/my/payment",
                        name: "payment",
                        component: () => import("../views/my/Payment.vue"),
                    },
                    {
                        path: "/my/transfer",
                        name: "Transfer",
                        component: () => import("../views/my/Transfer.vue"),
                    },
                    {
                        path: "/my/batchTransfer",
                        name: "batchTransfer",
                        component: () => import("../views/my/batchTransfer.vue"),
                    },
                    {
                        path: "/my/withdraw",
                        name: "Withdraw",
                        component: () => import("../views/my/Withdraw.vue"),
                    },
                    {
                        path: "/my/MyBill",
                        name: "MyBill",
                        component: () => import("../views/my/MyBill.vue"),
                    },
                    {
                        path: "/my/MyBillDetail",
                        name: "MyBillDetail",
                        component: () => import("../views/my/MyBillDetail.vue"),
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login/Login.vue"),
    },
    {
        path: "/home",
        name: "Home",
        component: () => import("../views/Home/Home.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

export default router;


import { computed, defineComponent, ref } from "vue";
import { tokenObj } from "@/assets/http";
import { useStore } from "vuex";
import Lang from "@/assets/lang";
export default defineComponent({
    name: "tradeHeader",
    setup() {
        const langObj = computed(() => Lang.langObj);
        const merchant = ref(tokenObj.merchant);
        const showAuthAlert = ref(false);
        const showAlertTag = ref(0);
        const $store = useStore();
        const gotoShowAuth = (index: number) => {
            showAuthAlert.value = true;
            showAlertTag.value = index;
        };
        const unreadAll = computed(() => $store.state.newsObj.unreadAll);

        return {
            langObj,
            merchant,
            showAuthAlert,
            showAlertTag,
            gotoShowAuth,
            unreadAll,
        };
    },
});

import { MethodEnum, AjaxObj } from "./httpType";
import Utils from "../ts/utils";
import $store from "@/store";
import Config from "../ts/config";
import axios from "axios";
import route from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";

import { onCreate, onClose } from "./webSocket";
import { computed, ref } from "vue";
import WithdrawSubVo from "../vo/WithdrawSubVo";
import TransferSubVo from "../vo/TransferSubVo";
import BatchTransferSubVo from "../vo/batchTransferSubVo";
import UserInfoVo from "../vo/UserInfoVo";
import OrderDetailsVo from "../vo/OrderDetailsVo";
import AdvertVo from "../vo/AdvertVo";
import TokenVo from "../vo/TokenVo";
import Lang from "../lang";
import LocalEnum from "../enum/LocalEnum";
import router from "@/router";

/**登录信息**/
export const tokenObj = new TokenVo();

/**上传文件Header**/
export const imgHeader = computed(() => {
    return {
        Authorization: tokenObj.getToken,
        clientCode: "1",
        "operation-type": "export",
    };
});

/**是否登录**/
export const mainIsLogin = ref(false);
/**退出登录**/
export function signOut() {
    sessionStorage.removeItem(LocalEnum.TOKEN_OBJ);
    route.push({ path: "/login" });
    tokenObj.onClear();
    mainIsLogin.value = false;
    onClose();
}

// 添加请求拦截器
// axios.interceptors.request.use(
//     function(config) {
//         // 在发送请求之前做些什么
//         return config;
//     },
//     function(error) {
//         // 对请求错误做些什么
//         return Promise.reject(error);
//     },
// );

// 添加返回拦截器
axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        return Promise.reject(error.response);
    },
);

/**接口携带信息**/
const paramsObj = { appVer: "0.0.0", reqId: 0, ver: "0.0" };
/**接口请求--直接请求*/
function onAjax(obj: AjaxObj) {
    let _params;
    if (obj.data) _params = { param: obj.data, ...paramsObj, language: Lang.langI == "en" ? 0 : 1 };
    axios({
        url: Config.project.host + obj.url,
        method: obj.method || MethodEnum.POST,
        data: _params || {},
        headers: {
            Authorization: tokenObj.getToken,
        },
    })
        .then(res => {
            if (res.data.code == 0) {
                obj.then && obj.then(res.data.data);
            } else {
                if (!obj.hideCatch) ElMessage.error(Lang.codeHttpObj[res.data.code + ""] || res.data.msg);
                if (res.data.code == 3001) {
                    //您的账号已在其它设备登录
                    signOut();
                    return;
                }
                obj.fail && obj.fail(res.data);
            }
        })
        .catch(function(err) {
            console.log(err);
            if (!err) {
                ElMessage.error(Lang.codeHttpObj.code4);
                return;
            }
            if (err.status == 401) {
                signOut();
                ElMessage.error(Lang.codeHttpObj.code1);
            } else if (err.statusCode == 500 || err.statusCode == 503) {
                ElMessage.error(Lang.codeHttpObj.code2);
            } else if (err.statusCode == 429) {
                ElMessage.error(Lang.codeHttpObj.code3);
            } else if (err.statusCode == 502) {
                ElMessage.error(Lang.codeHttpObj.code4);
                window.location.href = "/maintain.html";
            } else {
                ElMessage.error(Lang.codeHttpObj.code0);
            }
        });
}

let ajaxArr: AjaxObj[] = [];
/**接口请求--登录后**/
function onAjaxToken(obj: AjaxObj) {
    if (mainIsLogin.value) {
        onAjax(obj);
    } else {
        ajaxArr.push(obj);
    }
}

/**执行个人信息后的接口*/
function runAjaxArr() {
    ajaxArr.forEach(element => {
        onAjax(element);
    });
    ajaxArr = [];
}

/**获取用户信息**/
export function getUserInfo(fun?: Function) {
    onAjax({
        url: "/admin/member/" + tokenObj.user_id,
        method: MethodEnum.GET,
        then: (data: UserInfoVo) => {
            $store.commit("updataUserInfo", data);
            tokenObj.update("levelId", data.levelId);
            fun && fun(data);
        },
    });
}

/**用户层级**/
export function getMemberLevel(levelId: string, fun: Function): void {
    onAjax({
        url: "/admin/memberLevel/" + levelId,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

function getMyMemberLevel(fun?: Function): void {
    onAjax({
        url: "/admin/memberLevel/" + tokenObj.levelId,
        method: MethodEnum.GET,
        then: (data: any) => {
            tokenObj.update("merchant", data.merchant);
            fun && fun(data);
        },
    });
}

/**验证登录**/
export function getType(phone: string, fun: Function): void {
    onAjax({
        url: "/admin/member/getType/" + phone,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**获取验证码**/
export function getMobile(phone: string): void {
    onAjax({
        url: "/admin/mobile/" + phone,
        method: MethodEnum.GET,
        then: () => {
            ElMessage.success(Lang.langObj.l0416);
        },
    });
}
/**校验验证码
 * phone: this.userInfo.phone,
	code: this.code
 * **/
export function checkSmsCode(phone: string, code: string, fun: Function): void {
    onAjax({
        url: "/admin/mobile/checkSmsCode?phone=" + phone + "&code=" + code,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**前台发送邮箱验证码*/
export function sendMailCodeApi(mail: string, fun?: Function): void {
    onAjaxToken({
        url: "/admin/mail/" + mail,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun && fun(data);
        },
    });
}
/**前台用户绑定邮箱*/
export function updateEmail(mail: string, code: string, fun: Function): void {
    onAjaxToken({
        url: "/admin/member/bindMail?mail=" + mail + "&code=" + code,
        method: MethodEnum.PUT,
        then: (data: any) => {
            fun(data);
        },
    });
}
/**前台用户开启或者关闭谷歌验证器，0关闭，1开启*/
export function updateGoogleSet(setting: string, code: string, fun: Function): void {
    onAjaxToken({
        url: "/admin/member/googleSetting?setting=" + setting + "&code=" + code,
        method: MethodEnum.PUT,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**校验支付密码*/
export function checkPayPassword(data: any, fun: Function): void {
    onAjaxToken({
        url: "/admin/member/checkPayPassword",
        method: MethodEnum.POST,
        data: data,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**修改用户信息**/
export function updateUserInfo(data: any, fun?: Function) {
    onAjax({
        url: "/admin/member",
        method: MethodEnum.PUT,
        data: data,
        then: (data: any) => {
            fun && fun(data);
        },
    });
}

/**验证语言验证码**/
export function getSendVoiceSms(phone: string) {
    onAjax({
        url: "/admin/mobile/sendVoiceSms?phone=" + phone,
        method: MethodEnum.GET,
        then: () => {
            ElMessage.success(Lang.langObj.l0417);
        },
    });
}

/**获取抵押资产*/
export function inquiryMortgage(userId: string, fun?: Function) {
    onAjaxToken({
        url: "/admin/pledgeRecord/member/" + userId,
        method: MethodEnum.GET,
        then: (data: any) => {
            if (userId == tokenObj.user_id) {
                $store.commit("setMortgageNum", data.amount || 0);
            }
            fun && fun(data);
        },
    });
}

/********************************************普通接口开始****************************************************/
/**全部余额**/
export function getAllBalance(fun: Function) {
    onAjaxToken({
        url: "/admin/wallet/getAllBalance",
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**余额单个**/
export function getBalance(code: string, fun: Function) {
    onAjaxToken({
        url: "/admin/wallet/getBalance/" + code,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**更新自己的余额**/
export function getMyBalance(fun?: Function) {
    getBalance("USDT", (_data: any) => {
        $store.commit("setBalance", _data);
        fun && fun(_data);
    });
}

/**自选交易列表**/
export function postFreeTradeList(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/advertisement/page",
        method: MethodEnum.POST,
        data: data,
        then: (data: AdvertVo[]) => {
            fun(data);
        },
    });
}

/**自选交易下单**/
export function postFreeTradeBuy(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/create",
        method: MethodEnum.POST,
        data: data,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**自选交易历史记录**/
export function postFreeTradeHistory(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/page",
        method: MethodEnum.POST,
        data: data,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**OTC统计**/
export function getOtcData(userId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/member/getOtcData/" + userId,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**商户发布管理列表**/
export function getAdministration(userId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/advertisement/administration/" + userId,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**撤销广告**/
export function resetAdvertise(id: number, fun: Function) {
    onAjaxToken({
        url: "/admin/advertisement/revoke/" + id,
        method: MethodEnum.PUT,
        then: (data: any) => {
            fun(data);
        },
    });
}
/**回收广告**/
export function withdrawalAdvertise(id: number, fun: Function) {
    onAjaxToken({
        url: "/admin/advertisement/withdrawal/" + id,
        method: MethodEnum.POST,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**获取支付方式列表**/
export function getPaymentList(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/payMethod/page",
        data: data,
        method: MethodEnum.POST,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**获取激活的支付方式*/
export function getActivePaymentList(userId: any, fun: Function) {
    onAjaxToken({
        url: "/admin/payMethod/activate?userId=" + userId,
        method: MethodEnum.GET,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**添加支付方式*/
export function savePayment(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/payMethod/save",
        data: data,
        method: MethodEnum.POST,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**修改支付方式*/
export function updatePayment(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/payMethod/update",
        data: data,
        method: MethodEnum.PUT,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**创建广告**/
export function createAdvertisment(data: any, fun: Function) {
    data.userId = tokenObj.user_id;
    onAjaxToken({
        url: "/admin/advertisement/create",
        data: data,
        method: MethodEnum.POST,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**商户获取自己广告下的交易订单**/
export function getOrderRecord(data: any, fun: Function) {
    data.userId = tokenObj.user_id;
    onAjaxToken({
        url: "/admin/advertisement/orderRecord",
        data: data,
        method: MethodEnum.POST,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**商户获取自己广告下的交易订单**/
export function getOrderDetail(orderId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/" + orderId,
        method: MethodEnum.GET,
        then: (data: OrderDetailsVo) => {
            fun(data);
        },
    });
}
export function getRecords(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/wallet/getRecords",
        data: data,
        method: MethodEnum.POST,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**
 * 用户币种订单收支统计"
 *  "currencyCode": "string",必须
    "endTime": "2020-09-27T12:04:58.295Z",
    "max": 0,
    "min": 0,
    "startTime": "2020-09-27T12:04:58.295Z",
    "type": 0,
    "userId": 0
 * 
*/
export function currencyOrderStatistics(data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/wallet/currencyOrderStatistics",
        data: data,
        method: MethodEnum.POST,
        then: (data: any[]) => {
            fun(data);
        },
    });
}
/***获取订单详情 id, type
 * 0 转账 1 收付款  2兑汇  3 提币  4 充币 5抵押 6otc广告 ；7otc订单 8 提现委托 9充值 10 后台扣款（不会出现在客户端） 11 消费 12商户转账模块
 */
export function getBillRecordDetailByType(id: string, type: number, fun: Function) {
    let url = "";
    switch (type) {
        case 0:
            url = "/admin/transfer/";
            break;
        case 1:
            url = "/admin/transfer/";
            break;
        case 2:
            url = "/admin/exchange/";
            break;
        case 3:
            url = "/admin/withdrawRecords/";
            break;
        case 4:
            url = "/admin/rechargeRecords/";
            break;
        case 5:
            url = "/admin/pledgeRecord/details/";
            break;
        case 6:
            url = "/admin/advertisement/details/";
            break;
        case 7:
            url = "/admin/orderRecords/";
            break;
        case 8:
            url = "/admin/withdrawEntrustRecords/details/";
            break;
        case 9:
            url = "/mag/adminRechargeRecords/app/getRechargeInfo/";
            break;
        case 11:
            url = "/admin/merchantOrder/getRecordById/";
            break;
        case 12:
            url = "/admin/merchantTransfer/getTransferRecordDetail/";
            break;
        default: {
            break;
        }
    }
    onAjaxToken({
        url: url + id,
        method: MethodEnum.GET,
        then: (data: any[]) => {
            fun(data);
        },
    });
}

/**获取激活的收款方式**/
export function getPayMethod(userId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/payMethod/activate?userId=" + userId,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**取消订单**/
export function putCancelOrder(orderId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/cancel/" + orderId,
        method: MethodEnum.PUT,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**确认收到付款，订单放行**/
export function putReleaseOrder(orderId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/permit/" + orderId,
        method: MethodEnum.PUT,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**
 * 付款完成
 *  @param {string} orderId 订单id
 *  @param {string} payMethodId 支付id
 *  @param {Function} fun 回调函数
 */
export function putPaymentOrder(orderId: string, payMethodId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/pay?id=" + orderId + "&paymentMethodId=" + payMethodId,
        method: MethodEnum.PUT,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**获取订单最新的申诉日志**/
export function postAppealOrder(_data: any, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/appeal",
        method: MethodEnum.POST,
        data: _data,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**获取订单最新的申诉日志**/
export function getNewAppealLog(orderId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/orderAppealLog/newAppealLog/" + orderId,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**获取订单最新的申诉日志**/
export function putCancelAppeal(orderId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/orderRecords/unAppeal/" + orderId,
        method: MethodEnum.PUT,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**查看他人用户信息**/
export function getHeUserInfo(userId: string, fun: Function) {
    onAjaxToken({
        url: "/admin/member/" + userId,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**历史聊天记录**/
export function getMessage(orderId: string, fun: Function) {
    onAjaxToken({
        url: "/im/message/" + orderId,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**
 * 提币设置（服务费）
 * @param {number} levelId 用户层级id
 * @param {string} currencyCode 币种
 */
export function gettWithdrawSetting(levelId: number, currencyCode: string, fun: Function) {
    onAjaxToken({
        url: "/admin/digitalSetting/getSetting?levelId=" + levelId + "&currencyCode=" + currencyCode,
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**获取提币验证配置*/
export function getWithdrawVerification(fun: Function) {
    onAjaxToken({
        url: "/admin/config/getWithdrawVerification",
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**获取提币验证配置*/
export function postWithdrawVerification(data: WithdrawSubVo, fun: Function) {
    onAjaxToken({
        url: "/admin/withdrawRecords/create",
        data: data,
        method: MethodEnum.POST,
        then: (data: any) => {
            fun(data);
        },
    });
}
/**货币类型*/
export function getCurrencyType(fun: Function) {
    onAjaxToken({
        url: "/admin/digitalAddr/getCurrencyType",
        method: MethodEnum.GET,
        then: (data: any) => {
            fun(data);
        },
    });
}

/**
 * 根据用户角色获取对应的配置信息
 * @param {number} levelId 用户层级id
 */
export function getSettingByRole(levelId: number, fun?: Function) {
    onAjaxToken({
        url: "/admin/setting/getSettingByRole/" + levelId,
        method: MethodEnum.GET,
        then: (data: any) => {
            $store.commit("setCaptialConfig", data);
            fun && fun(data);
        },
    });
}

/**
 * 转账
 * @param {TransferSubVo} data 转账信息
 */
export function postTransfer(data: TransferSubVo, fun?: Function) {
    onAjaxToken({
        url: "/admin/wallet/transfer",
        method: MethodEnum.POST,
        data: data,
        then: (data: any) => {
            fun && fun(data);
        },
    });
}

/**
 * 批量转账
 * @param {BatchTransferSubVo} data 转账信息
 */
 export function batchTransfer(data: BatchTransferSubVo, fun?: Function) {
    onAjaxToken({
        url: "/admin/wallet/batchTransfer",
        method: MethodEnum.POST,
        data: data,
        then: (data: any) => {
            fun && fun(data);
        },
    });
}


/**
 * 查询他人信息
 * @param {number} type 0 id 1 手机号
 * @param {string} param 对应的查询信息
 */
export function queryUser(type: number, param: string, fun: Function) {
    onAjaxToken({
        url: "/admin/member/queryUser",
        method: MethodEnum.POST,
        data: { type, param },
        then: (data: any) => {
            fun && fun(data);
        },
    });
}

/**
 * 根据数字货币地址信息查询用户id，以及链路信息，为空则说明为系统外部的充币地址
 * @param {string} addr 链路地址
 * @param {string} addrtype 链路地址类型
 */
export function postUserByDigitalAddr(addr: string, addrtype: string, fun: Function) {
    onAjaxToken({
        url: "/admin/digitalAddr/getUserByDigitalAddr",
        method: MethodEnum.POST,
        data: { addr, addrtype },
        then: (data: any) => {
            fun && fun(data);
        },
    });
}

/**
 * 获取自定义提币地址列表
 */
export function postWithdrawContact(fun: Function) {
    onAjaxToken({
        url: "/admin/withdrawContact/page",
        method: MethodEnum.POST,
        data: { size: 1000, current: 1 },
        then: (data: any) => {
            fun && fun(data);
        },
    });
}

/********************************************普通接口结束****************************************************/

let _differenceTime = 0;

function getTime() {
    onAjax({
        url: "/admin/setting/getTime",
        method: MethodEnum.GET,
        then: (data: number) => {
            _differenceTime = new Date().getTime() - data || 0;
        },
    });
}

export function httpTime() {
    return new Date().getTime() - _differenceTime;
}

/**密码登录或者token成功*/
function onSuccess(data: any) {
    Utils.updateObj(tokenObj, data);
    getMyBalance();
    inquiryMortgage(data.user_id);
    onCreate();
    getUserInfo((data: UserInfoVo) => {
        const routerName = router.currentRoute.value.name;
        if (routerName == "Login") router.push({ path: "/" });
        getSettingByRole(data.levelId);
        mainIsLogin.value = true;
        /**判断是否要设置登录密码*/
        if (data.type == 0) {
            ElMessageBox.prompt("", Lang.langObj.l0221, {
                confirmButtonText: Lang.langObj.l0004,
                inputPattern: /^[0-9a-zA-Z]{6,12}$/,
                inputErrorMessage: Lang.langObj.l0219,
                inputType: "password",
                inputPlaceholder: Lang.langObj.l0239,
                showClose: false,
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
            }).then(({ value }) => {
                const data1 = {
                    password: value,
                    userId: tokenObj.user_id,
                };
                updateUserInfo(data1, () => {
                    $store.commit("updataUserInfo", { type: 1 });
                    ElMessage.success(Lang.langObj.l0207);
                });
            });
        }
        runAjaxArr();
    });
    getMyMemberLevel();
}

/**登录**/
export function postLogin(data: any, fun?: Function) {
    onAjax({
        url: "/admin/member/login",
        method: MethodEnum.POST,
        data: data,
        then: (data: any) => {
            onSuccess(data);
            sessionStorage.setItem(LocalEnum.TOKEN_OBJ, JSON.stringify(data));
            route.push({ path: "/" });
            fun && fun(data);
        },
    });
}

/**初始化**/
export function init() {
    getTime();
    const _tokenObj = sessionStorage.getItem(LocalEnum.TOKEN_OBJ);
    if (_tokenObj) onSuccess(JSON.parse(_tokenObj));
    else route.push({ path: "/login" });
}

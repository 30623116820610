
import Config from "@/assets/ts/config";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Footer",
    setup() {
        return {
            Config,
        };
    },
});

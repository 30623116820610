import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["infinite-scroll-disabled"]
const _hoisted_2 = {
  key: 0,
  class: "loadEnd"
}
const _hoisted_3 = {
  key: 1,
  class: "loadEnd"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Wares = _resolveComponent("Wares")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_PlaceOrder = _resolveComponent("PlaceOrder")!
  const _component_OrderDetails = _resolveComponent("OrderDetails")!
  const _component_OrderChat = _resolveComponent("OrderChat")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createBlock(_component_el_row, {
    class: "freeTradeBuy",
    gutter: 20
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", {
            class: "advertsItems scroll marT10",
            "infinite-scroll-disabled": _ctx.isLoadEnd
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsArr, (item, index) => {
              return (_openBlock(), _createBlock(_component_Wares, {
                key: item.id,
                class: _normalizeClass({ active: _ctx.itemBoxI == index }),
                onClick: ($event: any) => (_ctx.onItemBox(item, index)),
                goodsArr: _ctx.goodsArr,
                goods: item
              }, null, 8, ["class", "onClick", "goodsArr", "goods"]))
            }), 128)),
            (_ctx.isLoadStart)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.langObj.l0053), 1))
              : (!_ctx.isLoadStart && _ctx.isLoadEnd)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.langObj.l0054), 1))
                : _createCommentVNode("", true)
          ], 8, _hoisted_1), [
            [_directive_infinite_scroll, _ctx.onFreeTradeList]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_PlaceOrder, {
            ref: "placeOrder",
            onOnHide: _ctx.placeOrderHide,
            onShowOrderDetail: _ctx.showOrderDetail
          }, null, 8, ["onOnHide", "onShowOrderDetail"]),
          _createVNode(_component_OrderDetails, { ref: "orderDetails" }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_OrderChat, { ref: "orderChat" }, null, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
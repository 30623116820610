/**
 * 提币提交参数
 * */
 export default class WithdrawSubVo {
    /**地址*/
    addr = "";
    /**钱包链路*/
    addrType = "";
    /**提币金额*/
    amount = "";
    /**数字货币种类*/
    currencyCode = "";
    /**商户代码*/
    merCode = "";
    /**用户id*/
    userId = "";
    /**提币服务费*/
    withdrawService = 0;
    /**用户支付密码*/
    payPassword = "";
    /**谷歌验证码*/
    googleCode = "";
    /**邮箱验证码*/
    mailCode = "";
    /**手机验证码*/
    phoneCode = "";
}

import { zh } from "./private";
const index: any = {
    ...zh,
    l0001: "退出",
    l0002: "登录",
    l0003: "是否退出当前账户？",
    l0004: "确定",
    l0005: "取消",
    l0006: "单价",
    l0007: "全部",
    l0008: "限额",
    l0009: "交易数量",
    l0010: "交易金额",
    l0011: "{1}秒后自动取消",
    l0012: "请核对订单信息、总价、数量与收款方式，并自行转账 给对方。",
    l0013: "完成转账后请保留付款截图等凭证，发送至订单聊天中， 并及时操作【我已完成付款】。",
    l0014: "您的账户余额为0，不能出售",
    l0015: "请输入金额",
    l0016: "请输入数量",
    l0017: "购买",
    l0018: "出售",
    l0019: "金额不能为0",
    l0020: "您购买的金额或数量小于最小限额",
    l0021: "按金额",
    l0022: "按数量",
    l0023: "自选交易",
    l0024: "广告交易",
    l0025: "聊天记录",
    l0026: "广告管理",
    l0027: "个人中心",
    l0028: "资产",
    l0029: "温馨提示",
    l0030: "知道了",
    l0031: "您没有足够的权限进行广告交易。",
    l0032: "您需要申请并获得广告发布权限。",
    l0033: "数量",
    l0034: "收款方式",
    l0035: "我的个人信息",
    l0036: "备注",
    l0037: "转账",
    l0038: "提币",
    l0039: "账单",
    l0040: "我的账单",
    l0041: "时间",
    l0042: "开始日期",
    l0043: "至",
    l0044: "结束日期",
    l0045: "金额",
    l0046: "最小金额",
    l0047: "最大金额",
    l0048: "账单类型",
    l0049: "货币类型",
    l0050: "支出",
    l0051: "收入",
    l0052: "重置",
    l0053: "加载中",
    l0054: "暂无更多",
    l0055: "没有一条数据哦...",
    l0056: "收付款",
    l0057: "您不能提币到自己的账户",
    l0058: "充币",
    l0059: "抵押",
    l0060: "广告",
    l0061: "交易",
    l0062: "充值",
    l0063: "消费",
    l0064: "入款",
    l0065: "开始时间不能为空",
    l0066: "结束时间不能为空",
    l0067: "兑汇",
    l0068: "提现委托",
    l0069: "后台扣款",
    l0070: "最大限额不能低于最小限额",
    l0071: "订单详情",
    l0072: "账单号",
    l0073: "对方账户",
    l0074: "用户",
    l0075: "服务费",
    l0076: "到账金额",
    l0077: "商户名称",
    l0078: "商户号",
    l0079: "入款汇率",
    l0080: "商品信息",
    l0081: "兑入货币",
    l0082: "兑入金额",
    l0083: "兑出货币",
    l0084: "汇率",
    l0085: "创建时间",
    l0086: "账单分类",
    l0087: "转入",
    l0088: "转出",
    l0089: "复制失败",
    l0090: "收款",
    l0091: "支付",
    l0092: "提币成功",
    l0093: "提币失败",
    l0094: "提币确认中",
    l0095: "撤回抵押",
    l0096: "添加抵押",
    l0097: "广告撤销",
    l0098: "广告发布",
    l0099: "交易取消",
    l0100: "请务必使用您本人的真实收款账户，被激活的收款方式将在交易时向对方展示，并且会保存为申诉依据。",
    l0101: "添加收款方式",
    l0102: "激活",
    l0103: "关闭",
    l0104: "删除",
    l0105: "支付方式吗",
    l0106: "我要转账",
    l0107: "转账至",
    l0108: "手机号码",
    l0109: "请选择",
    l0110: "可用余额",
    l0111: "下一步",
    l0112: "转账确认",
    l0113: "转账数量",
    l0114: "收款方昵称",
    l0115: "收款方",
    l0116: "添加备注(选填)",
    l0117: "请输入转账备注...",
    l0118: "未设置支付密码，请前往",
    l0119: "设置",
    l0120: "支付密码",
    l0121: "请输入支付密码",
    l0122: "请输入用户",
    l0123: "不能转账给自己",
    l0124: "数量小于限额",
    l0125: "未查询到此用户",
    l0126: "转账成功",
    l0127: "请输入手机号",
    l0128: "认证商户",
    l0129: "注册手机",
    l0130: "注册时间",
    l0131: "更新成功",
    l0132: "我要提出",
    l0133: "链名称",
    l0134: "提币地址",
    l0135: "请输入提币地址",
    l0136: "到账数",
    l0137: "若提币超过 51 USDT，为保障账户资产安全需延迟一天到账",
    l0138: "安全验证",
    l0139: "提币数量",
    l0140: "请输入手机验证码",
    l0141: "未绑定邮箱，请前往",
    l0142: "绑定",
    l0143: "若未收到邮件，请检查垃圾箱，若遗忘安全项请联系客服。",
    l0144: "未开启谷歌身份验证，请前往",
    l0145: "认证",
    l0146: "谷歌验证码",
    l0147: "请输入谷歌验证码",
    l0148: "获取验证码",
    l0149: "提币地址错误",
    l0150: "检测到您的目标地址为海豚钱包用户，将享用快速转账通道，即时到账，免服务费。",
    l0151: "请输入邮箱验证码",
    l0152: "我的OTC统计",
    l0153: "总成单",
    l0154: "完成率",
    l0155: "30日成单",
    l0156: "平均放行",
    l0157: "抵押资产",
    l0158: "发布广告",
    l0159: "在线出售",
    l0160: "撤销",
    l0161: "回收资产",
    l0162: "克隆",
    l0163: "在线购买",
    l0164: "撤销广告",
    l0165:
        "确认后，广告将即时不可交易，并在15分钟后完成撤销，并回收剩余资产至可用余额，若届时有进行中的交易则需要手动回收资产。正在进行中的交易不受撤销影响。",
    l0166: "您需要添加并激活至少一个有效的收款方式。",
    l0167: "剩余时间",
    l0168: "撤销中",
    l0169: "等待回收",
    l0170: "总价",
    l0171: "订单号",
    l0172: "订单时间",
    l0173: "申诉",
    l0174: "取消申诉",
    l0175: "我已确认收到付款",
    l0176: "取消订单",
    l0177: "申诉理由",
    l0178: "交易提醒",
    l0179: "提起诉讼后资产将会冻结，重组专员将介入本次交易，直至重组结束。恶意篡改属于扰乱正常交易等级的行为，情节严重将冻结账户。",
    l0180: "提交申诉",
    l0181: "交易记录",
    l0182: "收款人",
    l0183: "开户行",
    l0184: "开户支行",
    l0185: "收款二维码",
    l0186: "恶意点击确认，会影响您的行为记录",
    l0187: "确认已付款",
    l0188: "我没有收到买家的付款",
    l0189: "买家少转了钱",
    l0190: "其他",
    l0191: "我已完成付款，卖家未及时放行",
    l0192: "我向卖家多转了钱",
    l0193: "等待买家付款",
    l0194: "确认放行",
    l0195: "交易完成",
    l0196: "没有要复制的内容",
    l0197: "请付款",
    l0198: "等待卖家确认",
    l0199: "若买家长时间未付款，建议主动联系对方",
    l0200: "请输入6-12位数字或字母的登录密码",
    l0201: "登录海豚钱包需要的密码",
    l0202: "在发生账户资金变动时需要的密码",
    l0203: "请再次输入新的登录密码",
    l0204: "新登录密码",
    l0205: "原登录密码",
    l0206: "修改登录密码",
    l0207: "修改登录密码成功",
    l0208: "新密码两次输入不一致",
    l0209: "新密码格式不正确",
    l0210: "修改支付密码成功",
    l0211: "新支付密码格式不正确",
    l0212: "请输入6位手机验证码",
    l0213: "原密码格式不正确",
    l0214: "设置支付密码成功",
    l0215: "两次输入不一致",
    l0216: "支付密码格式不正确",
    l0217: "修改成功",
    l0218: "设置登录密码成功",
    l0219: "登录密码格式不正确",
    l0220: "修改/忘记",
    l0221: "设置登录密码",
    l0222: "确认登录密码",
    l0223: "请输入原密码",
    l0224: "忘记登录密码",
    l0225: "忘记支付密码",
    l0226: "修改/忘记支付密码",
    l0227: "原支付密码",
    l0228: "请输入原支付密码",
    l0229: "新支付密码",
    l0230: "请输入6位数字的新密码",
    l0231: "确认新密码",
    l0232: "请输入新密码",
    l0233: "请再次输入新密码",
    l0234: "请再次输入新的支付密码",
    l0235: "设置支付密码",
    l0236: "确认支付密码",
    l0237: "联系客服",
    l0238: "忘记密码",
    l0239: "登录密码",
    l0240: "新密码",
    l0241: "上一步",
    l0242: "完成",
    l0243: "修改邮箱",
    l0244: "为保护您的账户资产安全，修改需要联系客服，提供您的收款银行卡、最近3次交易收付款截图等证明",
    l0245: "发送",
    l0246: "系统将向您的注册手机号{X}发送验证码以验证身份",
    l0247: "关闭谷歌验证",
    l0248: "确认谷歌验证码",
    l0249: "开启谷歌验证",
    l0250: "您可将秘钥填入至谷歌身份验证，或扫描二维码添加绑定",
    l0251: "您确定要关闭谷歌验证吗？",
    l0252: "请发送安全验证邮件至{X}，收到邮件后填入邮箱验证码",
    l0253: "绑定邮箱后若要修改，请联系客服",
    l0254: "绑定邮箱",
    l0255: "未绑定邮箱",
    l0256: "谷歌身份验证",
    l0257: "更新昵称",
    l0258: "请输入昵称",
    l0259: "修改注册手机号",
    l0260: "请输入邮箱",
    l0261: "绑定手机号",
    l0262: "昵称",
    l0263: "修改",
    l0264: "复制",
    l0265: "已开启",
    l0266: "未开启",
    l0267: "邮箱格式不正确",
    l0268: "邮箱验证码格式不正确",
    l0269: "请输入正确的谷歌验证码",
    l0270: "发送邮箱验证码成功",
    l0271: "复制成功",
    l0272: "基本资料",
    l0273: "安全设置",
    l0274: "请选择收款方式",
    l0275: "持卡人姓名",
    l0276: "请输入持卡人姓名",
    l0277: "银行卡号",
    l0278: "请输入银行卡号",
    l0279: "请输入银行名称",
    l0280: "开户银行",
    l0281: "开户地址",
    l0282: "请输入开户地址或支行名称",
    l0283: "请输入姓名",
    l0284: "姓名",
    l0285: "账户",
    l0286: "请输入账户",
    l0287: "请输入账户名",
    l0288: "添加成功",
    l0289: "请输入开户行",
    l0290: "请输入账号",
    l0291: "请输入正确格式的邮箱",
    l0292: "请输入正确格式的IFSC",
    l0293: "请输入正确格式的电话",
    l0294: "收款码图片",
    l0295: "请输入纯数字账户",
    l0296: "上传失败",
    l0297: "用户id不存在",
    l0298: "请输入PayTM账号",
    l0299: "我要购买",
    l0300: "我要出售",
    l0301: "发布出售广告将冻结出售总数量的对应资产，交易成功将从该冻结资产中划转。",
    l0302: "发布购买广告",
    l0303: "发布出售广告",
    l0304: "同时撤销被克隆广告",
    l0305: "广告留言",
    l0306: "添加图片信息",
    l0307: "若留言内容不为空，在创建订单后，会自动发送广告留言消息给对方。",
    l0308: "清空",
    l0309: "时效",
    l0310: "请填入时效",
    l0311: "最大",
    l0312: "最高限额",
    l0313: "最低限额不小于1",
    l0314: "订单限额",
    l0315: "请填入单价",
    l0316: "出售数量",
    l0317: "购买数量",
    l0318: "请填入数量",
    l0319: "剩余",
    l0320: "小时",
    l0321: "未激活",
    l0322: "您的OTC交易已被永久限制",
    l0323: "您的OTC交易已被受限至",
    l0324: "对方需要您添加并激活相应的收款方式",
    l0325: "最少选择一种支付方式",
    l0326: "广告时效不能超过{X}小时",
    l0327: "广告时效最小为1",
    l0328: "最高限额不能小于最小限额",
    l0329: "最低限额最小为1",
    l0330: "最大限额最小为1",
    l0331: "价格必须大于0",
    l0332: "数量不能为超过最大余额",
    l0333: "数量必须大于0",
    l0334: "没有匹配项",
    l0335: "请输入密码",
    l0336: "手机号有误",
    l0337: "请输入验证码",
    l0338: "接听语音",
    l0339: "使用验证码登录",
    l0340: "使用密码登录",
    l0341: "次",
    l0342: "买家已付款，请确认放行",
    l0343: "交易已经完成",
    l0344: "交易已经取消",
    l0345: "申诉中",
    l0346: "请您尽快付款",
    l0347: "您已付款，等待卖家确认",
    l0348: "申诉口令",
    l0349: "支付宝",
    l0350: "微信",
    l0351: "银行卡",
    l0352: "申诉成功",
    l0353: "确认您已经收到了全额付款",
    l0354: "确定收到",
    l0355: "我再看看",
    l0356: "订单数量",
    l0357: "订单放行验证支付密码",
    l0358: "放行成功",
    l0359: "取消申诉后，您的订单状态将恢复至等待放行状态。请勿无理由的频繁申诉及取消申诉，这些行为会被记录，并影响您的信用。",
    l0360: "确定取消",
    l0361: "我再想想",
    l0362: "取消成功",
    l0363: "确认取消订单？",
    l0364: "确认成功",
    l0365: "确认付款",
    l0366: "请输入内容",
    l0367: "交易详情",
    l0368: "您已成功下单，请及时支付。",
    l0369: "对方已成功下单，正在支付。",
    l0370: "您已将订单标记为【已付款】状态，请等待对方确认收款和放行。",
    l0371: "对方已支付，{1}已标记向您{2}【{3}】成功付款{4}{5}，请及时查收并尽快放行。",
    l0372: "对方已确认收到您的付款，您所购买的{1}已发放到您的账户，请查收。",
    l0373: "您已将订单标记为放行，对方将收到您出售的{1}。",
    l0374: "您已发起申诉，等待客服介入处理，您也可以取消申诉。",
    l0375: "对方已发起申诉，等待客服介入处理。",
    l0376: "订单已取消。如有疑问，请联系客服。",
    l0377: "对方已将订单取消。如有疑问，请联系客服。",
    l0378: "请尽快核对收款信息完成支付，注意保留付款凭据或截图。",
    l0379: "若对方长时间未付款，建议积极与对方沟通。",
    l0380: "您已取消申诉",
    l0381: "对方已取消申诉，交易恢复正常。",
    l0382: "请确认已收到对方付款，并尽快放行。",
    l0383: "请确认已付款，并提醒对方放行。",
    l0384: "收不到短信验证码怎么办？",
    l0385: "分钟",
    l0386: "普通商户",
    l0387: "图片消息",
    l0388: "系统消息",
    l0389: "交易中",
    l0390: "已完成",
    l0391: "已取消",
    l0392: "等待付款",
    l0393: "等待放行",
    l0394: "不能和自己交易",
    l0395: "未注册的手机号通过验证后将自动注册",
    l0396: "重新输入手机号",
    l0397: "将向您的手机拨号，以便通知语音验证码？",
    l0398: "确定接听么？",
    l0399: "用户信息",
    l0400: "您还没有设置支付密码，是否前往个人中心设置支付密码？",
    l0401: "您有新的【{1}USDT】订单，订单号{2}，请尽快处理。",
    l0402: "订单号为{1}的【{2}USDT】的订单已完成付款。",
    l0403: "订单号为{1}的【{2}USDT】的订单已放行。",
    l0404: "订单号为{1}的【{2}USDT】的订单正在申诉。等待客服处理",
    l0405: "订单号为{1}的【{2}USDT】的订单已取消。",
    l0406: "您有【{1}USDT】订单，订单号{2}，未支付，请尽快处理。",
    l0407: "提币申请已完成，金额 {1} {2}，详细请查看账单。",
    l0408: "您有新的【{1}USDT】订单，订单号{2}，请尽快放行。",
    l0409: "您的出售{1}广告已完成撤销，剩余{2}{3}已成功退还，详细请查看账单。",
    l0410: "收款入账",
    l0411: "收到转账",
    l0412: "兑汇入账",
    l0413: "充值入账",
    l0414: "到账入款",
    l0415: "{1} {2} {3}，详细请查看账单。",
    l0416: "发送成功",
    l0417: "请求成功",
    l0418: "PayTM",
    l0419: "Bank Card",
    l0420: "UPI",
    l0421: "IMPS",
    l0422: "Google Pay",
    l0423: "批量转账",
    l0424: "导入excel",
    l0425: "上传成功!",
    l0426: "请删除上传失败的文件，修改后重新上传",
    l0427: "事例：",
    l0428: "立即上传",
    l0429: "只能上传后缀是.xlsx或.xls的文件",
    l0430: "用户ID",
    l0431: "数量",
    l0432: "地址",
    l0433: "将文件拖到此处，或",
    l0434: "只能上传xlsx、xls文件",
    l0435: "一次只能上传一个!",
    l0436: "请上传文件",
    l0437: "昵称不能少于6位数",
    l0438: "您购买的金额或数量大于最大限额",
    l0439: "海豚钱包",
    l0440: "手机充值",
    l0441: "线下交易",

};
export default index;

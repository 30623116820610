
import { defineComponent, ref, onMounted, Ref, provide, computed } from "vue";
import { getOtcData, getAdministration, getHeUserInfo, getMemberLevel, inquiryMortgage } from "@/assets/http";
import Utils from "@/assets/ts/utils";
import paymentView from "./paymentView.vue";
import router from "../../router";
import Config from "@/assets/ts/config";
import Lang from "@/assets/lang";
export default defineComponent({
    name: "MerchantInfo",
    components: { paymentView },
    props: {
        userId: {
            type: Number,
            default: 0,
        },
    },
    setup() {
        const langObj = computed(() => Lang.langObj);
        provide("paymethodId", "");
        const sellList: Ref<any[]> = ref([]);
        const buyList: Ref<any[]> = ref([]);
        const merchant = ref("0");
        const mortgageNum = ref(0);
        const pageName: any = ref("");
        const user = ref({
            userId: 0,
            username: "",
            nickName: "",
            currencyCode: "USDT",
            balance: 0,
            phone: "",
            registTime: 0,
        });
        const otcData = ref({
            success: 0,
            successRatio: 0,
            successByMonth: 0,
            avgPermitTime: 0,
        });
        const selectItem = ref({
            id: 0,
        });

        const requestList = (userId: string) => {
            getAdministration(userId, (data: any[]) => {
                buyList.value = [];
                sellList.value = [];
                data.forEach((item: any) => {
                    if (item.type == 0) sellList.value.push(item);
                    else buyList.value.push(item);
                });
            });
        };

        onMounted(() => {
            const _userId: any = router.currentRoute.value.query.userId || "";
            pageName.value = router.currentRoute.value.query.pageName || "";
            getHeUserInfo(_userId, (res: any) => {
                user.value = res;

                getOtcData(res.userId, (data: any) => {
                    Utils.updateObj(otcData.value, data);

                    getMemberLevel(res.levelId, (res: any) => {
                        merchant.value = res.merchant;
                    });
                });
                inquiryMortgage(res.userId, (data: any) => {
                    console.log(data);
                    mortgageNum.value = data.amount;
                });
                requestList(_userId);
            });
        });

        const gotoBack = () => {
            router.back();
        };
        const copyAction = () => {
            Utils.clipboard({ text: user.value.userId.toString() });
        };
        return {
            langObj,
            Config,
            requestList,
            sellList,
            buyList,
            copyAction,
            user,
            pageName,
            otcData,
            selectItem,
            gotoBack,
            merchant,
            formatDateTime: (time: number) => {
                return Utils.formatDateYMD(time);
            },
            getCountdown: (time: number) => {
                return Utils.getCountdown(time, true);
            },
            mortgageNum,
        };
    },
});

import { computed } from "vue";
import Lang from "../lang";

/**项目参数*/
interface Project {
    /**项目名称*/
    name: string;
    /** 项目title */
    title: string,
    /**接口地址*/
    host: string;
    /**ajax接口地址*/
    wsHost: string;
    /**资源服务器*/
    fileHost: string;
    /**官网地址*/
    website: string;
    /**客服地址*/
    server: string;
}

/**货币*/
interface Currency {
    /**区号*/
    areaCode: number | string;
    /**货币*/
    code: string;
    /**货币符号*/
    symbol: string;
    /**付款方式*/
    paymentArr: any;
}

/**项目配置*/
const _projectArr: Array<Project> = [
    {
        name: "haitunjinfu",
        title:'海豚金服',
        host: "http://192.168.1.241:7888",
        wsHost: "ws://192.168.1.241:7888/im/websocket",
        fileHost: "https://image.origowallet.me",
        website: "https://website.origowallet.info/#/",
        server: "https://direct.lc.chat/12335076/",
    },
    {
        name: "haitunjinfu",
        title:'海豚金服',
        host: "https://phone.origowallet.me",
        wsHost: "wss://phone.origowallet.me/im/websocket",
        fileHost: "https://image.origowallet.me",
        website: "https://website.origowallet.info/#/",
        server: "https://direct.lc.chat/12335076/",
    },
    {
        name: "haitunjinfu",
        title: Lang.langObj.l0439,
        host: "https://dhapi.irukagroup.cc",
        wsHost: "wss://dhapi.irukagroup.cc/im/websocket",
        fileHost: "https://image.origo-wallet.info",
        website: "https://website.origo-wallet.info/pc/index.html",
        server: "https://direct.lc.chat/12335076/",
    },
    {
        name: "origo",
        title:'海豚金服',
        host: "https://phone.origowallet.info",
        wsHost: "wss://phone.origowallet.info/im/websocket",
        fileHost: "https://image.origowallet.net",
        website: "https://website.origowallet.net/#/",
        server: "https://direct.lc.chat/12449559/",
    },
    {
        name: "918-pay",
        title:'海豚金服',
        host: "https://phone.918-pay.com",
        wsHost: "wss://phone.918-pay.com/im/websocket",
        fileHost: "https://image.918-pay.com",
        website: "https://website.918-pay.com/#/",
        server: "https://direct.lc.chat/12449559/",
    },
];

/**使用的货币数组*/
const _currencyArr: Array<Currency> = [
    {
        areaCode: "971",
        code: "SGD",
        symbol: "$",
        paymentArr: computed(() => [
            // { name: Lang.langObj.l0349, type: 2 },
            // { name: Lang.langObj.l0350, type: 1 },
            { name: Lang.langObj.l0351, type: 0 },
        ]),
    },
    {
        areaCode: "91",
        code: "INR",
        symbol: "₹",
        paymentArr: computed(() => [
            { name: Lang.langObj.l0418, type: 3 },
            { name: Lang.langObj.l0419, type: 4 },
            { name: Lang.langObj.l0420, type: 5 },
            { name: Lang.langObj.l0421, type: 6 },
            { name: Lang.langObj.l0422, type: 7 },
        ]),
    },
];
export default class Config {
    /**
     * 项目环境 调试使用
     * @param {number} 0 开发
     * @param {number} 1 测试
     * @param {number} 2 origo版
     * @param {number} 3 origo印度版
     * @param {number} 4 918-pay版
     * */

    public static projectFlag = Number(sessionStorage.getItem("projectFlag")) || 2;
    /**商户号*/
    public static mercode = "wwal";

    /**项目参数*/
    public static get project(): Project {
        console.log("122222",Config.projectFlag,_projectArr[Config.projectFlag])
        return _projectArr[Config.projectFlag];
    }

    /**获取资源服务器文件地址*/
    public static getFileUrl(_url: string): string {
        if (_url.startsWith("http")) return _url;
        if (!_url && _url !== "0") return "";
        else return Config.project.fileHost + _url;
    }

    /**项目货币参数*/
    public static get currency(): Currency {
        if (Config.projectFlag == 0 || Config.projectFlag == 1 || Config.projectFlag == 2) return _currencyArr[0];
        else if (Config.projectFlag == 3 || Config.projectFlag == 4) return _currencyArr[1];
        return _currencyArr[0];
    }
}

/**
 * 提币验证开启状态
 * 0-开启
 * 1-不开启
 * */
 export default class WithdrawVo {
    /*谷歌验证*/
    google = 0;
    /**邮箱验证*/
    mail = 0;
    /**支付密码验证*/
    paypasword = 0;
    /**手机验证码验证*/
    phonesmg = 0;
}

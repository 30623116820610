import zh from "./zh";
import en from "./en";
import { ref } from "vue";
import codeWs from "@/assets/lang/codeWs";
import codeHttp from "@/assets/lang/codeHttp";
import LocalEnum from "../enum/LocalEnum";
export default class Lang {
    private static _langI = ref(localStorage.getItem(LocalEnum.LANG) || (navigator.language.startsWith("en") ? "zh" : "en"));
    private static _langObj: any = ref({ zh: zh, en: en });

    private static _codeWsObj: any = ref(codeWs);
    private static _codeHttpObj: any = ref(codeHttp);

    /**现在使用的语言**/
    public static get langI() {
        return this._langI.value;
    }

    /**修改语言**/
    public static setLangI(val: "zh" | "en") {
        this._langI.value = val;
        localStorage.setItem(LocalEnum.LANG, val);
    }
    /**语言数据**/
    public static get langObj() {
        return this._langObj.value[this._langI.value];
    }

    /**ws语言数据*/
    public static get codeWsObj() {
        debugger
        return this._codeWsObj.value[this._langI.value];
    }
    /**http语言数据*/
    public static get codeHttpObj() {
        return this._codeHttpObj.value[this._langI.value];
    }
}

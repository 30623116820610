import Config from "@/assets/ts/config";
import { ElMessage, ElNotification } from "element-plus";
import $store from "@/store";
import $route from "@/router";
import { getMyBalance, getOrderDetail, getUserInfo, signOut, tokenObj } from "./index";
import EventBus from "../ts/eventBus";
import OrderDetailsVo from "../vo/OrderDetailsVo";
import Lang from "../lang";

/**计时器**/
class SetTime {
    private _time: NodeJS.Timeout | undefined;
    onStart(fun: Function) {
        this.onClose();
        this._time = setInterval(() => {
            fun();
        }, 5000);
    }
    onClose() {
        this._time && clearInterval(this._time);
        this._time = undefined;
    }
}

/**心跳**/
const _heartbeat = {
    main: new SetTime(),
    onStart: function () {
        this.main.onStart(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onSend({ protoCode: 1, from: tokenObj.user_id, body: tokenObj.access_token });
        });
    },
    onClose: function () {
        this.main.onClose();
    },
};

/**断线连接**/
const _reconnect = {
    main: new SetTime(),
    retryCount: 0,
    onStart: function () {
        this.main.onStart(() => {
            this.retryCount++;
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onCreate();
            if (this.retryCount == 5) {
                this.onClose();
                getUserInfo(() => {
                    this.retryCount == 0;
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    onCreate();
                });
            }
        });
    },
    onClose() {
        this.main.onClose();
    },
};

/**音乐通知**/

const _noticeMusic = {
    _innerAudioContext: document.createElement("audio"),
    _isAppendChild: true,
    play: function (type: number) {
        if (this._isAppendChild) {
            document.body.appendChild(this._innerAudioContext);
            this._isAppendChild = false;
        }
        // if (type == 10) {
        //     this._innerAudioContext.src = require("@/assets/music/notice.mp3");
        // } else if (type == 11) {
        //     this._innerAudioContext.src = require("@/assets/music/chatNotice.mp3");
        // } else {
        //     this._innerAudioContext.src = require("@/assets/music/" + Lang.langI + type + ".mp3");
        // }
        this._innerAudioContext.play();
    },
};

/*接口回调*/
const _callbacks: any = {};

function showView(text: string) {
    ElNotification({
        title: Lang.langObj.l0029,
        message: text,
    });
}

function onHandleChat(_data: any) {
    $store.commit("updataChatObj", _data);
    function getOthersName(orderDetail: OrderDetailsVo): string {
        if (orderDetail.payUserId == $store.state.userInfo.userId) return orderDetail.payUserName;
        else return orderDetail.receiveUserName;
    }
    const _arr = [3, 4, 5, 6, 7, 12]; //订单状态变化强制更新
    if (_arr.includes(_data.type)) {
        getOrderDetail(_data.orderId, (_orderDetail: OrderDetailsVo) => {
            EventBus.emit(EventBus.OTC_SOCKET_ORDERINFO, _data, _orderDetail);
            $store.commit("updateOrderDetailsObj", _data);
            $store.commit("updataNewsObj", { ..._data, othersName: getOthersName(_orderDetail) });
        });
        return;
    }
    const _orderDetail: OrderDetailsVo = $store.state.orderDetailsObj[_data.orderId];
    if (_orderDetail) {
        $store.commit("updataNewsObj", { ..._data, othersName: getOthersName(_orderDetail) });
    } else {
        getOrderDetail(_data.orderId, (_orderDetail: OrderDetailsVo) => {
            $store.commit("updateOrderDetailsObj", _data);
            $store.commit("updataNewsObj", { ..._data, othersName: getOthersName(_orderDetail) });
        });
    }
}
/***ws消息**/
function onMessage(data: any) {
    const _data = JSON.parse(data.data);
    //服务端返回错误标志
    if (_data.type == -1) {
        const _body = JSON.parse(_data.body);
        ElMessage.error(Lang.codeWsObj[_body.status + ""] || _body.desc);
        if (_body.status == 5) signOut();
        return;
    }
    if (_data.protoCode == 2) {
        if (_data.body && _data.body == 401) {
            $store.commit("setWebStatus", 2);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onClose();
            signOut();
            $route.push({ path: "/login" });
        }
    } else if (_data.protoCode == 6) {
        _callbacks[_data.fp] && _callbacks[_data.fp]();
        _callbacks[_data.fp] = undefined;
    } else if (_data.protoCode == 1003) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onSend({ fp: _data.fp, ack: 1, protoCode: 5 }); //消息已收到
        const _body = JSON.parse(_data.body);
        const _userId = tokenObj.user_id;
        const _action = _body.payUserId == _userId ? Lang.langObj.l0018 : Lang.langObj.l0017; ///出售或购买,
        let _amount;
        if (_body.currencyCode == "USDT") _amount = Number(_body.amount).toFixed(6);
        else _amount = Number(_body.amount).toFixed(2);

        /**
         * 消息类型
         * 聊天消息：1 为文本 2 为图片
         * 3 订单创建通知
         * 4 订单付款通知 5 订单放行通知 6 申诉 7 取消 8 二次提醒支付
         * 9 账户、钱包状态通知  10 资金入账通知 11 提币通知
         * 12 取消申诉 13 二次提醒放行 14 广告金额返还通知
         **/
        switch (_data.type) {
            case 1:
                onHandleChat(_data);
                _noticeMusic.play(11);
                break;
            case 2:
                onHandleChat(_data);
                _noticeMusic.play(11);
                break;
            case 3:
                onHandleChat(_data);
                if (_body.advertiserId == _userId) {
                    if (_body.receiveUserId == _userId) _noticeMusic.play(2);
                    else _noticeMusic.play(3);
                } else {
                    _noticeMusic.play(10);
                }
                showView(Lang.langObj.l0401.replace("{1}", _action).replace("{2}", _body.orderNo));
                break;
            case 4:
                onHandleChat(_data);
                if (_body.payUserId == _userId) {
                    _noticeMusic.play(0);
                } else {
                    _noticeMusic.play(10);
                }
                showView(Lang.langObj.l0402.replace("{1}", _body.orderNo).replace("{2}", _action));
                break;
            case 5:
                onHandleChat(_data);
                if (_body.receiveUserId == _userId) {
                    _noticeMusic.play(1);
                } else {
                    _noticeMusic.play(10);
                }
                showView(Lang.langObj.l0403.replace("{1}", _body.orderNo).replace("{2}", _action));
                getMyBalance();
                break;
            case 6:
                onHandleChat(_data);
                _noticeMusic.play(10);
                showView(Lang.langObj.l0404.replace("{1}", _body.orderNo).replace("{2}", _action));
                break;
            case 7:
                onHandleChat(_data);
                _noticeMusic.play(10);
                showView(Lang.langObj.l0405.replace("{1}", _body.orderNo).replace("{2}", _action));
                break;
            case 8:
                onHandleChat(_data);
                _noticeMusic.play(10);
                showView(Lang.langObj.l0406.replace("{1}", _action).replace("{2}", _body.orderNo));
                break;
            case 9:
                _noticeMusic.play(10);
                break;
            case 10: {
                getMyBalance();
                _noticeMusic.play(10);
                const _arr: string[] = [Lang.langObj.l0410, Lang.langObj.l0411, Lang.langObj.l0412, Lang.langObj.l0413, Lang.langObj.l0414];
                showView(
                    Lang.langObj.l0415
                        .replace("{1}", _arr[_body.type])
                        .replace("{2}", _amount)
                        .replace("{3}", _body.currencyCode),
                );
                break;
            }
            case 11:
                _noticeMusic.play(10);
                showView(Lang.langObj.l0407.replace("{1}", _amount).replace("{2}", _body.currencyCode));
                break;
            case 12:
                onHandleChat(_data);
                _noticeMusic.play(10);
                if (_body.unAppealUserId == _userId) showView(Lang.langObj.l0380);
                else showView(Lang.langObj.l0381);
                break;
            case 13:
                onHandleChat(_data);
                _noticeMusic.play(10);
                showView(Lang.langObj.l0408.replace("{1}", _action).replace("{2}", _body.orderNo));
                break;
            case 14:
                getMyBalance();
                _noticeMusic.play(10);
                showView(
                    Lang.langObj.l0409
                        .replace("{1}", _body.currencyCode)
                        .replace("{2}", _amount)
                        .replace("{3}", _body.currencyCode),
                );
                break;
        }
    }
}

/**ws结束**/
function onWSOver() {
    $store.commit("setWebStatus", 0);
    _heartbeat.onClose();
    if (tokenObj.access_token && _reconnect.retryCount == 0) {
        _reconnect.onStart();
    }
}

/**创建连接**/
let _webSocket: WebSocket;
export function onCreate() {
    _webSocket = new WebSocket(
        Config.project.wsHost + "?im_token=" + tokenObj.access_token + "&user_id=" + tokenObj.user_id + "&enveFlag=" + _reconnect.retryCount,
    );
    _webSocket.onopen = () => {
        _reconnect.retryCount = 0;
        console.log("连接成功");
        $store.commit("setWebStatus", 1);
        _heartbeat.onStart();
        _reconnect.onClose();
    };
    _webSocket.onmessage = (data: any) => {
        onMessage(data);
    };
    _webSocket.onerror = err => {
        onWSOver();
        console.log(err);
        console.log("连接出错");
    };
    _webSocket.onclose = err => {
        onWSOver();
        console.log(err);
        console.log("连接关闭");
    };
}

/*发送消息*/

export function onSend(_data: any, fun?: Function) {
    if (fun) _callbacks[_data.fp] = fun;
    _webSocket.send(JSON.stringify(_data));
}

/*主动关闭webSocket*/
export function onClose() {
    console.log("主动关闭webSocket");
    _webSocket.close();
}


import Utils from "@/assets/ts/utils";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import QrCode from "qrcode.vue";
import { ElMessage } from "element-plus";
import { getUserInfo, sendMailCodeApi, updateEmail, updateGoogleSet, updateUserInfo } from "@/assets/http";
import Config from "@/assets/ts/config";
import Lang from "@/assets/lang";
export default defineComponent({
    components: { QrCode },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const nickName = ref("");
        const phone = ref("");
        const email = ref("");
        const google = ref(false);
        const $store = useStore();
        const user = ref(computed(() => $store.state.userInfo));
        const showNameAlert = ref(false);
        const showPhoneAlert = ref(false);
        const showMailAlert = ref(false);
        const showGoogleAlert = ref(false);
        const inputName = ref("");
        const inputMail = ref("");
        const inputMailCode = ref("");
        const inputGoogleCode = ref("");
        const showUpdateMailAlert = ref(false);
        const showCloseGoogleAlert = ref(false);
        const mailStep = ref(0);
        const sendMailTitle = ref(langObj.value.l0245);
        const sendMailNum = ref(60);
        const googleCode = ref("");
        const showGoogleStep = ref(0);
        const emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

        onMounted(() => {
            console.log(JSON.stringify(user.value));
            inputName.value = user.value.nickName;

            //生成谷歌验证码
            googleCode.value = "otpauth://totp/" + user.value.username.toString() + "?secret=" + user.value.secret.toString();
        });
        const updateNickName = () => {
            const data = {
                nickName: inputName.value,
                userId: user.value.userId,
            };
            //更新用户信息
            updateUserInfo(data, (res: any) => {
                console.log(res);
                getUserInfo();
            });
        };
        const nextMail = () => {
            if (emailReg.test(inputMail.value) == false) {
                ElMessage.error(langObj.value.l0267);
                return;
            }
            mailStep.value = 1;
        };
        const sendMailCode = () => {
            if (emailReg.test(inputMail.value) == false) {
                ElMessage.error(langObj.value.l0267);
                return;
            }

            sendMailCodeApi(inputMail.value, () => {
                ElMessage.success(langObj.value.l0270);

                const timer = setInterval(() => {
                    sendMailNum.value--;
                    sendMailTitle.value = sendMailNum.value.toString() + "s";
                    if (sendMailNum.value <= 0) {
                        sendMailTitle.value = langObj.value.l0245;
                        clearInterval(timer);
                        sendMailNum.value = 60;
                        inputMailCode.value = "";
                    }
                }, 1000);
            });
        };
        const bindMail = () => {
            if (emailReg.test(inputMail.value) == false) {
                ElMessage.error(langObj.value.l0267);
                return;
            }
            if (inputMailCode.value.length != 6) {
                ElMessage.error(langObj.value.l0268);
                return;
            }
            updateEmail(inputMail.value, inputMailCode.value, () => {
                showMailAlert.value = false;
                getUserInfo();
            });
        };
        const bindGoogle = (set: string) => {
            if (inputGoogleCode.value.length < 6) {
                ElMessage.error(langObj.value.l0269);
                return;
            }
            updateGoogleSet(set, inputGoogleCode.value, () => {
                showGoogleAlert.value = false;
                showCloseGoogleAlert.value = false;
                getUserInfo();
            });
        };
        const contactService = () => {
            window.open(Config.project.server);
        };
        const copyAction = (content: string) => {
            Utils.clipboard({ text: content.toString() });
            // ElMessage.success(langObj.value.l0271);
        };

        return {
            langObj,
            user,
            nickName,
            phone,
            inputName,
            email,
            google,
            mailStep,
            bindMail,
            sendMailTitle,
            bindGoogle,
            copyAction,
            inputMail,
            inputMailCode,
            updateNickName,
            showCloseGoogleAlert,
            showNameAlert,
            showMailAlert,
            nextMail,
            showGoogleAlert,
            showUpdateMailAlert,
            showPhoneAlert,
            inputGoogleCode,
            showGoogleStep,
            contactService,
            sendMailCode,
            googleCode,
        };
    },
});


import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import $route from "@/router";
import { signOut, mainIsLogin } from "@/assets/http";
import { ElMessageBox } from "element-plus";
import Config from "@/assets/ts/config";
import LocalEnum from "@/assets/enum/LocalEnum";
import Lang from "@/assets/lang";
export default defineComponent({
    name: "Header",
    setup() {
        const $store = useStore();
        const langI = computed(() => Lang.langI);
        const langObj = computed(() => Lang.langObj);
        const langV = ref(Lang.langI);
        const langArr = ref([
            { value: "zh", label: "中文" },
            { value: "en", label: "English" },
        ]);
        const onChange = (item: any) => {
            Lang.setLangI(item)
        };
        return {
            langObj,
            langV,
            langI,
            langArr,
            onChange,
            Config,
            mainIsLogin,
            userInfo: computed(() => $store.state.userInfo),
            onSignOut: () => {
                ElMessageBox.confirm(langObj.value.l0003, {
                    confirmButtonText: langObj.value.l0004,
                    cancelButtonText: langObj.value.l0005,
                    callback: (action: string) => {
                        if (action == "confirm") {
                            signOut();
                        }
                    },
                });
            },
            onLogin: () => {
                $route.push({ path: "/login" });
            },
        };
    },
});

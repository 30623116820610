
import { defineComponent, ref, onMounted, computed, Ref, provide } from "vue";
import { useStore } from "vuex";
import Lang from "@/assets/lang";
import { getOtcData, getActivePaymentList, getAdministration, resetAdvertise, tokenObj, withdrawalAdvertise, httpTime } from "@/assets/http";
import Utils from "@/assets/ts/utils";
import route from "../../router";
import paymentView from "./paymentView.vue";
import Config from "@/assets/ts/config";

export default defineComponent({
    name: "AdvertisAdmin",
    components: { paymentView },
    setup() {
        const langObj = computed(() => Lang.langObj);
        provide("paymethodId", "");
        const sellList: Ref<any[]> = ref([]);
        const buyList: Ref<any[]> = ref([]);
        const $store = useStore();
        const showResetAlert = ref(false);
        const showNoAuth = ref(false);
        const currentTime = ref(0);
        const setInt: any = ref();
        const showNoPay = ref(false);
        const merchant = ref(tokenObj.merchant);
        const paymentList: Ref<any[]> = ref([]);
        const otcData = ref({
            success: 0,
            successRatio: 0,
            successByMonth: 0,
            avgPermitTime: 0,
        });
        const selectItem = ref({
            id: 0,
        });

        const onSetInt = () => {
            currentTime.value = httpTime();
        };
        const requestList = () => {
            getAdministration(tokenObj.user_id, (data: any[]) => {
                buyList.value = [];
                sellList.value = [];
                data.forEach((item: any) => {
                    if (item.type == 0) sellList.value.push(item);
                    else buyList.value.push(item);
                });
                if (sellList.value.length > 0 && buyList.value.length > 0) {
                    onSetInt();
                }
            });
        };
        const requestActivePayment = () => {
            getActivePaymentList(tokenObj.user_id, (list: any) => {
                //设置可用的支付方式
                if (!list || list.length == 0) {
                    return;
                }

                paymentList.value = list;
            });
        };
        const onSetIntStart = () => {
            if (!setInt.value) {
                setInt.value = setInterval(setInt, 1000);
            }
        };
        const onSetIntEnd = () => {
            setInt.value && clearInterval(setInt.value);
            setInt.value = null;
        };
        const onRevokeTime = (_item: any) => {
            //撤销时间
            if (!_item.revokeTime) {
                return "";
            }

            const _num = (_item.revokeTime + 900000 - currentTime.value) / 1000;
            if (_num == 0) {
                onSetIntEnd(); //关闭计时器
                requestList(); //刷新列表
            }
            return Utils.getCountdown(_num);
        };
        const getTime = (_item: any) => {
            //显示剩余时间
            // console.log('剩余时间---------')
            const _num = (_item.expireTime - currentTime.value) / 1000;
            if (_num == 0) {
                onSetIntEnd(); //关闭计时器
                requestList(); //刷新列表
            }
            return langObj.value.l0167 + Utils.getCountdown(_num);
        };

        onMounted(() => {
            setInt.value = setInterval(onSetInt, 1000);
            getOtcData(tokenObj.user_id, (data: any) => {
                Utils.updateObj(otcData.value, data);
            });
            requestList();
            requestActivePayment();
        });

        const cloneAd = (item: any) => {
            selectItem.value = item;
            if (merchant.value == 0 || $store.state.userInfo.isAdvertise == 1) {
                showNoAuth.value = true;
            } else if (paymentList.value.length == 0) {
                showNoPay.value = true;
            } else {
                $store.commit("setClonAdvertise", item);
                route.push({ path: "/advertisPublish" });
                onSetIntEnd();
            }
        };
        const resetAd = (item: any) => {
            selectItem.value = item;
            showResetAlert.value = true;
        };
        const handleCloseReset = () => {
            showResetAlert.value = false;
            const item = selectItem.value;
            resetAdvertise(item.id, () => {
                requestList();
            });
        };
        const handleCloseAuth = () => {
            showNoAuth.value = false;
        };
        const gotoPublish = () => {
            if (merchant.value == 0 || $store.state.userInfo.isAdvertise == 1) {
                showNoAuth.value = true;
            } else if (paymentList.value.length == 0) {
                showNoPay.value = true;
            } else {
                $store.commit("setClonAdvertise", {});
                route.push({ path: "/advertisPublish" });
                onSetIntEnd();
            }
        };
        const getShowTitle = (item: any) => {
            if (item.status == 0) {
                return getTime(item);
            } else if (item.status == 1) {
                return langObj.value.l0168;
            } else {
                return langObj.value.l0169;
            }
        };
        const recycle = (item: any) => {
            withdrawalAdvertise(item.id, () => {
                requestList();
            });
        };
        const gotoSetPayment = () => {
            $store.commit("setClonAdvertise", {});
            route.push({ path: "/my" });
            onSetIntEnd();
        };
        return {
            langObj,
            Config,
            handleCloseAuth,
            showNoAuth,
            gotoPublish,
            requestList,
            showNoPay,
            sellList,
            buyList,
            otcData,
            selectItem,
            showResetAlert,
            handleCloseReset,
            cloneAd,
            resetAd,
            getShowTitle,
            recycle,
            currentTime,
            onSetIntStart,
            onSetIntEnd,
            gotoSetPayment,
            onRevokeTime,
            getTime,
            getCountdown: (time: number) => {
                return Utils.getCountdown(time, true);
            },
            mortgageNum: computed(() => $store.state.mortgageNum),
        };
    },
});

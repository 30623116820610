import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "freeTrade" }
const _hoisted_2 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/freeTrade/freeTradeBuy" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langObj.l0017) + "USDT", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/freeTrade/freeTradeSell" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langObj.l0018) + "USDT", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/freeTrade/freeTradeRecord" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langObj.l0181), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_router_view)
  ]))
}

import { defineComponent, ref, onMounted, computed, onUnmounted, Ref } from "vue";
import { getOrderRecord } from "@/assets/http";
import Lang from "@/assets/lang";
import { useStore } from "vuex";
import Utils from "@/assets/ts/utils";
import EventBus from "@/assets/ts/eventBus";
import OrderDetails from "./OrderDetails.vue";
import OrderChat from "./OrderChat.vue";
import Config from "@/assets/ts/config";
import OrderDetailsVo from "@/assets/vo/OrderDetailsVo";
export default defineComponent({
    name: "AdvertisTrade",
    components: { OrderDetails, OrderChat },
    setup() {
        const langObj = computed(() => Lang.langObj);
        const orderDetails = ref();
        const orderChat = ref();
        const tabsArr = computed(() => [langObj.value.l0389, langObj.value.l0390, langObj.value.l0391]);
        const tabI = ref(0);
        const itemBoxI = ref(-1);
        const $store = useStore();
        const isMySell = (id: string) => {
            return $store.state.userInfo.userId == id; //是否我出售
        };
        let orderId = "";
        const onItemBox = (item: any, index: number) => {
            if (itemBoxI.value == index) return;
            itemBoxI.value = index;
            orderId = item.id;
            orderDetails.value.onShow(item, isMySell(item.payUserId));
            orderChat.value.onShow(item, isMySell(item.payUserId));
        };
        const payArr: Ref<any[]> = ref([]);
        const isLoadEnd = ref(false);
        const isLoadStart = ref(false);
        let pageNumber = 0; //当前页数
        let totalPages = 0; //总页数
        const statusArr = ["0,1,4", "2", "3"]; //订单状态 0：待支付 1：待放行 2 已完成 3：已取消、4:申诉中 例：进行中 0，1,4
        const onOrderRecord = () => {
            isLoadStart.value = true;
            isLoadEnd.value = true;
            pageNumber++;
            const _size = tabI.value == 0 ? 99999 : 10;
            const _data = {
                status: statusArr[tabI.value],
                size: _size,
                current: pageNumber,
            };
            getOrderRecord(_data, (data: any) => {
                isLoadStart.value = false;
                if (pageNumber == 1) payArr.value = [];
                payArr.value = [...payArr.value, ...data.content];
                totalPages = data.totalPages;
                pageNumber = data.pageNumber;
                isLoadEnd.value = pageNumber >= totalPages;
            });
        };
        const refreshChat = (_data: any, _orderDetail: OrderDetailsVo) => {
            const _index = payArr.value.findIndex((item: any) => item.id == _data.orderId);
            /*_data.type 聊天消息 1 为文本 2 为图片 3 订单创建通知 4 订单付款通知 5 订单放行通知 6 申诉 7 取消 8 二次提醒支付 12 取消申诉 13 二次提醒放行**/
            /**当列表为未完成&&数组中有**/
            function spliceArr() {
                payArr.value.splice(_index, 1);
                if (_index == itemBoxI.value) {
                    itemBoxI.value = -1;
                    orderId = "";
                    orderDetails.value.onShow();
                    orderChat.value.onShow();
                } else if (_index < itemBoxI.value) {
                    itemBoxI.value--;
                }
            }
            /**当列表为已完成||已取消 **/
            function unshiftArr() {
                payArr.value.unshift(_orderDetail);
                if (itemBoxI.value != -1) itemBoxI.value++;
                if (pageNumber < totalPages) payArr.value.pop();
            }

            /**创建订单&&在交易中&&币商是自己*/
            if (_data.type == 3 && tabI.value == 0 && isMySell(_orderDetail.advertisementUserId)) {
                payArr.value.unshift(_orderDetail);
                if (itemBoxI.value != -1) itemBoxI.value++;
                if (pageNumber < totalPages) payArr.value.pop();
            } else if (_data.type == 5) {
                /**已完成*/
                if (tabI.value == 0) {
                    spliceArr();
                } else if (tabI.value == 1) {
                    unshiftArr();
                }
            } else if (_data.type == 7) {
                /**已取消*/
                if (tabI.value == 0) {
                    spliceArr();
                } else if (tabI.value == 2) {
                    unshiftArr();
                }
            }
            /**判断是否显示当前订单**/
            if (_data.orderId == orderId) {
                orderDetails.value.updataOrderDetail(_orderDetail);
            }
        };
        onMounted(() => {
            onOrderRecord();
            EventBus.on(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        onUnmounted(() => {
            EventBus.remove(EventBus.OTC_SOCKET_ORDERINFO, refreshChat);
        });
        return {
            langObj,
            Config,
            tabsArr,
            tabI,
            onTab: (index: number) => {
                tabI.value = index;
                pageNumber = 0; //当前页数
                totalPages = 0; //总页数
                isLoadEnd.value = false;
                itemBoxI.value = -1;
                orderId = "";
                payArr.value = [];
                orderDetails.value.onShow();
                orderChat.value.onShow();
                onOrderRecord();
            },
            itemBoxI,
            onItemBox,
            payArr,
            isLoadStart,
            isLoadEnd,
            onOrderRecord,
            isMySell,
            formatDateTime: (time: number) => {
                return Utils.formatDateMD(time);
            },
            showName: (data: any) => {
                //显示的名字
                if (isMySell(data.payUserId)) return data.receiveUserName;
                else return data.payUserName;
            },
            getStatus: (num: number) => {
                //0：待支付款 1：支付成功等待放行 2 已完成 3：已取消、4:申诉中
                const arr = computed(() => [langObj.value.l0392, langObj.value.l0393, langObj.value.l0390, langObj.value.l0391, langObj.value.l0345]);
                return arr.value[num];
            },
            getClassName: (data: any) => {
                //状态的颜色
                const _isMySell = isMySell(data.payUserId);
                if ((_isMySell && data.status == 1) || (!_isMySell && data.status == 0)) {
                    return "fontRed";
                } else if (data.status == 4) {
                    return "fontBright";
                } else {
                    return "";
                }
            },
            orderDetails,
            orderChat,
        };
    },
});
